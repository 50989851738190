<ng-container>
  <div class="wrapper"  *ngFor=" let assinatura of assinaturasAtuais">
    <div class="title-wrapper">
      <h5 class="title"> {{ assinatura.AssinaturaDescricao }}</h5>
      <h6 class="title"> {{ assinatura.AssinaturaCondicaoDescricao }}</h6>
    </div>
    <p class="vencimento"> Vence no dia <strong> {{ assinatura.DataProximoVencimento | date: 'dd'}} </strong> de cada mês</p>
    <p> Período da assinatura </p>
    <div class="divider-value">
      <p> de: <strong> {{ assinatura.DataInicio | date: 'dd/MM/YYYY' }} </strong> até <strong> {{ assinatura.DataFim | date: 'dd/MM/YYYY' }} </strong></p>
      <h5> {{ assinatura.VrPrimeiroVencimento | moeda }} </h5>
    </div>

    <section class="forma-pagamento" *ngIf="vendasPaguela[assinatura.AssinaturaPessoaId] && situacaoPaguela === 0">
      <h5> Forma de Pagamento</h5>
      <span>Cartão de Crédito</span>
      <div class="forma-pagamento-card">
        <figure class="forma-pagamento-card-brand">
          <img src="{{getBrandSource(vendasPaguela[assinatura.AssinaturaPessoaId].Bandeira)}}" alt="bandeira do cartão de crédito"/>
        </figure>
        <div class="forma-pagamento-card-info">
          <div class="forma-pagamento-card-info-row">
              **** **** **** <strong>{{vendasPaguela[assinatura.AssinaturaPessoaId].Numero}}</strong>
          </div>
          <div class="forma-pagamento-card-info-row">
            {{vendasPaguela[assinatura.AssinaturaPessoaId].NomeComprador}}
          </div>
          <div class="forma-pagamento-card-info-row">
            Próxima cobrança em: <strong>{{assinatura.DataProximoVencimento | date: 'dd/MM/YYYY'}}</strong>
          </div>
        </div>
      </div>
<!--      <button class="forma-pamagmento-button" *ngIf="permitirPagarComPaguela && situacaoPaguela === 0" (click)="adicionarContasReceberParaVendaCartao(assinatura, true)">-->
<!--        Alterar Cartão de Crédito-->
<!--      </button>-->
    </section>
    <section class="forma-pagamento" *ngIf="!vendasPaguela[assinatura.AssinaturaPessoaId] && permitirPagarComPaguela && situacaoPaguela === 0">
      <h5> Forma de Pagamento</h5>
      <span>Não há forma de pagamento cadastrada</span>
      <button class="forma-pamagmento-button" (click)="adicionarContasReceberParaVendaCartao(assinatura, false)">
        Adicionar Cartão de Crédito
      </button>
    </section>
  </div>
</ng-container>
