<header class="capa bg-cinza-logo">
  <div class="nome m-t-10">
    <h4>{{userData?.FirstName}} {{userData?.LastName}}</h4>
    <h5 class="nome-conta">{{userData?.AccountName}}</h5>
  </div>
  <img class="avatar" [src]="getAvatarUrl()" alt="avatar-capa">
</header>

<section class="content">
  <div class="bem-vindo-container">
    <p class="msg-bem-vindo">Bem
      <span *ngIf="userData?.Sex == 'M'">vindo</span>
      <span *ngIf="userData?.Sex == 'F'">vinda</span>,
      <strong>{{userData?.FirtName || '...'}}</strong>!
      <span *ngIf="postagens">
        Veja as aulas recentes de
        <br>
        sua academia
      </span>
    </p>
  </div>

  <div appmasonry class="content-masonry">
    <app-card-aula *ngFor="let aula of aulas" [aula]="aula" [resumido]="true"></app-card-aula>
  </div>
</section>

<footer class="fixed">
  <div [class.open]="!conexaoInternet" class="msg-offline">
    Você está offline, não foi possível atualizar o feed
  </div>

  <button class="btn-iniciar-treino shadow-up" mat-button mat-ripple [disabled]="!possuiTreino" [routerLink]="['../treino']">
    {{ possuiTreino ? 'Começar o treino' : 'Você ainda não tem treino :( '}}
  </button>
</footer>
