<mat-card class="animated" [class.closed]="finaliza" [class.focused]="descansoIniciado">
  <mat-card-title>
    <div [style.paddingBottom]="exercicioPaddingBottom" id="card-row-1" class="row-exercicio {{getFlagClass(1)}}">
      <div class="flag-exercicio {{exercicio.TipoExercicio}}"
      [class.no-content]="finaliza && exercicio.TipoExercicio === exercicioBiset?.TipoExercicio"></div>
      <div [style.fontSize]="descricaoFontSize" #exercicioDescricao class="espaco-direita">{{exercicio.DescricaoCurta}}</div>
    </div>
    <div [style.paddingBottom]="exercicioPaddingBottom" id="card-row-2" class="row-exercicio {{getFlagClass(2)}}" *ngIf="exercicioBiset">
      <div class="flag-exercicio {{exercicioBiset.TipoExercicio}}"
      [class.no-content]="finaliza && exercicioBiset.TipoExercicio === exercicioTriset?.TipoExercicio"></div>
      <div [style.fontSize]="descricaoFontSize" #exercicioDescricao class="espaco-direita">{{exercicioBiset.DescricaoCurta}}</div>
    </div>
    <div [style.paddingBottom]="exercicioPaddingBottom" id="card-row-3" class="row-exercicio {{getFlagClass(3)}}" *ngIf="exercicioTriset">
      <div class="flag-exercicio radius {{exercicioTriset.TipoExercicio}}"></div>
      <div [style.fontSize]="descricaoFontSize" #exercicioDescricao class="espaco-direita">{{exercicioTriset.DescricaoCurta}}</div>
    </div>

    <div class="checkbox-tap"></div>
    <mat-checkbox [checked]="finaliza" color="accent"></mat-checkbox>
  </mat-card-title>

  <div class="status-content">
    <div *ngIf="exercicio" class="card-status" id="card-1">
      <mat-card-content class="animated">
        <div class="box-card" [class.no-content-card]="!exercicio.Carga && !exercicio.Repeticao && !exercicio.Serie && !exercicio.Tempo && !exercicio.Velocidade">
          <div *ngIf="exercicio.Serie" class='col2' id="series" style="position: relative;">
            <div>
              <p  class="color-transition">{{(exercicio.Serie - exercicio.SeriesConcluidas) > 0 ? (exercicio.Serie - exercicio.SeriesConcluidas) : '✓'}}</p>
              <i class="check">✓</i>
            </div>
            <span>séries</span>
          </div>
          <div class='col2' *ngIf="exercicio.Tempo && exercicio.Tempo !== '0'">
            <p >{{exercicio.Tempo}}</p>
            <span>tempo</span>
          </div>
          <div class='col2' *ngIf="carga.length">
            <div class="ic-carga" *ngIf="ajusteTreino && ajusteTreino.EditarCarga">
              <i class="fa fa-edit fa-sm" (click)="abrirEdicaoCarga(exercicio)"></i>
            </div>
            <p>
              {{
                (carga[exercicio.SeriesConcluidas % carga.length])
                  ? carga[exercicio.SeriesConcluidas % carga.length]
                  : carga[0]
              }}
            </p>
            <span>carga</span>
          </div>
          <div class='col1 box-repeticao' *ngIf="repeticao && repeticao.length">
            <p [ngClass]="repeticao[exercicio.SeriesConcluidas % repeticao.length] && repeticao[exercicio.SeriesConcluidas % repeticao.length].length > 13 ? 'custome-paragraph' : ''">
              {{
                (repeticao[exercicio.SeriesConcluidas % repeticao.length])
                  ? repeticao[exercicio.SeriesConcluidas % repeticao.length]
                  : repeticao[0]
              }}
              <span class="repeticao-label">repetições</span>
            </p>
          </div>
          <div class='col1' *ngIf="exercicio.Velocidade && exercicio.Velocidade !== '0'">
            <p >{{exercicio.Velocidade}}</p>
            <span>velocidade</span>
          </div>
        </div>
        <div class="box-observacao">
          <p>{{exercicio.Observacoes}}</p>
        </div>
      </mat-card-content>

      <mat-card-actions class="animated">
        <div class="bottom-card-animated">
          <div class="w-100" [class.wrapper-com-ver-exercicio]="exercicio?.ExerciceHasMedia">
            <div *ngIf="exercicio.Descanso" (click)="iniciaDescanso(exercicio.Descanso)" class="descanso-treino">
              <!-- este icone é o timer -->
              <mat-icon> &#xE425;</mat-icon>
              <strong>Intervalo</strong>{{exercicio.Descanso}}s.
            </div>
            <div *ngIf="!exercicio.Descanso" class="descanso-treino">
              <!-- este icone é o timer -->
              <mat-icon> &#xE425;</mat-icon>
              <span>Sem descanso</span>
            </div>
            <div *ngIf="exercicioBiset || exercicioTriset" (click)="avancaExercicio()" class="descanso-treino">
              <!-- browsers antigos ( <= android 4.4 ) não entendem pelo nome do icone e precisam do código, este icone é o chevron_right -->
              <mat-icon> &#xE5CC; </mat-icon>
              <strong>Próximo</strong>
               <span>{{exercicioTriset ? 'triset' : 'biset'}}</span>
            </div>

          </div>

          <div *ngIf="exercicio?.ExerciceHasMedia" mat-ripple class="descanso-treino ver-exercicio-action" (click)="setInstrucao(exercicio)">
            <mat-icon>play_arrow</mat-icon>visualizar
          </div>
          <div class="d-flex">
            <div mat-ripple (click)="toggleExtra()" class="treino-mais" [class.active]="extraOpen">
              <!-- este icone é o add -->
              <mat-icon *ngIf="!extraOpen"> &#xE145;</mat-icon>

              <!-- este icone é o remove -->
              <mat-icon *ngIf="extraOpen"> &#xE15B;</mat-icon>
            </div>
          </div>
        </div>
      </mat-card-actions>

      <mat-card-actions class="treino-info" [class.open]="extraOpen">
        <span class="descricao-longa" *ngIf="exercicio?.DescricaoLonga">{{exercicio?.DescricaoLonga}}</span>
.
        <ng-container *ngIf="!loadingExtra">
          <div *ngIf="extraInfo" class="flex-content padded">
            <div>
              <div *ngIf="extraInfo.NumeroAparelho && extraInfo.NumeroAparelho > 0">
                <span>Aparelho</span>
                <p>{{extraInfo.NumeroAparelho}}</p>
              </div>
              <div class="header-dica">
                <span [class]="exercicio?.TipoExercicio">{{exercicio?.TipoExercicio | tipoExercicio}}</span>
                <span *ngIf="extraInfo.GrupoMuscular">{{extraInfo.GrupoMuscular}}</span>
              </div>
            </div>
            <div *ngIf="extraInfo.Dica">
              <p class="texto-ajuda">{{extraInfo.Dica}}</p>
            </div>
          </div>
        </ng-container>

        <div class="m-t-10 m-b-10" *ngIf="loadingExtra">
          <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
        </div>

        <small *ngIf="!extraInfo && !exercicio?.DescricaoLonga && !loadingExtra">Este exercício não possui informações adicionais.</small>
      </mat-card-actions>
    </div>

    <div *ngIf="exercicioBiset" class="card-status overlay" id="card-2">
      <mat-card-content>
        <div class="box-card" [class.no-content-card]="!exercicioBiset.Carga && !exercicioBiset.Repeticao && !exercicioBiset.Serie && !exercicioBiset.Tempo && !exercicioBiset.Velocidade">
          <div *ngIf="exercicio.Serie" class='col2' id="series" style="position: relative;">
            <div>
              <p  class="color-transition">{{(exercicio.Serie - exercicio.SeriesConcluidas) > 0 ? (exercicio.Serie - exercicio.SeriesConcluidas) : '✓'}}</p>
              <i class="check">✓</i>
            </div>
            <span>séries</span>
          </div>
          <div class='col2' *ngIf="exercicioBiset.Tempo && exercicioBiset.Tempo !== '0'">
            <p >{{exercicioBiset.Tempo}}</p>
            <span>tempo</span>
          </div>
          <div class='col2' *ngIf="carga.length">
            <div class="ic-carga" *ngIf="ajusteTreino && ajusteTreino.EditarCarga">
              <i class="fa fa-edit fa-sm" (click)="abrirEdicaoCarga(exercicioBiset)"></i>
            </div>
            <p>
              {{
                (cargaBiset[exercicioBiset.SeriesConcluidas % cargaBiset.length])
                ? cargaBiset[exercicioBiset.SeriesConcluidas % cargaBiset.length]
                : cargaBiset[0]
              }}
            </p>
            <span>carga</span>
          </div>
          <div class='col1 box-repeticao' *ngIf="repeticao && repeticao.length">
            <p [ngClass]="repeticao[exercicioBiset.SeriesConcluidas % repeticao.length] && repeticao[exercicio.SeriesConcluidas % repeticao.length].length > 13 ? 'custome-paragraph' : ''">
              {{
                (repeticaoBiset[exercicioBiset.SeriesConcluidas % repeticaoBiset.length])
                  ? repeticaoBiset[exercicioBiset.SeriesConcluidas % repeticaoBiset.length]
                  : repeticaoBiset[0]
              }}
              <span class="repeticao-label">repetições</span>
            </p>
          </div>
          <div class='col1' *ngIf="exercicioBiset.Velocidade && exercicioBiset.Velocidade !== '0'">
            <p >{{exercicioBiset.Velocidade}}</p>
            <span>velocidade</span>
          </div>
        </div>
      </mat-card-content>

      <div *ngIf="exercicioBiset" class="box-observacao">
        <p>{{exercicioBiset.Observacoes}}</p>
      </div>

      <mat-card-actions>
        <div class="bottom-card-animated">
          <div class="w-100" [class.wrapper-com-ver-exercicio]="exercicioBiset?.ExerciceHasMedia">
            <div *ngIf="exercicioBiset.Descanso" (click)="iniciaDescanso(exercicioBiset.Descanso)" class="descanso-treino">
              <!-- este icone é o timer -->
              <mat-icon> &#xE425;</mat-icon>
              <strong>Intervalo</strong>{{exercicioBiset.Descanso}}s.
            </div>
            <div *ngIf="!exercicioBiset?.Descanso" class="descanso-treino">
              <!-- este icone é o timer -->
              <mat-icon> &#xE425;</mat-icon>
              <span>Sem descanso</span>
            </div>

            <div class="d-flex">
              <div (click)="avancaExercicio()" class="descanso-treino">
                              <!-- browsers antigos ( <= android 4.4 ) não entendem pelo nome do icone e precisam do código, este icone é o chevron_right -->
                <mat-icon> &#xE5CC;</mat-icon>
                <strong>
                  {{exercicio.SeriesConcluidas == (exercicio.Serie - 1) && !exercicioTriset ? 'Concluir' : 'Próximo'}}
                </strong>
                <span>{{exercicioTriset ? 'triset' : 'biset'}}</span>
              </div>
              <div *ngIf="exercicioBiset?.ExerciceHasMedia" mat-ripple class="descanso-treino ver-exercicio-action" (click)="setInstrucao(exercicioBiset)">
                <mat-icon>play_arrow</mat-icon>visualizar
              </div>
              <div class="d-flex">
                <div mat-ripple (click)="toggleExtra()" class="treino-mais" [class.active]="extraOpen">
                  <!-- este icone é o add -->
                  <mat-icon *ngIf="!extraOpen"> &#xE145; </mat-icon>

                  <!-- este icone é o remove -->
                  <mat-icon *ngIf="extraOpen"> &#xE15B; </mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-actions>

      <mat-card-actions class="treino-info" [class.open]="extraOpen">
        <span class="descricao-longa">{{exercicioBiset?.DescricaoLonga}}</span>

        <div *ngIf="extraInfoBiset" class="flex-content padded">
          <div>
            <div *ngIf="extraInfoBiset.NumeroAparelho && extraInfoBiset.NumeroAparelho > 0">
              <span>Aparelho</span>
              <p>{{extraInfoBiset.NumeroAparelho}}</p>
            </div>

            <div class="header-dica">
              <span [class]="exercicioBiset?.TipoExercicio">{{exercicioBiset?.TipoExercicio}}</span>
              <span *ngIf="extraInfoBiset.GrupoMuscular">{{extraInfoBiset.GrupoMuscular}}</span>
            </div>
          </div>

          <div *ngIf="extraInfoBiset.Dica">
            <p class="texto-ajuda">{{extraInfoBiset.Dica}}</p>
          </div>
        </div>
      </mat-card-actions>
    </div>

    <div *ngIf="exercicioTriset" class="card-status overlay" id="card-3">
      <mat-card-content>
        <div class="box-card" [class.no-content-card]="!exercicioTriset.Carga && !exercicioTriset.Repeticao && !exercicioTriset.Serie && !exercicioTriset.Tempo && !exercicioTriset.Velocidade">
          <div *ngIf="exercicio.Serie" class='col2' id="series" style="position: relative;">
            <div>
              <p  class="color-transition">{{(exercicio.Serie - exercicio.SeriesConcluidas) > 0 ? (exercicio.Serie - exercicio.SeriesConcluidas) : '✓'}}</p>
              <i class="check">✓</i>
            </div>
            <span>séries</span>
          </div>
          <div class='col2' *ngIf="exercicioTriset.Tempo && exercicioTriset.Tempo !== '0'">
            <p >{{exercicioTriset.Tempo}}</p>
            <span>tempo</span>
          </div>
          <div class='col2' *ngIf="carga.length">
            <div class="ic-carga" *ngIf="ajusteTreino && ajusteTreino.EditarCarga">
              <i class="fa fa-edit fa-sm" (click)="abrirEdicaoCarga(exercicioTriset)"></i>
            </div>
            <p>
              {{
                (cargaTriset[exercicioTriset.SeriesConcluidas % cargaTriset.length])
                ? cargaTriset[exercicioTriset.SeriesConcluidas % cargaTriset.length]
                : cargaTriset[0]
              }}
            </p>
            <span>carga</span>
          </div>
          <div class='col1 box-repeticao' *ngIf="repeticao && repeticao.length">
            <p [ngClass]="repeticao[exercicioTriset.SeriesConcluidas % repeticao.length] && repeticao[exercicioTriset.SeriesConcluidas % repeticao.length].length > 13 ? 'custome-paragraph' : ''">
              {{
                (repeticaoTriset[exercicioTriset.SeriesConcluidas % repeticaoTriset.length])
                  ? repeticaoTriset[exercicioTriset.SeriesConcluidas % repeticaoTriset.length]
                  : repeticaoTriset[0]
              }}
              <span class="repeticao-label">repetições</span>
            </p>
          </div>
          <div class='col1' *ngIf="exercicioTriset.Velocidade && exercicioTriset.Velocidade !== '0'">
            <p >{{exercicioTriset.Velocidade}}</p>
            <span>velocidade</span>
          </div>
        </div>
      </mat-card-content>

      <div *ngIf="exercicioTriset" class="box-observacao">
        <p>{{exercicioTriset.Observacoes}}</p>
      </div>

      <mat-card-actions>
        <div class="bottom-card-animated">
          <div class="w-100" [class.wrapper-com-ver-exercicio]="exercicioTriset?.ExerciceHasMedia">
            <div class="d-flex">
              <div *ngIf="exercicioTriset.Descanso" (click)="iniciaDescanso(exercicioTriset.Descanso)" class="descanso-treino">
                <!-- este icone é o timer -->
                <mat-icon> &#xE425;</mat-icon>
                <strong>Intervalo</strong> {{exercicioTriset.Descanso}}s.
              </div>
              <div *ngIf="exercicioTriset?.ExerciceHasMedia" mat-ripple class="descanso-treino ver-exercicio-action" (click)="setInstrucao(exercicioTriset)">
                <mat-icon>play_arrow</mat-icon><span class="ver-label"></span>visualizar
              </div>
            </div>
            <div *ngIf="!exercicioTriset.Descanso" class="descanso-treino">
              <!-- este icone é o timer -->
              <mat-icon> &#xE425;</mat-icon>
              <span>Sem descanso</span>
            </div>

            <div class="d-flex">

              <!-- browsers antigos ( <= android 4.4 ) não entendem pelo nome do icone e precisam do código, este icone é o chevron_right -->
              <div class="descanso-treino" (click)="avancaExercicio()">
                <mat-icon> &#xE5CC; </mat-icon>
                <strong>{{exercicio.SeriesConcluidas == (exercicio.Serie - 1) ? 'Concluir' : 'Próximo'}}</strong>
                <span>triset</span>
              </div>
              <div class="d-flex">

                <div mat-ripple (click)="toggleExtra()" class="treino-mais" [class.active]="extraOpen">
                  <!-- este icone é o add -->
                  <mat-icon *ngIf="!extraOpen">&#xE145;</mat-icon>

                  <!-- este icone é o remove -->
                  <mat-icon *ngIf="extraOpen"> &#xE15B; </mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-actions>

      <mat-card-actions class="treino-info animated" [class.open]="extraOpen">
        <span class="descricao-longa">{{exercicioTriset?.DescricaoLonga}}</span>
        <div *ngIf="extraInfoTriset" class="flex-content padded">
          <div>
            <div *ngIf="extraInfoTriset.NumeroAparelho && extraInfoTriset.NumeroAparelho > 0">
              <span>Aparelho</span>
              <p>{{extraInfoTriset.NumeroAparelho}}</p>
            </div>

            <div class="header-dica">
              <span [class]="exercicioTriset?.TipoExercicio">{{exercicioTriset?.TipoExercicio}}</span>
              <span *ngIf="extraInfoTriset.GrupoMuscular">{{extraInfoTriset.GrupoMuscular}}</span>
            </div>
          </div>

          <div *ngIf="extraInfoTriset.Dica">
            <p class="texto-ajuda">{{extraInfoTriset.Dica}}</p>
          </div>
        </div>
      </mat-card-actions>
    </div>
  </div>

  <div id="descanso-overlay" class="overlay green">
    <div class="timer-container">
      <div (click)="finalizaDescanso(true)" class="close cursor-pointer">
        <!-- este icone é o clear -->
        <i class="material-icons"> &#xE14C; </i>
      </div>
      <div *ngIf="descanso" class="timer-content">
        <canvas appDialDirective [width]="300" [height]="150" [max]="descanso" [current]="currentInterval" ></canvas>
        <div *ngIf="descanso > 0" class="timer-value">
          <span>{{currentInterval / 1000 | number: '.0-0'}}</span>
          <p>segundos</p>
        </div>
        <div *ngIf="!descanso" class="timer-value done">
          <span>✓</span>
        </div>
      </div>
    </div>
  </div>
</mat-card>

<div [class.active]="descansoIniciado" class="background-overlay animated"></div>
<app-exercicio-instrucao *ngIf="instrucao" (closeModal)="closeModal($event)" [instrucao]="instrucao"></app-exercicio-instrucao>
