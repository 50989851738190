import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AvatarCropComponent } from './avatar-crop/avatar-crop.component';
import { LoginService } from '../../../shared/services/login.service';
import { API, URLS } from '../../../../environments/environment';
import {EventEmitterService} from '../../../shared/services/event-emitter.service';
import {LocalStorageService} from '../../../shared/services/localstorage.service';
import * as $ from 'jquery';
import { AvatarService } from '../../../shared/services/avatar-service.service';
import { takeWhile, filter } from 'rxjs/operators';
import { PerfilService } from 'src/app/shared/services/perfil.service';
import { SweetalertService } from 'src/app/shared/services/sweetalert.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnChanges, OnInit, OnDestroy {
  @Input() objeto: any;
  @Input() width = 100;
  @Input() heigth = 100;
  @Input() buttons = true;
  @Input() pessoaId = null;
  @Input() url = null;
  @Input() isEditable = false;
  @Input() objetoImagem = null;
  @Input() fileId

  defaultAvatarUrl = 'assets/imagens/avatar-m.jpg';
  avatarUrl = null;
  isAlive = true;
  userData: any;
  noCache = new Date().getTime();
  loading = true;

  constructor(
    private dialog: MatDialog,
    private loginService: LoginService,
    private localStorage: LocalStorageService,
    private avatarService: AvatarService,
    private perfilService: PerfilService,
    private sweetAlertService: SweetalertService
  ) { }

  async ngOnInit() {
    if (this.pessoaId) {
      this.perfilService.obterAvatarTreinoPorPessoaId(this.pessoaId)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        data[0]?.File?.Url ? this.avatarUrl = data[0]?.File?.Url : this.avatarUrl = this.defaultAvatarUrl;
        this.loading = false;
      });
      return;
    }

    this.loginService
      .getSessionData().pipe(takeWhile(() => this.isAlive)).pipe(filter(user => user))
      .subscribe(async (user) => {
      this.userData = user;
      this.avatarUrl = await this.getAvatarUrl();
    });

    EventEmitterService
      .get('atualizar-avatar')
      .subscribe(async () => {
        this.avatarUrl = await this.getAvatarUrl();
      });

    EventEmitterService
      .get('remover-avatar')
      .subscribe(() => {
        this.avatarUrl = null;
      });
    EventEmitterService.get('wipeAvatarCache').subscribe(() => {
      this.wipeCache();
    });
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  abrir() {


    const dialogRef = this.dialog.open(AvatarCropComponent);

    dialogRef.afterClosed()
      .subscribe(result => {
        this.atualizaImagem(result);
      });
  }

  async ngOnChanges() {
    // if (this.objeto) {
    //   this.defaultAvatarUrl = this.objeto.Sexo === 'F'
    //     ? 'assets/imagens/avatar-f.jpg'
    //     : 'assets/imagens/avatar-m.jpg';
    // }
    // this.avatarUrl = await this.getAvatarUrl();
  }

  atualizaImagem(event: any): void {
    if (!event || !event.imagem) {
      return;
    }

    const imagem = event.imagem.base64;
    this.objeto.RemoverAvatar = false;
    this.objeto.Avatar = imagem.split('base64,')[1];
    this.avatarUrl = imagem;
    this.objeto.AvatarUrl = null;

    if (this.objetoImagem) {
      this.objetoImagem.remover = false;
      this.objetoImagem.atualizar = true;
    }
  }

  removeImagem(): void {
    this.objeto.Avatar = null;
    this.avatarUrl = this.defaultAvatarUrl;
    this.objeto.AvatarUrl = null;
    this.objeto.RemoverAvatar = true;

    if (this.objetoImagem) {
      this.objetoImagem.remover = true;
    }
  }

  getAvatarUrl(pessoaId?: string): string | Promise<string> {
    this.loading = true;
    if (this.url) {
      return this.url;
    }

    if (pessoaId) {
      return new Promise(async (resolve) => {
        await this.avatarService.getUrlByPessoaId(pessoaId).subscribe((obj) => {
          if(obj){
            resolve(obj.response);
          } else {
            resolve(this.verifyMyAvatarSex());
          }
        });
      });
    }

    return this.verifyMyAvatarSex();

  }

  verifyMyAvatarSex(): string {
    const userData = this.localStorage.get('userData');
    return userData && userData.Sex === 'F'
      ? 'assets/imagens/avatar-f.jpg'
      : 'assets/imagens/avatar-m.jpg';
  }

  upload(): void {
    $('body').find('#img-upload').click();
  }

  fileChangeAvatar($event) {
   const dialogRef = this.dialog.open(AvatarCropComponent);
    dialogRef.afterOpened().subscribe(() => {EventEmitterService.get('alterar-avatar').emit($event)})
    dialogRef.afterClosed()
      .subscribe(result => {
        this.atualizaImagem(result);
        $('body').find('#img-upload').val('');
    });
  }

  loadedImage(){
    this.loading = false;
  }

  wipeCache() {
    this.avatarService.wipeCache().subscribe(() => {}, error => console.error(error));
  }
}
