<section class="perfil">
  <h6 class="page-title p-t-20">
    <i class="material-icons">&#xE7FD;</i>
    Meu Perfil
  </h6>

  <mat-card>
    <div class="messages" *ngIf="loading || error">

      <p class="erro" *ngIf="!loading && error">Erro ao carregar as suas informações.</p>
    </div>

    <ng-container *ngIf="!loading && !error">
      <app-avatar [objetoImagem]="objetoImagem" [url]="url" [isEditable]="true" [objeto]="pessoa" [fileId]="fileId"></app-avatar>

      <p style="text-align: center; margin-top: 25px; font-weight: bold">Para mudar os seus dados, peça ao administrador.</p>

      <form [formGroup]="form" (ngSubmit)="salvar()">
          <label class="label-input-perfil">Primeiro Nome</label>
          <input class="input-perfil" [disabled]="true" [readOnly]="true" placeholder="Primeiro Nome" formControlName="PrimeiroNome" dividerColor="accent" >
          <label class="label-input-perfil">Sobrenome</label>
          <input class="input-perfil" [disabled]="true" [readOnly]="true" placeholder="Sobrenome" formControlName="Sobrenome" dividerColor="accent">

        <div class="mt-20 forms-actions">
          <button mat-button type="button" class="" (click)="alterarSenha()">ALTERAR SENHA</button>
          <button mat-button type="submit" class="btn btn-primary" [ladda]="salvando">Salvar</button>
        </div>
      </form>
    </ng-container>
  </mat-card>
</section>
