<main class="common-wrapper" [ngClass]="ios ? 'common-wrapper-ios' : ''">

  <div class="common-container">
    <img class="common-logo" src="assets/imagens/logo-treino.svg" alt="Logo Trei.no">

    <main class="m-t-30">
      <router-outlet></router-outlet>
    </main>
  </div>
</main>
