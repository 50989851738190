<mat-tab-group class="mat-tab-group" mat-align-tabs="left" [(selectedIndex)]="selectedIndex">
  <mat-tab label="{{ mesSelecionado === mesAtual && anoSelecionado === anoAtual ? 'Este mês' : obterDescricaoMes(mesSelecionado, anoSelecionado)}}" >
    <section>
      <article>
        <div class="box__title">
          <span>{{ obterDescricaoMes(mesSelecionado, anoSelecionado) }}</span>
          <i *ngIf="selectedIndex !== 0" class="fa fa-chevron-up" aria-hidden="true"></i>
        </div>
        <div class="divider-wrapper">
          <div class="divider div-transparent div-arrow-down"></div>
        </div>
        <div class="box__body">
          <div class="loading" *ngIf="carregando">
            <mat-progress-spinner mode="indeterminate" diameter="50" strokeWidth="2" ></mat-progress-spinner>
          </div>
          <div class="message" *ngIf="data && obterDadosPorMeses(mesSelecionado).length === 0">
            Não há registros no período.
          </div>
          <ul class="padding" *ngIf="data && data.length > 0 && obterDadosPorMeses(mesSelecionado).length > 0">
            <ng-container *ngFor="let frequencia of obterDadosPorMeses(mesSelecionado)">
              <li class="entrada" *ngIf="frequencia.DataEntrada">
                <div class="row-info">
                  <i class="fa fa-arrow-right" aria-hidden="true"></i><span> {{frequencia.DataEntrada | date: 'dd/MM/YYYY hh:mm': 'GMT-0'}} </span> <span> Entrada </span>
                </div>
                <legend>
                </legend>
              </li>
              <li class="saida" *ngIf="frequencia.DataSaida">
                <div class="row-info">
                  <i class="fa fa-arrow-left" aria-hidden="true"></i><span> {{frequencia.DataSaida | date: 'dd/MM/YYYY hh:mm': 'GMT-0'}} </span> <span> Saída </span>
                </div>
                <legend>
                </legend>
              </li>
            </ng-container>
          </ul>
        </div>
      </article>
    </section>
  </mat-tab>
  <mat-tab label="{{ anoSelecionado === anoAtual ? 'Este ano' : anoSelecionado }}">
    <section class="wrapper-options" *ngIf="carregando">
      <div class="loading">
        <mat-progress-spinner mode="indeterminate" diameter="50" strokeWidth="2" ></mat-progress-spinner>
      </div>
    </section>
    <section class="wrapper-options" *ngIf="!carregando">
      <article class="option" [ngClass]="{'disabled': !hasDadosNoMes}" *ngFor="let hasDadosNoMes of calcularMesesExibicao(); let i = index" (click)="exibirMesSelecionado(i, hasDadosNoMes)">
        <div class="box__title">
          <span>{{ obterDescricaoMes(i+1, this.anoSelecionado) }}</span>
        </div>
      </article>
    </section>
  </mat-tab>
  <mat-tab label=" Anos anteriores ">
    <section class="wrapper-options">
      <article class="option" *ngFor="let ano of anosExibicao(qtAnosAnterioresExibir); let i = index" (click)="selecionarAno(ano)">
        <div class="box__title">
          <span>{{ ano }}</span>
        </div>
      </article>
    </section>
  </mat-tab>
</mat-tab-group>
