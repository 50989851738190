import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CachedHttpClient } from './cached-http-client.service';
import { API, URLS } from '../../../environments/environment';
import { Observable, Observer} from 'rxjs';
import { AvatarObject, ProxyResponse } from '../interfaces/avatar.interface';
import { PerfilService } from './perfil.service';


@Injectable({
  providedIn: 'root'
})
export class AvatarService {

  dateNow = new Date();

  constructor(
    private http: HttpClient,
    private cachedHttp: CachedHttpClient,
    private perfilService: PerfilService
  ) {}

  public wipeCache(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
        this.cachedHttp.wipeCache().subscribe((wiped) => {
            if (wiped) {
              observer.next(wiped);
              return observer.complete();
            }
          }, (error) => {
            throw new Error(error);
          }
        );
      }
    );
  }

  public getUrlByObject(objectId: string): ProxyResponse {
    return {
      response: `${URLS.base_blob_storage}/${objectId}.png?cache=${this.dateNow}`
    };
  }

  public getUrlByPessoaId(pessoaId: string): Observable<ProxyResponse> {
    return new Observable((observer: Observer<ProxyResponse>) => {
      this.perfilService.obterAvatarTreinoPorPessoaId(pessoaId).subscribe((data) => {
        if (data && data.FileId) {
          observer.next(this.getUrlByObject(data.FileId));
        }
        if (!data || !data.FileId) {
          observer.next(null);
        }
        observer.complete();
      }, (error) => {
        observer.next(null);
        console.error(error);
      });
    });
  }

}
