import { Ficha } from './../../core.interfaces';
import { Component, Input } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-card-ficha-info',
  templateUrl: './card-ficha-info.component.html',
  styleUrls: ['./card-ficha-info.component.scss']
})
export class CardFichaInfoComponent {
  @Input() ficha: Ficha = {};

  constructor() { }

  toggle(id) {
    $(id).toggleClass('open');
    $(id).find('.accordion-toggle').slideToggle();
  }

  temExtra() {
    return this.ficha.Observacao || this.ficha.Patologias || this.temObjetivos();
  }

  temObjetivos() {
    return this.ficha.ObjetivoCF || this.ficha.ObjetivoFM
      || this.ficha.ObjetivoHT || this.ficha.ObjetivoIM
      || this.ficha.ObjetivoRP || this.ficha.ObjetivoSD
      || this.ficha.ObjetivoOTTexto;
  }
}
