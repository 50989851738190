import { Injectable } from '@angular/core';

@Injectable()
export class SoundsService {
  audioPaths = './assets/audios/';
  sounds;
  audio: any;

  constructor() {
    this.sounds = {
      start: new Audio(`${this.audioPaths}start.mp3`),
      rest: new Audio(`${this.audioPaths}rest.mp3`),
      done: new Audio(`${this.audioPaths}done.mp3`)
    };

  }

  playStart() {
    this.stopAllSounds();
    this.sounds.start.play();
  }

  playDone() {
    this.stopAllSounds();
    this.sounds.done.play();
  }

  playRest() {
    this.stopAllSounds();
    this.sounds.rest.play();
  }

  stopAllSounds() {
    this.stop(this.sounds.start);
    this.stop(this.sounds.done);
    this.stop(this.sounds.rest);
  }

  stop(sound) {
    sound.pause();
    sound.currentTime = 0;
  }
}
