import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoExercicio'
})
export class TipoExercicioPipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      value = value.toString();
      switch (value) {
        default: return value;
        case 'M':
            return 'Musculação';
        case 'C':
            return 'Cardio';
        case 'F':
            return 'Funcional';
        case 'A':
            return 'Alongamento';
      }
    }
  }
}
