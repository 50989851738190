import { Component, OnDestroy, OnInit } from '@angular/core';

import { LoginService } from '../../shared/services/login.service';

@Component({
  selector: 'app-escolha',
  templateUrl: './escolha.component.html',
  styleUrls: ['./escolha.component.scss']
})
export class EscolhaComponent implements OnInit, OnDestroy {
  userData: any = {};
  isAlive = true;

  constructor(
    private loginService: LoginService,
  ) { }

  ngOnInit() {
    this.loginService.getSessionData()
      .takeWhile(() => this.isAlive)
      .subscribe(response => {
        this.userData = response;
      });
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  getAvatarUrl(): string {
    return this.userData && this.userData.AvatarUrl
      ? this.userData.AvatarUrl
      : this.userData && this.userData.Sex === 'F'
        ? 'assets/imagens/avatar-f.jpg'
        : 'assets/imagens/avatar-m.jpg';
  }
}
