<section>
  <div class="title">
    Agora digite o código que foi enviado no seu e-mail
  </div>

  <form [formGroup]="formVerificacao">
    <mat-form-field class="m-t-10 m-b-20">
      <input matInput name="codigo" formControlName="Code" placeholder="Código Verificação" dividerColor="accent">
      <mat-error *ngIf="formVerificacao.get('Code').getError('required')">Campo Obrigatório</mat-error>
      <mat-error *ngIf="formVerificacao.get('Code').getError('minlength')">Código de verificacão deve ter 6 dígitos</mat-error>
      <mat-error *ngIf="formVerificacao.get('Code').getError('maxlength')">Código de verificacão deve ter 6 dígitos</mat-error>
    </mat-form-field>
  </form>

  <div class="m-t-20">
    <button [ladda]="carregando" class="btn" (click)="confirmarEmail()">Confirmar!</button>
  </div>

  <p *ngIf="erro" class="erro m-t-20">{{erro}}</p>

  <p *ngIf="loading" class="text-muted m-t-20">Enviando...</p>

  <div class="m-t-20" *ngIf="!sucesso">
    <a class="green" (click)="reenviarEmail()">Não recebi o email, enviar novamente</a>
  </div>

  <div *ngIf="sucesso">
    <h4 class="white">Email reenviado com sucesso!</h4>
  </div>

  <div class="m-t-20">
    <a [routerLink]="['/login']" class="green">Voltar para o início</a>
  </div>

  <app-footer></app-footer>
</section>
