import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { LoginService } from './../shared/services/login.service';

@Injectable()
export class CommonGuard implements CanActivate, CanActivateChild {
  constructor(private loginService: LoginService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const _route: any = route;

    if (_route && _route._routerState && _route._routerState.url && _route._routerState.url === '/politica-privacidade') {
      return true;
    }

    return this.loginService.checkLogin()
      .map(activate => {
        if (activate) {
          this.router.navigate(['/painel']);
          return false;
        }

        return true;
      });
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
