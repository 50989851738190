<section *ngIf="assistirVideo">
  <h3 class="text-white">
    Primeiro, assista o
    <span class="texto-verde-100">vídeo</span>
    e conheça o aplicativo
    trei<span class="texto-verde-100">.</span>no
  </h3>

  <div class="video">
    <iframe src="https://www.youtube.com/embed/TW_BtZCLqkU?autoplay=1&controls=0&rel=0&showinfo=0&autohide=0"
      frameborder="0">
    </iframe>
  </div>

  <button class="btn m-t-20" (click)="fazerCadastro()">Já assisti o vídeo</button>

  <div class="m-t-20">
    <a class="texto-verde-100" [routerLink]="['/login']">
      Voltar ao login
    </a>
  </div>
</section>

<section *ngIf="!assistirVideo">
  <h3 class="text-white mt-0">Faça seu cadastro, é
    <span class="texto-verde-100">rápido, fácil</span> e
    <span class="texto-verde-100">grátis</span> para testar
  </h3>

  <form [formGroup]="form" (ngSubmit)="submit(form)" class="text-left form">
    <mat-form-field>
      <input matInput formControlName="FirstName" name="primeiro-nome" required placeholder="Primeiro Nome" maxlength="100" dividerColor="accent">
      <mat-error *ngIf="form.get('FirstName').getError('required')">Campo Obrigatório</mat-error>
    </mat-form-field>

    <mat-form-field>
      <input matInput formControlName="LastName" name="sobrenome" required placeholder="Sobrenome" maxlength="100" dividerColor="accent">
      <mat-error *ngIf="form.get('LastName').getError('required')">Campo Obrigatório</mat-error>
  </mat-form-field>

    <mat-form-field>
      <input matInput formControlName="Email" name="email" required placeholder="E-mail" maxlength="100" dividerColor="accent">
      <mat-error *ngIf="form.get('Email').getError('required')">Campo Obrigatório</mat-error>
      <mat-error *ngIf="form.get('Email').getError('email')">Digite um email válido</mat-error>
  </mat-form-field>

    <mat-form-field>
      <input matInput formControlName="Password" name="senha" type="password" autocomplete="new-password" required placeholder="Senha" maxlength="100" dividerColor="accent">
      <mat-error *ngIf="form.get('Password').getError('required')">Campo Obrigatório</mat-error>
      <mat-error *ngIf="form.get('Password').getError('minlength')">A senha deve ter pelo menos 6 caracteres</mat-error>
    </mat-form-field>

    <mat-form-field>
      <input matInput formControlName="ConfirmPassword" name="confirmar-senha" type="password" autocomplete="confirm-password" required placeholder="Confirme a Senha" maxlength="100" dividerColor="accent">
      <mat-error *ngIf="form.get('ConfirmPassword').getError('required')">Campo Obrigatório</mat-error>
      <mat-error *ngIf="form.get('ConfirmPassword').getError('MatchPassword')">Senhas não são iguais</mat-error>
    </mat-form-field>

    <div class="container-checkbox text-white m-t-10" style="position: relative;">
      <mat-checkbox [ngClass]="submitted && form.get('PrivacyAccepted').getError('required') ? 'checkbox-error' : ''" formControlName="PrivacyAccepted">Concordo com a <a [routerLink]="['/politica-privacidade']">política de privacidade</a></mat-checkbox>
      <span style="position: relative; left: 0; bottom: 12px; font-size: 12px; width: 100%; display: inline-block; color: #f44336;" class="text-error" class="text-error" *ngIf="submitted && form.get('PrivacyAccepted').getError('required')">Obrigatório aceitar as políticas de privacidade</span>
    </div>

    <div class="container-checkbox text-white m-t-10" style="position: relative;">
      <mat-checkbox [ngClass]="submitted && form.get('TermsAccepted').getError('required') ? 'checkbox-error' : ''" formControlName="TermsAccepted">Concordo com os termos do Trei.no e da Actuar</mat-checkbox>
      <span style="position: relative; left: 0; bottom: 12px; font-size: 12px; width: 100%; display: inline-block; color: #f44336;" class="text-error" *ngIf="submitted && form.get('TermsAccepted').getError('required')">Obrigatório aceitar os termos</span>
    </div>

    <div>
      <p class="text-error" *ngIf="erro">{{ erro }}</p>
    </div>

    <div class="text-center m-t-20">
      <a class="texto-verde-100 m-t-20" (click)="_voltar()">
        Voltar para a seleção de cadastros
      </a>

      <div style="width: 10px;"></div>

      <button type="submit" class="btn m-t-20" [ladda]="carregando">
        Criar sua conta grátis
      </button>
    </div>
  </form>

  <app-footer></app-footer>
</section>
