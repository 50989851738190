import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { API } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AvaliacoesService {

  constructor( private http: HttpClient) { }

  obterAvaliacoesPorPessoaId(id) {
    return this.http.get(`${API.afig}/Avaliacoes/ObterAvaliacoesPessoaPorId?id=${id}`);
  }

  obterAvaliacoesResumidasPorPessoaId(pessoaId) {
    return this.http.get(`${API.afig}/Avaliacoes/ObterAvaliacoesResumidaPorPessoaId?pessoaId=${pessoaId}`, {
      withCredentials: true
    })
  }
}
