export const contasAReceberDictionary = {
    "PersonId": "PessoaId",
    "Recurring": "Recorrente",
    "DueDate": "DataVencimento",
    "GroupingPersonId": "PessoaAgrupadoraId",
    "AccountsReceivable": {
        "ContasReceber": {
            "AccountReceivableId": "ContaReceberId",
            "DueOn":"DataVencimento",
            "PersonId": "PessoaId",
            "PersonSubscriptionId": "AssinaturaPessoaId",
            "ProductSaleId": "VendaProdutoId",
            "PersonServiceId": "ServicoPessoaId",
            "SourceType": "TipoProveniente",
            "RegistrationType": "TipoCadastro",
            "Status": "Situacao",
            "SourceId": "ProvenienteId",
            "TotalReceivedAmount": "VrTotalRecebido",
            "CurrentOpenAmount": "VrAbertoAtual",
            "CurrentPenaltyAmount": "VrMultaAtual",
            "CurrentInterestAmount": "VrJuroAtual",
            "OriginalAmount": "VrOriginal",
            "SourceDescription": "DescricaoProveniente", 
            "IsActiveRecurrencePagueLa": "RecorrenciaPagueLaAtiva",  
            "StarOn": "PeriodoInicio",
            "EndOn": "PeriodoFinal",
        }
    },
}