import { AgendamentoAulasComponent } from './agendamento-aulas/agendamento-aulas.component';
import { ModuleWithProviders } from '@angular/core';
import { ChildrenOutletContexts, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../guards/auth.guard';
import { AcademiaComponent } from './academia/academia.component';
import { AulaComponent } from './aulas/aula.component';
import { ConfigComponent } from './config/config.component';
import { CoreComponent } from './core.component';
import { FeedComponent } from './feed/feed.component';
import { PerfilComponent } from './perfil/perfil.component';
import { SobreComponent } from './sobre/sobre.component';
import { TreinoComponent } from './treino/treino.component';
import { ChatComponent } from './chat/chat.component';
import { MinhasFrequenciasComponent } from './minhas-frequencias/minhas-frequencias.component';
import { MinhasReservasComponent } from "./minhas-reservas/minhas-reservas.component";
import { TimersEscolhaComponent } from './timers/timers-escolha/timers.component';
import { TabataComponent } from './timers/tabata/tabata.component';
import { TimersCoreComponent } from './timers/core.component';
import { CronometroComponent } from './timers/cronometro/cronometro.component';
import { TimerComponent } from './timers/timer/timer.component';
import { EmomComponent } from './timers/emom/emom.component';
import { ParticipantesTurmaComponent } from './agendamento-aulas/participantes-turma/participantes-turma.component';
import { AvaliacoesFisicasComponent } from './avaliacoes-fisicas/avaliacoes-fisicas.component';
import { VisualizarAvaliacaoComponent } from './avaliacoes-fisicas/visualizar-avaliacao/visualizar-avaliacao.component';
import { DadosPagamentoComponent } from './dados-pagamento/dados-pagamento.component';
import {PagueLaComponent} from "./dados-pagamento/pague-la/pague-la.component";

export const CoreRoutes: Routes = [{
  path: 'painel',
  component: CoreComponent,
  canActivateChild: [ AuthGuard ],
  children: [
    { path: '', redirectTo: '/painel/feed', pathMatch: 'full' },
    { path: 'aula', component: AulaComponent },
    { path: 'aula/:aulaId', component: AulaComponent },
    { path: 'feed', component: FeedComponent },
    { path: 'treino', component: TreinoComponent },
    { path: 'academia', component: AcademiaComponent },
    { path: 'sobre', component: SobreComponent },
    { path: 'perfil', component: PerfilComponent },
    { path: 'config', component: ConfigComponent },
    { path: 'chat', component: ChatComponent },
    { path: 'aulas', component: AgendamentoAulasComponent },
    { path: 'participantes/:turmaId', component: ParticipantesTurmaComponent },
    { path: 'aulas/:aulaId', component: AgendamentoAulasComponent },
    { path: 'minhas-reservas', component: MinhasReservasComponent },
    { path: 'minhas-frequencias', component: MinhasFrequenciasComponent },
    { path: 'dados-pagamento', component: DadosPagamentoComponent },
    { path: 'dados-pagamento/pagar/:id', component: PagueLaComponent },
    { path: 'dados-pagamento/pagar/pix/:id', component: PagueLaComponent },
    { path: 'timers-escolha', component: TimersEscolhaComponent },
    { path: 'avaliacoes-fisicas', component: AvaliacoesFisicasComponent },
    { path: 'avaliacoes-fisicas/avaliacao/:id', component: VisualizarAvaliacaoComponent },
    { path: 'timers', component: TimersCoreComponent,
      children: [
        { path: 'tabata', component: TabataComponent },
        { path: 'cronometro', component: CronometroComponent },
        { path: 'timer', component: TimerComponent },
        { path: 'emom', component: EmomComponent },
      ]
    },
  ]
}];

export const CoreRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(CoreRoutes);
