import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRouting, AppRoutingProviders } from './app.routing';
import { CommonComponentModule } from './common/common.module';
import { CoreModule } from './core/core.module';
import { _401Component } from './errors/401/401.component';
import { _404Component } from './errors/404/404.component';
import { CommonGuard } from './guards/common.guard';
import { HttpIntercept } from './shared/services/httpintercept.service';
import { LoginService } from './shared/services/login.service';
import { CrashReportingService } from './shared/services/crash-reporting.service';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { RouterService } from './shared/services/router.service';
import { IonicModule } from '@ionic/angular';
import { MatTabsModule } from '@angular/material/tabs';
// Must export the config
export const firebaseTreinoConfig = {
  apiKey: 'AIzaSyC_xoC0POWmnx1v8FRsUA-lrufqpS-byGI',
  authDomain: 'treino-e2dfb.firebaseapp.com',
  databaseURL: 'https://treino-e2dfb.firebaseio.com',
  projectId: 'treino-e2dfb',
  storageBucket: 'treino-e2dfb.appspot.com',
  messagingSenderId: '629985405426'
};

@NgModule({
  imports: [
    AppRouting,
    CoreModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonComponentModule,
    IonicModule.forRoot(),
    MatTabsModule,
  ],
  declarations: [
    AppComponent,
    _404Component,
    _401Component
  ],
  providers: [
    CommonGuard,
    AppRoutingProviders,
    LoginService,
    CrashReportingService,
    ErrorHandlerService,
    RouterService,
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpIntercept,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
