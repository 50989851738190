import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  db = 'treino/';

  constructor() {}

  get(key) {
    return JSON.parse(localStorage.getItem(this.db + key));
  }

  set(key, obj) {
    const data = typeof obj === 'string' ? obj : JSON.stringify(obj);
    localStorage.setItem(this.db + key, data);
  }

  remove(key) {
    localStorage.removeItem(this.db + key);
  }

  /* apagar tudo menos contasSalvas, config e authorizationData */
  /* iterar pelas keys, criar um array de Observable from promises */
  /* retornar 1 só observable */
  async clear() {
    const keys = Object.keys(localStorage);
      for await (const key of keys) {
        if (key !== 'treino/contasSalvas' && key !== 'treino/config' && key !== 'treino/authorizationData') {
          /* guarda o observable de remoção da chave */
            this.remove(key.split('treino/')[1]);
        }
      }
  }
}
