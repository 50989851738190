import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class NetworkService {
  connection$: Observable<any>;
  connected: boolean;

  constructor() {
    this.connection$ = Observable.merge(
      Observable.of(navigator.onLine),
      Observable.fromEvent(window, 'online').map(() => {
        this.connected = true;
        return true;
      }),
      Observable.fromEvent(window, 'offline').map(() => {
        this.connected = false;
        return false;
      }));
  }

  checkConnection(): Observable<boolean> {
    return this.connection$;
  }

  isConnected() {
    return this.connected;
  }
}
