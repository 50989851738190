import { Component, Input } from '@angular/core';
import { DevicesService } from '../../../shared/services/devices.service';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';


@Component({
  selector: 'app-card-postagem',
  templateUrl: './card-postagem.component.html',
  styleUrls: ['./card-postagem.component.scss']
})
export class CardPostagemComponent {
  @Input('postagem') postagem: any;

  constructor(
    private devicesService: DevicesService,
  ) { }

  async abrirLink(url: string) {
    if (Capacitor.getPlatform() === 'ios' || 'android') {
      await Browser.open({url});
    } else {
      window.open(url, '_blank');
    }
  }
}
