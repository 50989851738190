import { Injectable } from '@angular/core';

@Injectable()
export class DevicesService {

  constructor() { }

  isAndroid() {
    return window.navigator.userAgent.match(/Android/i);
  }

  isBlackBerry() {
    return window.navigator.userAgent.match(/BlackBerry/i);
  }

  isIOS() {
    return window.navigator.userAgent.match(/iPhone|iPad|iPod/i);
  }

  isOpera() {
    return window.navigator.userAgent.match(/Opera Mini/i);
  }

  isWindows() {
    return window.navigator.userAgent.match(/IEMobile/i);
  }

  isMobile() {
    return (this.isAndroid() || this.isBlackBerry() || this.isIOS() || this.isOpera() || this.isWindows());
  }
}
