import { DevicesService } from '../shared/services/devices.service';
import { OnInit, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-common',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommonComponent implements OnInit  {
  ios: any;
  mobile: any;

  constructor(
    private devicesService: DevicesService
  ) { }

  ngOnInit(): void {
    this.ios = this.devicesService.isIOS();
    this.mobile = this.devicesService.isMobile();
  }
}
