import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';

@Injectable()
export class VibracaoService {
  config: any;

  constructor(private configService: ConfigService) {
    this.configService.getConfig().subscribe(value => {
      this.config = value;
    });
  }

  vibrar(tempo: number) {
    if (this.config.vibraintervalo === true) {
      document.addEventListener('deviceready', function () {
        navigator.vibrate(tempo);
      });
    }
  }
}
