import { MatDialog } from '@angular/material/dialog';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha.component';
import { ErrorHandlerService } from './../../shared/services/error-handler.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { LoginService } from '../../shared/services/login.service';
import { SweetalertService } from '../../shared/services/sweetalert.service';
import { PerfilService } from './../../shared/services/perfil.service';

import { EventEmitterService } from './../../shared/services/event-emitter.service';
import { LocalStorageService } from '../../shared/services/localstorage.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit, OnDestroy {
  salvando: boolean;
  loading: boolean;
  form: UntypedFormGroup;
  error: boolean;
  isAlive = true;
  userData: any;
  pessoa: any;
  url: any;
  objetoImagem: any;
  fileId: any;

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private loginService: LoginService,
    private perfilService: PerfilService,
    private sweetAlertService: SweetalertService,
    private errorHandlerService: ErrorHandlerService,
    private localStorage: LocalStorageService,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      PrimeiroNome: [null, Validators.required],
      Sobrenome: [null, Validators.required],
    });

    this.loading = true;

    this.loginService
      .getSessionData()
      .takeWhile(() => this.isAlive)
      .subscribe(user => {
        this.userData = user;
        this.carregarDados();
      });
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  carregarDados() {
    this.perfilService
      .getPerfil(this.userData.UserTenantId)
      .takeWhile(() => this.isAlive)
      .filter((res: any) => {
        if (res.carregando) {
          this.loading = true;
          return false;
        }

        return true;
      })
      .subscribe(({ perfil }) => {
        this.perfilService.obterAvatarTreinoPorPessoaId(perfil.PessoaId)
        .pipe(takeWhile(() => this.isAlive))
        .subscribe((data) => {
          if (data[0]?.File?.Url) {
            this.objetoImagem = data[0];
            this.fileId = data[0]?.FileId;
            this.url = data[0].File.Url;
          } else {
            this.userData && this.userData.Sex === 'F' ? this.url = 'assets/imagens/avatar-f.jpg' : this.url =  'assets/imagens/avatar-m.jpg';
          }
          if (!perfil) {
            this.error = true;
          } else {
            this.pessoa = perfil;

            const form = this.form.controls;
            form.PrimeiroNome.setValue(this.pessoa.PrimeiroNome);
            form.Sobrenome.setValue(this.pessoa.Sobrenome);
          }

          this.loading = false;
        });
      });
  }

  alterarSenha() {
    this.dialog.open(AlterarSenhaComponent);
  }

  salvar() {
    EventEmitterService.get('wipeAvatarCache').emit();

    if (this.objetoImagem && this.objetoImagem.FileId && this.pessoa.Avatar) {
      this.atualizarImagem();
      return;
    }

    if (!this.objetoImagem && this.pessoa.Avatar) {
      this.adicionarImagem();
      return;
    }

    if (this.fileId && (this.objetoImagem?.remover || this.pessoa.RemoverAvatar)) {
      this.removerAvatar();
      return;
    }


  }

  atualizarImagem(): void {
    this.salvando = true;

    const fileObj = {
      PersonId: this.pessoa.PessoaId,
      File: {
        FileName: 'image.png',
        Data: this.pessoa.Avatar,
        Url: null
      }
    }

    this.perfilService.atualizarImagem(fileObj)
    .pipe(takeWhile(() => this.isAlive))
    .subscribe((data) => {
      this.perfilService.setUserPhotoFlag(data.FileId).subscribe(() => {
        this.fileId = data.FileId
        this.salvando = false;
      }), (err) => {
        this.errorHandlerService.error(err);
        this.salvando = false;
      };
      EventEmitterService.get('wipeAvatarCache').emit();
      this.pessoa.Avatar = null;
      this.sweetAlertService.success('Alterações realizadas com sucesso').takeWhile(() => this.isAlive).subscribe();
    }, (err) => {
      this.errorHandlerService.error(err);
      this.salvando = false;
    });
  }

  adicionarImagem(): void {
    this.salvando = true;

    const fileObj = {
      PersonId: this.pessoa.PessoaId,
      File: {
        FileName: 'image.png',
        Data: this.pessoa.Avatar,
        Url: null
      }
    }

      this.perfilService.adicionarAvatar(fileObj)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data: any) => {
        this.perfilService.setUserPhotoFlag(data.FileId).subscribe(() => {
          this.fileId = data.FileId
        }), (err) => {
          this.errorHandlerService.error(err);
          this.salvando = false;
        };
        
        this.salvando = false;
        this.pessoa.Avatar = null;
        this.sweetAlertService.success('Alterações realizadas com sucesso').takeWhile(() => this.isAlive).subscribe();
      }, (err) => {
        this.errorHandlerService.error(err);
        this.salvando = false;
      });

  }

  excluirConta(): void {
    this.sweetAlertService.confirm('Excluir conta', 'Tem certeza de que deseja excluir esta conta?').subscribe(() => {
      // TODO
      setTimeout(() => {
        this.sweetAlertService.success('Conta excluída com sucesso!', 'Sucesso!').subscribe();
      }, 200);
    });
  }

  removerAvatar(): void {
    this.perfilService.removerImagem(this.fileId).subscribe(() => {
      this.sweetAlertService.success('Alterações realizadas com sucesso').takeWhile(() => this.isAlive).subscribe();
    })
    this.fileId = false;
  }

}
