<form [formGroup]="form" (ngSubmit)="salvar(form)">
  <h1 mat-dialog-title>Alterar Senha</h1>

  <div mat-dialog-content>
    <div class="form">
      <mat-form-field>
        <input matInput type="password" required placeholder="Senha atual" formControlName="OldPassword" dividerColor="accent">
        <mat-error *ngIf="hasError('OldPassword')?.required">Campo Obrigatório</mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput type="password" required placeholder="Nova senha" formControlName="NewPassword" dividerColor="accent">
        <mat-error *ngIf="hasError('NewPassword')?.required">Campo Obrigatório</mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput type="password" required placeholder="Confirme nova senha" formControlName="ConfirmPassword" dividerColor="accent">
        <mat-error *ngIf="hasError('ConfirmPassword')?.required">Campo Obrigatório</mat-error>
        <mat-error *ngIf="hasError('ConfirmPassword')?.MatchPassword">Senhas não são iguais</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="null" [disabled]="salvando">Cancelar</button>
    <button mat-button type="submit" class="btn btn-primary" [ladda]="salvando">Salvar</button>
  </div>
</form>
