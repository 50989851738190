import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { switchMap, filter, take } from 'rxjs/operators';

import { LoginService } from './login.service';

@Injectable()
export class HttpIntercept implements HttpInterceptor {
  isRefreshingToken = false;
  loginService: LoginService;
  reTryRefreshToken = 0;
  tokenSubject: BehaviorSubject < string > = new BehaviorSubject < string > (null);

  constructor(private inj: Injector) {}

  // tslint:disable-next-line:max-line-length
  intercept(req: HttpRequest < any > , next: HttpHandler): Observable < HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse < any > | HttpUserEvent < any >> {
    this.loginService = this.inj.get(LoginService);

    return next.handle(this.prepareRequest(req, this.loginService.getToken()))
      .catch((error) => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 400:
              return this.handle400Error(error);
            case 401:
              return this.handle401Error(req, next);
            default:
              return this.catchError(error);
          }
        } else {
          return this.catchError(error);
        }
      });
  }

  prepareRequest(request, token): HttpRequest < any > {
    let headers = request.headers.set('Accept-Language', 'pt-BR');

    if (!request.withCredentials) {
      return request.clone({
        headers
      });
    }

    headers = headers.set('Authorization', `Bearer ${token}`);

    if (request.headers.get('Content-Type') === 'application/x-www-form-urlencoded') {
      headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    }

    return request.clone({
      headers,
      withCredentials: false
    });
  }

  handle401Error(req: HttpRequest < any > , next: HttpHandler) {
    if (this.isRefreshingToken && this.reTryRefreshToken > 5) {
      this.loginService.logOut();
    }
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);
      return this.loginService.refresh_token().pipe(
        switchMap((data: any) => {
          this.reTryRefreshToken++;
        if (data) {
          this.tokenSubject.next(data.Token);
          this.reTryRefreshToken = 0;
          return next.handle(this.prepareRequest(req, data.Token));
        }
        return this.loginService.unauthorized();
      }))

        .catch((error) => {
          let errorParsed = null;
          const errors = error?.error?.errors?.DomainValidations ?? null;

          if (error && error.error && typeof error.error === 'string') {
            try {
              errorParsed = JSON.parse(error.error);
            } catch (e) { }
          }

          if (error.status === 400 && errors.length > 0 && errors.find((errorStr) => errorStr === 'Não foi encontrado o token.')) {
            this.reTryRefreshToken = 0;
            return this.loginService.unauthorized(error);
          }

          if (error.status === 400 && errorParsed && errorParsed.Token && errorParsed.Token.length > 0 && errorParsed.Token[0] === 'Não foi encontrado o token.') {
            return this.loginService.unauthorized(error);
          }

          if (error.status === 401) {
            return this.loginService.unauthorized(error);
          }

          if (error.status === 400) {
            return this.handle400Error(error);
          }

          return this.catchError(error);
        })
        .finally(() => {
          this.isRefreshingToken = false;
        });
    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null)
      ).pipe(
        take(1)
      ).pipe(
        switchMap(token => next.handle(this.prepareRequest(req, token))
        ));
    }
  }

  handle400Error(error: HttpErrorResponse) {
    try {
      if (error
        && error.status === 400
        && error.error?.errors?.DomainValidations[0] === 'Token expirado.') {
          return this.loginService.unauthorized(error);
      }

      const {error: errorMessage} = JSON.parse(error.error);

      if (error
        && error.status === 400
        && (errorMessage === 'invalid_grant' || errorMessage === 'invalid_clientId')
      ) {
        // If we get a 400 and the error message is 'invalid_grant' or 'invalid_clientId', the token is no longer valid so logout.
        return this.loginService.unauthorized(error);
      }
    } catch(e) { }

    return throwError(error);
  }

  catchError(error: HttpErrorResponse) {
    try {
      /* quando a resposta é Sucesso (200, 203...) mas o payload é vazio, a lib entende que deu erro */
      /* pois sempre espera por um json. */
      if (error.status >= 200 && error.status < 300) {
        /* retorna ok, para não cair no catch do component que fez a requisição */
        const res = new HttpResponse({
          body: null,
          headers: error.headers,
          status: error.status,
          statusText: error.statusText,
          url: error.url
        });

        return of(res);
      }

      const parsedError = Object.assign({}, error, {
        error: error.error ? JSON.parse(error.error) : ''
      });

      const err = new HttpErrorResponse(parsedError);
      return throwError(err);
    } catch (e) {
      return throwError(error);
    }
  }
}
