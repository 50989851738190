import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject,  } from 'rxjs/Rx';

import { API } from './../../../environments/environment';
import { Ficha } from './../../core/core.interfaces';
import { StorageService } from './storage.service';
import { LocalStorageService } from './localstorage.service';
import { LoginService } from './login.service';

@Injectable()
export class TreinoService {
  userData: any;
  fichaStream = new ReplaySubject(1);
  licencaStream = new ReplaySubject(1);

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private storageService: StorageService,
    private localStorageService: LocalStorageService
  ) { }

  // Faz a atualização da ficha caso encontre diferença na versão local com a versão do servidor
  updateFichaAtiva(fichaAtiva: any, id: string): void {
    const value = this.localStorageService.get('dataUltimaRequisicao');
        const data = new Date();
        const hoje = `${data.getDate()}/${data.getMonth() + 1}/${data.getFullYear()}`;
        const dataUltimaRequisicao = value ? value.dataUltimaRequisicao : null;

        this.verificaAtualizacoes(id)
          .take(1)
          .subscribe((res: any) => {
            // Caso nao tenha ficha, zerar
            if (!res) {
              this.storageService.remove('fichaAtiva');
              this.fichaStream.next({ fichaAtiva: null });
              return;
            }

            // QUANDO FAZER UMA NOVA REQUISIÇÃO DE FICHA ???
            if (
              // se não tiver ficha ativa
              !fichaAtiva

              // se a data de modificação da ficha for diferente da armazenada
              // ou seja: a ficha foi atualizada
              || res.DataModificacao !== fichaAtiva.DataModificacao

              // se a ficha ativa no servidor é diferente
              // da armazenada
              || res.FichaId !== fichaAtiva.FichaId

              // se a ultima vez que a ficha foi atualizada foi ontem
              // o servidor atualiza as fichas todos os dias porque tem
              // uma ficha para cada dia, além de resetar os valores...
              // tem que fazer a requisição pelo menos 1 vez todos os dias
              || hoje !== dataUltimaRequisicao
            ) {
              this.getFicha(id)
                .take(1)
                .subscribe((response: any) => {
                  this.storageService.set('fichaAtiva', response);
                  this.fichaStream.next({ fichaAtiva: response });
                  this.localStorageService.set('dataUltimaRequisicao', { dataUltimaRequisicao: hoje });
                }, error => {
                  this.fichaStream.next({ fichaAtiva: null });
                });
            } else {
              this.fichaStream.next({ fichaAtiva: fichaAtiva });
            }
          }, error => {
            this.fichaStream.next({ fichaAtiva: null });
          });
  }

  getFicha(id) {
    return this.http
      .get(API.treino + '/Fichas/ObterFichaAtivaPorPessoaId?id=' + id, {
        withCredentials: true
      });
  }

  // Obter ajustes
  getAjuste(): Observable<Object> {
    return this.http
      .get(`${API.treino}/Ajustes/ObterAjuste`, {
        withCredentials: true,
      });
  }

  // Verifica o estatus da ficha atual no servidor
  verificaAtualizacoes(id: string): Observable < Object > {
    return this.http
      .get(API.treino + '/Fichas/ObterStatusFichaAtivaPorPessoaId?id=' + id, {
        withCredentials: true
      });
  }

  // Obtem a ficha ativa atualizada
  obterFichaAtiva(id: string): ReplaySubject < Ficha > {
    const data = this.storageService.get('fichaAtiva');
        this.fichaStream.next({ fichaAtiva: data });
        if (navigator.onLine) {
          this.fichaStream.next({ carregando: true });
          this.updateFichaAtiva(data, id);
        }
    return this.fichaStream;
  }

  // Obtem a ajuda do exercício
  obterExercicioAjuda(id: string): Observable < Object > {
    return this.http
      .get(API.treino + '/Exercicios/ObterExercicioAjudaPorId?pessoaid=' + id, {
        withCredentials: true
      })
  }

  atualizarCarga(id: string, carga: string): Observable < Object > {
    return this.http
      .put(API.treino + '/Fichas/AtualizarCarga?id=' + id + '&carga=' + carga, null, {
        withCredentials: true
      })
  }

  // Adiciona uma série concluida ao exercício
  incrementarSerie(id: string): Observable < Object > {
    return this.http
      .post(API.treino + '/Fichas/IncrementarSerie?id=' + id, null, {
        withCredentials: true
      })
  }

  // Remove uma série concluida do exercício
  decrementarSerie(id: string): Observable < Object > {
    return this.http
      .post(API.treino + '/Fichas/DecrementarSerie?id=' + id, null, {
        withCredentials: true
      })
  }

  // Restaura as séries concluídas do exercício
  reiniciarSerie(id: string): Observable < Object > {
    return this.http
      .post(API.treino + '/Fichas/ReiniciarSerie?id=' + id, null, {
        withCredentials: true
      })
  }

  // Finaliza o exercício igualando o número de séries concluidas ao número de séries original
  finalizarSerie(id: string): Observable < Object > {
    return this.http
      .post(API.treino + '/Fichas/FinalizarSerie?id=' + id, null, {
        withCredentials: true
      })
  }

  salvarEstado(ficha: Ficha): void {
    this.storageService.set('fichaAtiva', ficha);
  }

  // Atualiza as informações (licença) da academia
  updateLicenca(): void {
    this.http.get(API.treino + '/Admin/ObterLicencaTreino', {
      withCredentials: true
    })
    .subscribe((response: any) => {
      this.storageService.set('licenca', response);
      this.licencaStream.next(response);
    });
  }

  // Retorna as informações (licença) da academia
  obterLicencaAcademia(): ReplaySubject < any > {
        this.licencaStream.next(this.storageService.get('licenca'));
        if (navigator.onLine) {
          this.updateLicenca();
        }
    return this.licencaStream;
  }

  obterAjustes() {
    return this.http.get(API.treino + '/Ajustes/ObterAjuste', {
      withCredentials: true
    });
  }

  getMediasByExercise(id): Observable<Object> {
    return this.http.get(`${API.treino}/Exercicios/GetMediasByExerciceId?id=${id}`, {
      withCredentials: true
    });
  }
}
