import { AbstractControl } from '@angular/forms';

export class CustomValidators {
  static matchPasswordNew(AC: AbstractControl) {
    const password = AC.get('NovaSenha').value;
    const confirmPassword = AC.get('NovaSenhaConfirmacao').value;
    if (password !== confirmPassword) {
      AC.get('NovaSenhaConfirmacao').setErrors({ MatchPassword: true });
    } else {
      return null;
    }
  }

  static matchPasswordOld(AC: AbstractControl) {
    const password = AC.get('OldPassword').value;
    const confirmPassword = AC.get('NewPassword').value;
    if (password !== confirmPassword) {
      AC.get('ConfirmPassword').setErrors({ MatchPassword: true });
    } else {
      return null
    }
  }
}
