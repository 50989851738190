import { Component, OnInit, OnDestroy } from '@angular/core';

import { ConfigService } from './../../shared/services/config.service';
import {DevicesService} from "../../shared/services/devices.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit, OnDestroy {
  config: any;
  isAlive = true;

  isMobile: any;

  constructor(
    private configService: ConfigService,
    private deviceService: DevicesService,
    private router: Router
  ) { }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();

    this.configService
      .getConfig()
      .takeWhile(() => this.isAlive)
      .subscribe(value => {
        this.config = value;
      });
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  toggleConfig(config, ev) {
    this.configService.toggleConfig(config, ev);
  }

  irParaVisualizarPoliticaDePrivacidade(): void {
    this.router.navigate(['/painel/visualizar-politica']);
  }
}
