<mat-card *ngIf="ficha && temExtra()">
  <mat-card-content class="accordion">
    <div id="ProfessorId" class="accordion-item" *ngIf="ficha?.ProfessorId" (click)="toggle('#ProfessorId')">
      <h3>
        Professor
        <mat-icon>&#xE315;</mat-icon>
      </h3>
      <!-- Na ficha só vem ProfessorId, precisamos de um NomeProfessor para apresentar tbm -->
      <p class="accordion-toggle">{{ ficha?.NomeProfessor }}</p>
    </div>

    <div id="Observacao" class="accordion-item" *ngIf="ficha?.Observacao" (click)="toggle('#Observacao')">
      <h3>
        Observações
        <mat-icon>&#xE315;</mat-icon>
      </h3>
      <p class="accordion-toggle">{{ ficha?.Observacao }}</p>
    </div>

    <div id="Patologias" class="accordion-item" *ngIf="ficha?.Patologias" (click)="toggle('#Patologias')">
      <h3>
        Patologias
        <mat-icon>&#xE315;</mat-icon>
      </h3>
      <p class="accordion-toggle">{{ ficha?.Patologias }}</p>
    </div>

    <div id="Objetivos" class="accordion-item" *ngIf="temObjetivos()" (click)="toggle('#Objetivos')">
      <h3>
        Objetivos
        <mat-icon>&#xE315;</mat-icon>
      </h3>
      <div class="objetivos accordion-toggle">
        <div *ngIf="ficha.ObjetivoCF" class="check">
          <i class="material-icons">&#xE5CA;</i>
          Condicionamento Físico
        </div>
        <div *ngIf="ficha.ObjetivoFM" class="check">
          <i class="material-icons">&#xE5CA;</i>
          Fortalecimento Muscular
        </div>
        <div *ngIf="ficha.ObjetivoHT" class="check">
          <i class="material-icons">&#xE5CA;</i>
          Hipertrofia
        </div>
        <div *ngIf="ficha.ObjetivoIM" class="check">
          <i class="material-icons">&#xE5CA;</i>
          Enrijecimento Muscular
        </div>
        <div *ngIf="ficha.ObjetivoRP" class="check">
          <i class="material-icons">&#xE5CA;</i>
          Redução % Gordura
        </div>
        <div *ngIf="ficha.ObjetivoSD" class="check">
          <i class="material-icons">&#xE5CA;</i>
          Saúde
        </div>
        <div *ngIf="ficha.ObjetivoOTTexto" class="check">
          <i class="material-icons">&#xE5CA;</i>
          {{ficha.ObjetivoOTTexto }}
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
