import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appDialDirective]'
})
export class DialDirective implements OnChanges {
  @Input() height: number;
  @Input() width: number;
  @Input() max: number;
  @Input() current: number;
  context: CanvasRenderingContext2D;
  path: CanvasRenderingContext2D;
  startAngle: number = -Math.PI / 2;
  endAngle: number = Math.PI + Math.PI / 2;
  centerX: number;
  centerY: number;
  radius: number;
  pathInitialized = false;

  constructor(private elRef: ElementRef) { }

  setPath(): void {
    this.path = this.elRef.nativeElement.getContext('2d');
    this.path.clearRect(0, 0, this.width, this.height);
    this.path.beginPath();
    this.path.arc(this.centerX, this.centerY, this.radius, this.startAngle, this.endAngle, false);
    this.path.lineWidth = 2;
    this.path.strokeStyle = '#00D65A';
    this.path.stroke();
    this.pathInitialized = true;
  }

  ngOnChanges(): void {
    this.centerX = this.width / 2;
    this.centerY = this.height / 2;
    this.radius = 60;

    const porc: number = ((100 * (this.current / 1000)) / this.max) / 100;
    const currentAngle: number = this.endAngle - (2 * Math.PI * (1 - porc));

    this.context = this.elRef.nativeElement.getContext('2d');
    this.context.clearRect(0, 0, this.width, this.height);
    this.setPath();
    this.context.beginPath();
    this.context.arc(this.centerX, this.centerY, this.radius, this.startAngle, currentAngle, false);
    this.context.lineWidth = 2;
    this.context.strokeStyle = '#fff';
    this.context.stroke();
  }
}
