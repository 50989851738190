import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  NgZone,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { DevicesService } from '../shared/services/devices.service';
import { URLS } from './../../environments/environment';
import { ConfigService } from './../shared/services/config.service';
import { EventEmitterService } from './../shared/services/event-emitter.service';
import { LoginService } from './../shared/services/login.service';
import { NetworkService } from './../shared/services/network.service';
import { SweetalertService } from './../shared/services/sweetalert.service';
import { TreinoService } from './../shared/services/treino.service';
import { ChatService } from '../shared/services/chat.service';
import { AcessoService } from '../shared/services/acesso.service';
import { LocalStorageService } from '../shared/services/localstorage.service';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Browser } from '@capacitor/browser';

import {
  ActionPerformed,
  PushNotificationSchema,

  PushNotifications,
  Token,
} from '@capacitor/push-notifications';


import { verify } from 'crypto';

import { FCM } from '@ionic-native/fcm/ngx';
import {Capacitor} from '@capacitor/core';
import {RouterService} from '../shared/services/router.service';
import {PerfilService} from '../shared/services/perfil.service';
declare var $: any;
// declare var FCMPlugin: any;
// declare var universalLinks: any;

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CoreComponent implements OnInit, OnDestroy, AfterViewChecked {
  hide: boolean;
  open: boolean;
  licencaTreino: any;
  config: any;
  isMobile: any;
  conectado: any;
  refreshing: boolean;
  url: string;
  userData: any = {};
  possuiTreino = true;
  backgroundColor = '';
  isAlive = true;
  senderId: string;
  dateNow = new Date().getTime();
  avatarObj: any;
  chatInfo = null;
  headerChat = null;
  loadedAvatar = false;

  timeout = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private elRef: ElementRef,
    private snackBar: MatSnackBar,
    private loginService: LoginService,
    private treinoService: TreinoService,
    private configService: ConfigService,
    private deviceService: DevicesService,
    private sweetAlert: SweetalertService,
    private networkService: NetworkService,
    private changeDetector: ChangeDetectorRef,
    private chatService: ChatService,
    private acessoService: AcessoService,
    private zone: NgZone,
    private localStorage: LocalStorageService,
    private location: Location,
    private fcm: FCM,
    private barcodeScanner: BarcodeScanner,
    private routerService: RouterService,
    private perfilService: PerfilService,
  ) {
    window.onbeforeunload = () => { this.chatService.fecharConexao(); };
  }


  ngOnInit() {

    this.isMobile = this.deviceService.isMobile();

    this.checkConnection();

    this.url = this.router.url;
    this.verifyBackgroundColorByRoute(this.url);
    this.router.events
      .takeWhile(() => this.isAlive)
      .subscribe(event => {
        this.url = this.router.url;
        this.verifyBackgroundColorByRoute(this.url);
        if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0);
        }
      });

    this.loginService
      .getSessionData()
      .takeWhile(() => this.isAlive)
      .subscribe(response => {
        if (response) {
          this.userData = response;
        }
      });

    this.configService
      .getConfig()
      .subscribe(value => {
        this.config = value;

        if (!this.config) {
          this.configService.toggleConfig('vibraintervalo', true);
          this.configService.toggleConfig('notificaintervalo', true);
        }
    });

    document.addEventListener('deviceready', () => {
      // universalLinks.subscribe('acessoqrcode', (eventData) => {
      //   this.zone.run(() => {
      //     const params = Object.keys(eventData.params);
      //
      //     if (!params || params.length < 2) {
      //       this.sweetAlert.error('Não foi possível validar o acesso').subscribe();
      //       return;
      //     }
      //
      //     this.sweetAlert.notificacao('Validando acesso', 'Em alguns instantes o seu acesso será validado', true).subscribe();
      //
      //     this.acessoService.solicitacaoAcesso(params[1], params[0]).subscribe(() => {
      //       this.sweetAlert.success('O acesso foi validado').subscribe();
      //     }, () => {
      //       this.sweetAlert.error('Não foi possível validar o acesso').subscribe();
      //     });
      //   });
      // });

      // universalLinks.subscribe('agendamentoqrcode', (eventData) => {
      //   this.zone.run(() => {
      //     const params = Object.keys(eventData.params);

      //     if (!params) {
      //       this.sweetAlert.error('Não foi possível agendar aula.').subscribe();
      //       return;
      //     }

      //     this.router.navigate(['/painel/aulas', params[0]]).then();
      //   });
      // });

      PushNotifications.addListener('pushNotificationReceived', (data) => {
      });

      PushNotifications.addListener('registration',
        () => {
          this.treinoService.obterLicencaAcademia().takeWhile(() => this.isAlive).subscribe(response => {
            if (response) {
              this.licencaTreino = response;
            }
          });
        }
      );

      PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
        if (notification.actionId === 'tap') {
          if (notification.notification &&
            notification.notification.data &&
            notification.notification.data.chatType &&
            notification.notification.data.chatType === 'chat') {
              await this.router.navigate(['/painel/chat']);
          }
        }
          return;
      });
    });

    document.addEventListener('resume', () => {
      this.chatService.reconectar();
    });

    document.addEventListener('online', () => {
      this.chatService.reconectar();
    });

    this.inicializarChat();

    EventEmitterService.get('nova-mensagem-chat').subscribe((data) => {
      if (data && this.userData && this.userData.UserTenantId !== data.SenderId) {
        const url: string = this.router.url;

        if (url.indexOf('chat') === -1) {
          this.abrirNovaNotificacao(data);
        }
      }
    });

    EventEmitterService.get('mostrar-chat-header').subscribe((data) => {
      const url: string = this.router.url;
        if (url.includes('chat')) {
          this.headerChat = data;
        }
    });

    EventEmitterService.get('esconder-chat-header').subscribe(() => {
      this.headerChat = null;
      this.loadedAvatar = false;
    });

    this.localStorage.remove('avatar');
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
    this.onSwipe();

    document.addEventListener('deviceready', () => {
      if (!this.loginService.isLoginEmail && Capacitor.getPlatform() === 'web') {
        // universalLinks.subscribe(null, (eventData: any) => {
        //   if (eventData && eventData.hash && eventData.hash.includes('acessorapido')) {
        //     this.zone.run(() => {
        //       this.loginService.isLoginEmail = true;
        //     });
        //   }
        // });
      }
    });
  }

  abreMenu() {
    $('.menu').css({
      visibility: 'visible'
    });

    $('.menu').one({
      'click': function () {
        $(this).css({
          visibility: 'hidden'
        });
      }
    });
  }

  iniciarTreino(): void {
    if (this.possuiTreino) {
      this.router.navigate(['/painel/treino']);
    } else {
      this.sweetAlert.error('Você ainda não tem treino.');
    }
  }

  // Finaliza a sessão ativa
  logOut(): void {
    const data = {
      title: 'Tem certeza?',
      text: 'Deseja realmente sair?',
      type: 'info',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      closeOnConfirm: true,
      closeOnCancel: true
    };

    this.sweetAlert.show(data)
      .subscribe((SAResponse: boolean) => {
        if (SAResponse) {
          this.loginService.logOut();
        }
      });
  }

  redirecionaActuar() {
    const auth = this.loginService.getAuthorizationData();

    this.sweetAlert.notificacao('Abrindo a área do professor', 'Você será redirecionado dentro de alguns instantes.', true).subscribe();

    if (this.isMobile) {
      Browser.open({url:`${URLS.actuar_web}/#/common/login/token/${auth.TokenId}`}).then();
    } else {
      setTimeout(() => {
        window.open(`${URLS.actuar_web}/#/common/login/token/` + auth.TokenId, '_blank');
      }, 3000);
    }
  }

  refresh() {
    if (!this.conectado) {
      this.openSnackBar();
      return;
    }

    // girar icone
    $('#refresh-btn mat-icon').addClass('rotate');
    setTimeout(() => { $('#refresh-btn mat-icon').removeClass('rotate'); }, 700);

    // bloquear botao por 2s
    this.refreshing = true;
    setTimeout(() => { this.refreshing = false}, 2000);

    // enviar evento de refresh
    EventEmitterService.get('refresh').emit();
  }

  checkConnection(): void {
    this.networkService
      .checkConnection()
      .takeWhile(() => this.isAlive)
      .subscribe((conectado: any) => {
        this.conectado = conectado;

        if (conectado) {
          this.closeSnackBar();
        } else {
          this.openSnackBar();
        }
      });
  }

  openSnackBar() {
    this.snackBar.open('Você está offline.', null, { duration: 2000 });
  }

  closeSnackBar() {
    this.snackBar.dismiss();
  }

  inicializarChat() {
    this.chatService.getChatInfo().subscribe((data: any) => {
      if (data && data.UltimaMensagem && !data.UltimaMensagem.Lida) {
        EventEmitterService.get('nova-mensagem-chat').emit(null);
      }

      this.chatService.abrirConexao().then(() => {
        this.chatService.entrarNoChat(data.ChatId);
      });
    });
  }

  abrirNovaNotificacao(data: any) {
    this.chatInfo = data;

    const notificacao = document.getElementById('notificacao-chat');

    notificacao.classList.remove('fadeOutDown');
    notificacao.classList.add('fadeInDown');

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.fecharNovaNotificacao();
    }, 5000);
  }

  fecharNovaNotificacao() {
    this.timeout = null;
    this.chatInfo = null;

    const notificacao = document.getElementById('notificacao-chat');
    notificacao.classList.remove('fadeInDown');
    notificacao.classList.add('fadeOutDown');
  }

  irParaChat() {
    this.fecharNovaNotificacao();
    this.router.navigate(['/painel/chat']).then();
  }

  onSwipe() {
    $(document).swipe({
      swipe: (event, direction) => {
        if (direction === 'up') {
          if (this.chatInfo) {
            this.fecharNovaNotificacao();
          }
        }
      },
      excludedElements: 'button, input, select, textarea, .noSwipe'
    });
  }

  abrirLeitorQR(): void {

    if (Capacitor.getPlatform() === 'web') {

      this.sweetAlert.alert('', 'Baixe o app Trei.no para utilizar este recurso.').subscribe();
      return;
    }

    this.barcodeScanner.scan(
      {
        preferFrontCamera: false, // iOS and Android
        showFlipCameraButton: false, // iOS and Android
        showTorchButton: false, // iOS and Android
        torchOn: false, // Android, launch with the torch switched on (if available)
        prompt: 'Posicione o QR Code dentro da área destacada', // Android
        resultDisplayDuration: 0, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
        formats: 'QR_CODE', // default: all but PDF_417 and RSS_EXPANDED
        orientation: 'portrait', // Android only (portrait|landscape), default unset so it rotates with the device
        disableAnimations: false, // iOS
        disableSuccessBeep: true // iOS and Android
      }
    ).then((result) => {
      if (!result.text) {
        return;
      }

      const info = this.obterParametrosAcesso(result.text);

      if (result.text.includes('acessoqrcode')) {

        if (!info) {
          this.sweetAlert.error('Não foi possível ler o QR Code.').subscribe();
          return;
        }

        this.sweetAlert.notificacao('Enviando', 'Em alguns instantes sua identificação será enviada', true).subscribe();
        this.acessoService.solicitacaoAcessoNewApi(info.hardwareId, info.monitorId).subscribe(() => {
          this.sweetAlert.success('Sua identificação foi enviada', 'Enviado!').subscribe();
        }, () => {
          this.sweetAlert.error('Não foi possível enviar sua identificação').subscribe();
        });
      } else {
        const split1 = result.text.split('?');
        const params = split1[1];

        if (!params) {
          this.sweetAlert.error('Não foi possível agendar aula.').subscribe();
          return;
        }

        this.zone.run(() => {
          this.sweetAlert.notificacao('Aguarde', 'Sua solicitação esta sendo processada', true).subscribe();

          this.router.navigate(['/painel/aulas', split1[1]]).then();
        });
      }
    }, () => {
      this.sweetAlert.error('Não foi possível ler o QR Code').subscribe();
    }
    );
  }

  obterParametrosAcesso(str: string) {
    const split1 = str.split('?');
    let split2 = null;

    if (split1 && split1.length >= 2) {
      split2 = split1[1].split('&');
    }

    if (split2 && split2.length === 2) {
      return {
        hardwareId: split2[0],
        monitorId: split2[1]
      };
    } else {
      return null;
    }
  }
  toggleSidebar() {
    this.open = !this.open;
    document.querySelector('app-tabata').classList.toggle('openSidebar');
  }

  closeSidebar() {
    this.open = false;
    document.querySelector('app-tabata').classList.remove('openSidebar');
  }

  verifyBackgroundColorByRoute(url) {
    this.backgroundColor =  url.includes('/painel/timers') ? '#0c1420' : 'white';
  }

  goback() {
    if (this.url.includes('/painel/participantes/') ||
      this.url.includes('/painel/timers/') ||
      this.url.includes('/painel/avaliacoes-fisicas/avaliacao') ||
      this.url.includes('/painel/dados-pagamento/pagar')
    ) {
      this.router.navigate([this.routerService.getPreviousUrl()], {relativeTo: this.activatedRoute, skipLocationChange: true}).then();
      return;
    }
    this.router.navigate(['/painel/feed'], {relativeTo: this.activatedRoute, skipLocationChange: true}).then();
  }

}
