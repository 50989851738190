import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-pague-la',
  templateUrl: './pague-la.component.html',
  styleUrls: ['./pague-la.component.scss'],
})
export class PagueLaComponent implements OnInit {
  source: any;
  isPix: boolean;
  constructor(private route: ActivatedRoute,
              private sanitizer: DomSanitizer) {
    this.isPix = location.href.includes('pix');
    this.route.params.subscribe((data) => {
      if (data) {
        this.source = this.sanitizer
          .bypassSecurityTrustResourceUrl(`https://app.pague.la/#/checkout${this.isPix ? '/pix' : ''}?tokenId=${data.id}`);
      }
    });
  }

  ngOnInit() {}

}
