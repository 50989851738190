import { ActivatedRoute, Router } from '@angular/router';
import {Component, OnDestroy, OnInit, NgZone, AfterViewInit} from '@angular/core';

import { LoginService } from '../../shared/services/login.service';
import { NetworkService } from '../../shared/services/network.service';
import { StorageService } from '../../shared/services/storage.service';
import { SweetalertService } from '../../shared/services/sweetalert.service';
import { ErrorHandlerService } from '../../shared/services/error-handler.service';
import { TransferidorDeDadosService } from '../../shared/services/transferidor-de-dados-service';

import { Md5 } from 'ts-md5/dist/md5';
import {takeUntil, takeWhile} from 'rxjs/operators';

declare var navigator: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  public mask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  loginData: any = {
    name: '',
    email: '',
    senha: '',
    account: '',
  };

  erro: any;
  contas: any;
  mobile = true;
  isAlive = true;
  offline = false;
  lembrar: boolean;
  contasSalvas = [];
  carregando = false;
  habilitaLogin = false;
  contaSelecionada: any;

  loginTransiente = false;

  sid = null;

  isPassword = false;

  isAcessoRapido = false;

  isLogandoAcessoRapido = false;
  isEmailCriacaoSenhaEnviado = false;

  loginDataEmail = null;

  constructor(
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private sweetAlert: SweetalertService,
    private networkService: NetworkService,
    private storageService: StorageService,
    private errorHandlerService: ErrorHandlerService,
    private transferidorDeDadosService: TransferidorDeDadosService,
  ) {  }

  ngOnInit(): void {
    this.route.params.takeWhile(() => this.isAlive).subscribe(params => {
      this.loginData.email = params.email;
      this.sid = params.sid;

      if (params['pessoaId'] && params['codigo']) {
        this.loginAutomaticoEmail(params['pessoaId'], params['codigo']);
        this.isAcessoRapido = true;
        this.isLogandoAcessoRapido = true;
        return;
      }

      if (params['codigo']) { this.loginAutomatico(params['codigo']); }

      if (this.sid) {
        this.loginAutomaticoSid(this.sid);
      }
    });

    this.networkService.checkConnection().takeWhile(() => this.isAlive).subscribe((isConnected) => {
      this.offline = !isConnected;
    });

    const dadosLogin = this.transferidorDeDadosService.obterDadoPorId('dadosLogin');
    if (dadosLogin) {
      this.loginData.email = dadosLogin.email;
      this.loginData.senha = dadosLogin.senha;

      if (this.loginData.senha) {
        this.isPassword = true;
      }

      if (this.loginData.email && this.loginData.senha) {
        this.submitLogin();
      }
    } else {
        this.contasSalvas = this.storageService.get('contasSalvas');
    }
  }

  ngAfterViewInit(): void {
    document.addEventListener('deviceready', () => {
      setTimeout(() => {
        if (navigator && navigator.splashscreen) {
          navigator.splashscreen.hide();
        }
      }, 0);
    }, false);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  submitLogin(salvar?: boolean): void {
    if (this.offline) {
      this.erro = 'Sem Internet, verifique sua conexão.';
      return;
    }

    if (this.loginData.email && !this.isPassword) {
      this.erro = null;
      this.carregando = true;

      this.loginService.verificarEmail(this.loginData.email).subscribe((data) => {

        if (!data && !data?.EmailConfirmed) {
          this.sweetAlert.alert('Alerta!', 'Conta não encontrada.').subscribe(() => {});
        }

        if (data?.EmailConfirmed) {
          this.mostrarSenha();
          this.carregando = false;

          setTimeout(() => {
            const inputSenha = document.getElementById('mat-input-3');
            if (!inputSenha) { return; }
            inputSenha.focus();
          }, 200);

          return;
        }

        this.erro = 'Conta não encontrada.';
        this.carregando = false;
        return;
      }, (err) => {
        console.error(err);
        this.erro = this.errorHandlerService.getFirstValidationMessage(err);
        this.carregando = false;
      });

      return;
    }

    if (!this.loginData.email && !this.isPassword) {
      this.erro = 'Preencha o campo email.';
      return;
    }

    if (!this.loginData.email && !this.loginData.senha) {
      this.erro = 'Preencha todos os campos.';
      return;
    }

    if (!this.loginData.email && this.loginData.senha) {
      this.erro = 'O campo E-Mail não pode ser vazio';
      return;
    }

    if (this.loginData.email && !this.loginData.senha) {
      this.erro = 'O campo Senha não pode ser vazio';
      return;
    }

    if (!this.isValidEmail(this.loginData.email)) {
      this.erro = 'Insira um e-mail válido, exemplo email@email.com';
      return;
    }

    this.erro = null;
    this.carregando = true;

    this.loginData.email = this.loginData.email.toLowerCase();

    const loginData: any = {
      AppSource: 'treino,actuar,facilfit',
      Email: this.loginData.email.trim(),
      Password: <any>Md5.hashStr(this.loginData.email + this.loginData.senha),
    };

    this.loginService.login(loginData).takeWhile(() => this.isAlive).subscribe(response => {
      if (response && response.phoneConfirmed === false) {
        this.salvarDadosLogin();
        this.router.navigate(['confirmar-telefone', response.userId]).then();
        return;
      }

      if (response && response.emailConfirmed === false) {
        this.salvarDadosLogin();
        this.router.navigate(['confirmar-email', loginData.userName, response.userId]).then();
        return;
      }

      if (salvar === true || this.lembrar === true) {
        this.salvarStorage(loginData);
      }

      if (response && Array.isArray(response)) {
        this.habilitaLogin = false;
        this.carregando = false;
        if (response.length === 1) {
          this.submitConta(response[0]);
          return;
        }
        this.contas = response;
      } else {
        this.transferidorDeDadosService.removerDadoPorId('dadosLogin');
        this.redirecionar();
      }
    }, (error) => {
      this.carregando = false;
      this.erro = this.errorHandlerService.getFirstValidationMessage(error);
      this.sweetAlert.alert('Alerta!',this.erro ).subscribe(() => {});
    });
  }

  salvarStorage(loginData): void {
    if (!this.contasSalvas) {
      this.contasSalvas = [];
    }

    const contaJaSalva = this.contasSalvas.find(conta => conta.Email === loginData.Email);

    if (!contaJaSalva) {
      delete loginData.Password;
      this.contasSalvas.push(loginData);
      this.storageService.set('contasSalvas', this.contasSalvas);
    }
  }

  redirecionar(): void {
    const redirect = this.loginService.redirectUrl && this.loginService.redirectUrl !== '/login' ? this.loginService.redirectUrl : '/painel';
    this.loginService.getUserData().subscribe(() => {
      console.log('test');
      this.carregando = false;
      this.router.navigate(['/painel']).then();
    }, (error) => {
      this.carregando = false;
      this.erro = this.errorHandlerService.getFirstValidationMessage(error);
    });
  }

  removerConta(conta: any): void {
    const data = {
      closeOnCancel: true,
      closeOnConfirm: false,
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: 'Sim',
      confirmButtonText: 'Não',
      title: 'Deseja remover essa conta?',
    };

    this.sweetAlert.show(data).takeWhile(() => this.isAlive).subscribe(SAResponse => {
      if (!SAResponse) {
        this.contasSalvas.splice(this.contasSalvas.indexOf(conta), 1);
        this.storageService.set('contasSalvas', this.contasSalvas);
        this.sweetAlert.success('Sua conta foi removida dos favoritos').subscribe();
      }
    });
  }

  habilitarLogin(): void {
    this.habilitaLogin = true;
    this.contas = null;
    this.loginData = {
      email: '',
      senha: '',
      userId: '',
      account: '',
    };
  }

  selecionarConta(conta: any): void {
    this.contaSelecionada = conta;
  }

  selecionarContaSalva(conta: any): void {
    this.selecionarConta(conta);
    if (conta.Email) {
      this.loginData.email = conta.Email;
      this.isPassword = true;
    }
  }

  submitConta(conta: any): void {
    this.selecionarConta(conta);

    if (conta.email) {
      this.loginData.email = conta.email;
    }

    if (conta.userName) {
      this.loginData.email = conta.userName;
    }

    this.loginData.name = conta.name;
    this.loginData.companyId = conta.companyId;
    this.loginData.userCompanyId = conta.userCompanyId;

    if (this.isAcessoRapido) {
      this.loginAutomaticoEmail(null, null, conta);
    } else {
      this.mudarPessoa(conta);
    }
  }

  mudarPessoa(conta) {
    this.loginService.changePerson(conta.PersonId).subscribe(() => {

      this.redirecionar();
    });
  }

  loginAutomatico(id): void {
    this.loginService.obterRepositorioTransiente(id).takeWhile(() => this.isAlive).subscribe((response: any) => {
      if (!response) {
       this.erro = 'Não foi possível realizar o login automático.';
          setTimeout (() => {
            this.router.navigate(['/login']).then();
          }, 3000);
        return;
      }

      let token;
      let refreshToken;

      if (response && response.Conteudo) {
        refreshToken = response.Conteudo.split(' ; ')[0];
        token = response.Conteudo.split(' ; ')[1];
      }

      this.loginAutomaticoToken(refreshToken, token);
    }, error => {
      this.erro = this.errorHandlerService.getFirstValidationMessage(error);
    });
  }

  loginAutomaticoToken(token, refreshToken): void {
    this.loginService.loginPorToken(token, refreshToken);

    this.loginService.getUserData().subscribe(response => {
      if (response) {
        const data: any = {};

        data.userName = response.UserName;
        data.access = token;
        data.refresh = refreshToken;
        data.userId = response.Id;

        this.loginService.fillAuthData(data);
        this.router.navigate(['/painel']).then();
      }
    });
  }

  loginAutomaticoSid(sid): void {
    const data: any = {
      sid: sid,
      origin: 'Treino'
    };

    this.loginTransiente = true;

    this.loginService.code(data).subscribe(() => {
      this.redirecionar();
    }, (error) => {
      setTimeout(() => {
        this.router.navigate(['/login']).then();
        this.erro = this.errorHandlerService.getFirstValidationMessage(error);
      }, 3000);

      console.error(error);
    });
  }

  isValidEmail(email): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  mostrarSenha(): void {
    this.isPassword = true;
  }

  loginAutomaticoEmail(pessoaId: string, sid: string, conta?: any): void {
    const loginData: any = {
      origin: 'treino',
      userName: undefined,
      password: undefined,
      grantType: 'code_email',
      sid: sid ? sid : this.loginDataEmail.sid,
      userId: pessoaId ? pessoaId : this.loginDataEmail.userId,
      companyId: conta && conta.companyId ? conta.companyId : undefined,
      userCompanyId: conta && conta.userCompanyId ? conta.userCompanyId : undefined,
    };

    this.loginDataEmail = loginData;

    this.loginService.loginEmail(loginData).subscribe((response) => {
      if (response && response.phoneConfirmed === false) {
        this.router.navigate(['/confirmar-telefone/' + this.loginDataEmail.userId + '/' + this.loginDataEmail.sid]).then();
      } else {
        if (response && Array.isArray(response)) {
          this.contas = response;
          this.carregando = false;
          this.habilitaLogin = false;
        } else {
          this.redirecionar();
        }
      }
    }, (error) => {
      console.error(error);
      this.isLogandoAcessoRapido = false;
      this.erro = this.errorHandlerService.getFirstValidationMessage(error);
    });
  }

  salvarDadosLogin(): void {
    this.transferidorDeDadosService.adicionarDado('dadosLogin', { email: this.loginData.email, senha: this.loginData.senha })
  }
}
