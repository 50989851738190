import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import {  ReplaySubject } from 'rxjs';

@Injectable()
export class ConfigService {
  configStream: ReplaySubject< any > = new ReplaySubject();
  config: any = { };

  constructor(private storageService: StorageService) {
    this.configStream.next(this.config);
  }

  getConfig() {
        this.config = this.storageService.get('config');
        this.configStream.next(this.config);

    return this.configStream;
  }

  toggleConfig(config, ev) {
    this.config = this.config || {};

    this.config[config] = ev.checked;
    this.storageService.set('config', this.config);
    this.configStream.next(this.config);
  }
}
