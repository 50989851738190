import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { LoginService } from '../../shared/services/login.service';
import { SweetalertService } from '../../shared/services/sweetalert.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';

import { CustomValidators } from '../../shared/validators/custom-validators';

import { Md5 } from 'ts-md5/dist/md5';

@Component({
  selector: 'app-redefinir-senha',
  templateUrl: './redefinir-senha.component.html',
  styleUrls: ['./redefinir-senha.component.scss']
})
export class RedefinirSenhaComponent implements OnInit {
  erro: any;
  senha = '';
  email: string;
  usuario: string;
  sucesso: boolean;
  carregando = false;

  form: UntypedFormGroup;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private sweetAlertService: SweetalertService,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      Email: [ null, [ Validators.required ] ],
      NovaSenha: [ null,  [ Validators.required ] ],
      NovaSenhaConfirmacao: [ null, [ Validators.required ] ],
      Code: [ null, [ Validators.required ] ],
    }, {
      validator: CustomValidators.matchPasswordNew,
    });

    this.route.params.subscribe(values => {
      this.form.controls['Code'].setValue(values.codigo || null);
      this.form.controls['Email'].setValue(values.email || null);
      this.obterDadosUsuario(values.userId);
    });
  }

  obterDadosUsuario(id): void {
    this.loginService.getUserInfo(id).subscribe((response: any) => {
      this.form.controls['Email'].setValue(response.email);
    }, (error) => {
      console.error(error);
      this.erro = this.errorHandlerService.getFirstValidationMessage(error);
    });
  }

  submit({ value, valid }): void {
    if (!valid) { return; }

    this.carregando = true;

    const data = {
      Email: value.Email,
      Code: value.Code,
      Password: Md5.hashStr(value.Email + value.NovaSenha),
    };

    this.loginService.redefinirSenha(data).subscribe(() => {
      this.sweetAlertService.success('Sua senha foi redefinida com sucesso.').subscribe(() => {
        this.irParaLogin();
      });

      this.carregando = false;
    }, error => {
      console.error(error);
      this.carregando = false;
      this.erro = this.errorHandlerService.getFirstValidationMessage(error);
    });
  }

  irParaLogin(): void {
    this.router.navigate(['/login']).then();
  }
}
