import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { AulasService } from '../../shared/services/aulas.service';
import { LoginService } from '../../shared/services/login.service';
import { PerfilService } from '../../shared/services/perfil.service';
import { TreinoService } from '../../shared/services/treino.service';
import { PostagensService } from '../../shared/services/postagens.service';
import { EventEmitterService } from '../../shared/services/event-emitter.service';

import * as moment from 'moment';
import {ErrorHandlerService} from '../../shared/services/error-handler.service';

declare let navigator: any;

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss'],
})
export class FeedComponent implements OnInit, OnDestroy {
  carregandoTreino: boolean;
  obtendoPostagens = false;
  feed: Array<any> = [];
  obtendoAulas = false;
  possuiTreino = true;
  postagens: any = [];
  loading: boolean;
  aulas: any = [];
  postagens$: any;
  sessionId: any;
  userData: any;
  ficha$: any;

  isAlive = true;

  isAcademia = false;
  nomeAcademia = null;
  novaMensagemChat = false;
  assinaturaVencida = false;
  dataVencimentoAssinatura = null;
  pessoaidAvatar: any;

  objetoImagem = null;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private aulasService: AulasService,
    private treinoService: TreinoService,
    private perfilService: PerfilService,
    private postagensService: PostagensService,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  ngOnInit() {
    this.loginService.getSessionData().takeWhile(() => this.isAlive).filter(user => user).subscribe(user => {

      this.userData = user;
      this.sessionId = user.UserTenantId;
      this.atualizaFicha(user.UserTenantId);
      this.mostrarDataVencimento();
    });

    this.atualizarPostagens();

    EventEmitterService.get('refresh').takeWhile(() => this.isAlive).subscribe(() => {
      this.atualizarPostagens();
      this.atualizaFicha(this.sessionId);

      this.mostrarDataVencimento();
    });

    EventEmitterService.get('nova-mensagem-chat').subscribe((data) => {
      if (data === null || (data && this.userData && this.userData.UserTenantId !== data.senderId)) {
        const url: string = this.router.url;

        if (url.indexOf('chat') === -1) {
          this.novaMensagemChat = true;
        }
      }
    });

    EventEmitterService.get('fechar-instrucao').emit();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  abrirChat(): void {
    this.novaMensagemChat = false;
    this.router.navigate(['/painel/chat']).then();
  }

  desativaSplash(): void {
    document.addEventListener('deviceready', () => {
      setTimeout(() => {
        if (navigator && navigator.splashscreen) {
          navigator.splashscreen.hide();
        }
      }, 0);
    }, false);
  }

  atualizaFicha(alunoId): void {
    if (this.ficha$) {
      this.ficha$.unsubscribe();
    }

    this.carregandoTreino = true;

    this.ficha$ = this.treinoService.obterFichaAtiva(alunoId).takeWhile(() => this.isAlive).filter((res: any) => {
      if (res.carregando) {
        this.carregandoTreino = true;
        return false;
      }

      return true;
    }).subscribe((res: any) => {
      this.carregandoTreino = false;
      this.possuiTreino = res.fichaAtiva ? res.fichaAtiva.hasOwnProperty('FichaId') : false;
    });
  }

  atualizarPostagens(): void {
    if (this.postagens$) {
      this.postagens$.unsubscribe();
    }

    this.obtendoPostagens = true;

    this.postagens$ = this.postagensService.getPostagens().takeWhile(() => this.isAlive).filter((res: any) => {
      if (res.carregando) {
        this.obtendoPostagens = true;
        return false;
      }

      return true;
    }).subscribe((response: any) => {
      if (response) {
        this.postagens = response;
      }

      if (!this.obtendoAulas) {
        this.organizarFeed('aulas', this.postagens);
      }

      this.desativaSplash();
      this.obtendoPostagens = false;
    }, () => {
      this.obtendoPostagens = false;
      this.desativaSplash();
    });
  }

  organizarFeed(objeto, response): void {
    this.feed = this[objeto].concat(response);
    this.feed.map(value => {
      if (value) {
        value.DataInicial = value.DataInicioPostagem || value.DataRealizacao;
     }
    });

    this.feed = this.feed.sort((a, b): any => {

      if (moment(a.DataInicial).isAfter(moment(b.DataInicial))) {
        return -1;
      }

      if (moment(a.DataInicial).isBefore(moment(b.DataInicial))) {
        return 1;
      }

      return 0;
    });
  }

  obterAssinaturaPorPessoaId(): void {
    this.perfilService.obterDataVencimentoAssinaturaPorPessoaId(this.userData.UserTenantId).subscribe((data: any) => {
      if (!data) {
        this.dataVencimentoAssinatura = null;
        this.nomeAcademia = null;

        return;
      }

      this.nomeAcademia = data.NomeFantasia;
      this.assinaturaVencida = this.assinaturaVenceu(data.DataVencimentoAssinatura);
      this.dataVencimentoAssinatura = data.DataVencimentoAssinatura ? this.obterDataHumanizada(data.DataVencimentoAssinatura) : null;
      }, (err) => {
      console.error(err);
    });
  }

  assinaturaVenceu(dataVencimentoAssinatura: string | Date): boolean {
    dataVencimentoAssinatura = moment(dataVencimentoAssinatura).format('YYYY-MM-DD');
    return moment(moment().format('YYYY-MM-DD')).isAfter(moment(dataVencimentoAssinatura));
  }

  obterDataHumanizada(data: string): any {
    const hoje = moment();
    const ontem = moment().subtract(1, 'days');
    const amanha = moment().add(1, 'days');

    const dataAssinatura = moment(data);

    if (dataAssinatura.format('DD/MM/YYYY') === hoje.format('DD/MM/YYYY')) {
      return { em: 'é', data: 'hoje'};
    }

    if (dataAssinatura.format('DD/MM/YYYY') === amanha.format('DD/MM/YYYY')) {
      return { em: 'é', data: 'amanhã'};
    }

    if (dataAssinatura.format('DD/MM/YYYY') === ontem.format('DD/MM/YYYY')) {
      return { em: 'foi', data: 'ontem' };
    }

    return { em: 'é', data: moment(dataAssinatura).format('DD/MM/YYYY') };
  }

  mostrarDataVencimento(): void {
    this.treinoService.obterAjustes().subscribe((data: any) => {
      if (data && data.ExibirDataDeVencimento) {
        if (this.userData && this.userData && this.userData.TenantId !== 'eb7689de-62b1-4e1b-98aa-86ee648bfe91') {
          this.isAcademia = true;
          this.obterAssinaturaPorPessoaId();
        }
      } else {
        this.isAcademia = false;
        this.dataVencimentoAssinatura = null;
      }
    });
  }
}
