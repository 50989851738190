import { Component, OnInit, AfterViewInit } from '@angular/core';
import { timeout, takeUntil } from 'rxjs/operators';
import { of, Subject, pipe } from 'rxjs'
import { FrequenciasService } from '../../shared/services/frequencias.service';
import { Mes, Frenquencia } from './minhas-frequencias';
import * as moment from 'moment';

@Component({
  selector: 'app-minhas-frequencias',
  templateUrl: './minhas-frequencias.component.html',
  styleUrls: ['./minhas-frequencias.component.scss'],
})
export class MinhasFrequenciasComponent implements OnInit, AfterViewInit {

  public selectedIndex = 1;
  public data: [Frenquencia];
  public mesAtual: number;
  public anoAtual: number;
  public mesSelecionado: number;
  public anoSelecionado: number;
  public descricaoMesSelecionado: string;
  public qtAnosAnterioresExibir = 2;
  public carregando = true;
  private unsubscriber = new Subject();

  constructor(private frequenciasService: FrequenciasService) { }

  public ngOnInit(): void {
    this.mesAtual = new Date().getMonth() + 1;
    this.anoAtual = new Date().getFullYear();
    this.mesSelecionado = this.mesAtual;
    this.anoSelecionado = this.anoAtual;
    this.obterFrequenciasPorUsuario(this.anoSelecionado);

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.selectedIndex = 0
    },0);
  }

  public obterDescricaoMes(number, ano?): string {
    return `${Mes[number]}/${ano ? ano : this.anoAtual}`;
  }

  public calcularMesesExibicao() {
    let mesesDoAno = 12;
    const _MesesBool = [];

    if (this.anoAtual === this.anoSelecionado) {
      mesesDoAno = this.mesAtual;
    }

    for (let numeroMes = 0; numeroMes < mesesDoAno; numeroMes++ ) {
      _MesesBool.push(this.obterDadosPorMeses(numeroMes+1).length > 0)
    }
    return _MesesBool;
  }

  public obterFrequenciasPorUsuario(ano): void {
    this.carregando = true;
    this.frequenciasService.obterFrequencias(ano).then((subscriber) => {
      subscriber.pipe(takeUntil(this.unsubscriber)).subscribe(async (data: any) => {
        this.data = data.value;
        this.carregando = false;
      });
    })
  }

  public exibirMesSelecionado(index, hasDadosNoMes): void {
    if(!hasDadosNoMes) {return}

    const numeroMes = index + 1;
    this.descricaoMesSelecionado = this.obterDescricaoMes(numeroMes);
    this.mesSelecionado = numeroMes;
    this.selectedIndex = 0;
  }

  public obterDadosPorMeses(mes: number): any[] {
    const _dadosPorMeses = [];

    for (let frequencia of this.data) {
      let mesEntrada = Number(moment(frequencia.DataEntrada).format('M'));
      let mesSaida = Number(moment(frequencia.DataSaida).format('M'));

      if (mesEntrada === mes || mesSaida === mes) {
        _dadosPorMeses.push(frequencia);
      }
    }

    return _dadosPorMeses;
  }

  public anosExibicao(qtAnosAterioresExibir) {
    const _anosExibicao = [];

    for (let anosSubtraidos = 0; anosSubtraidos < qtAnosAterioresExibir; anosSubtraidos++ ) {
      _anosExibicao.push( this.anoAtual - anosSubtraidos);
    }

    return _anosExibicao;
  }

  public selecionarAno(ano) {
    this.selectedIndex = 1;
    this.anoSelecionado = ano;

    this.obterFrequenciasPorUsuario(this.anoSelecionado);
  }

}
