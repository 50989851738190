import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EventEmitterService } from './../../../shared/services/event-emitter.service';
import { SoundsService } from './../../../shared/services/sounds.service';
import { StorageServiceTimers } from './../../../shared/services/storage-timers.service';
import { UtilsService } from './../../../shared/services/utils.service';
import { Timer } from 'easytimer.js';
@Component({
  selector: 'app-tabata',
  templateUrl: './tabata.component.html',
  styleUrls: ['./tabata.component.scss']
})
export class TabataComponent implements OnInit, OnDestroy {
  @ViewChild('alert') alert;
  addClassbyService = false;
  tempoOffSeconds = 0;
  tempoOnSeconds = 0;
  tabata = new Timer();
  tabataPrep = new Timer();
  tabataValues: any = {
    roundsDone: 0,
    roundsTotal: 8,
  };
  isPaused = false;
  tabataPrepValues: any = {};
  tempoAtual: any;
  tempoPreparacao = 10;
  pausedvalues: number;
  preparacao = true;
  form: UntypedFormGroup;
  openSidebar = false;
  finished = false;
  started = false;
  tempoOff = '0010';
  tempoOn = '0020';
  descanso = false;
  state: string;
  red = false;
  configDefault = {
    Rounds: 2,
    TempoOn: '0005',
    TempoOff: '0003',
    TempoWorkout: '0350', // soma de todos os rounds + tempos Off
    UltimoDescanso: true,
    TempoPreparacao: '0003',
  };

  constructor(
    private fb: UntypedFormBuilder,
    private storage: StorageServiceTimers,
    private utilsService: UtilsService,
    private soundsService: SoundsService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      Rounds: [8],
      TempoOn: ['0020'],
      TempoOff: ['0010'],
      UltimoDescanso: [null],
      TempoWorkout: [{value: null, disabled: true}],
      TempoPreparacao: [null],
      TelaCheia: [null],
    });

    this.tempoOffSeconds = this.utilsService.tempoToSeconds(this.tempoOff);
    this.tempoOnSeconds = this.utilsService.tempoToSeconds(this.tempoOn);
    this.form.controls.Rounds.valueChanges.subscribe((rounds) => {
      this.reset();
      this.tabataValues.roundsTotal = rounds;
    });

    this.form.controls.TempoOff.valueChanges.subscribe((tempoOff) => {
      this.reset();
      this.tempoOff = tempoOff;
      this.tempoOffSeconds = this.utilsService.tempoToSeconds(this.tempoOff);
    });

    this.form.controls.TempoOn.valueChanges.subscribe((tempoOn) => {
      this.reset();
      this.tempoOn = tempoOn;
      this.tempoOnSeconds = this.utilsService.tempoToSeconds(this.tempoOn);
    });

    this.form.controls.TelaCheia.valueChanges.subscribe((value) => this.utilsService.toggleTelaCheia(value))

    this.tabata.addEventListener('secondTenthsUpdated', () => {
      this.tabataValues.seconds = this.tabata.getTimeValues().seconds
      this.tabataValues.minutes = this.tabata.getTimeValues().minutes
      this.tabataValues.secondTenths = this.tabata.getTimeValues().secondTenths
    });

    this.tabata.addEventListener('targetAchieved', () => {
      EventEmitterService.get('background-end').emit();
      this.tabataValues.roundsDone < this.tabataValues.roundsTotal ?
        this.preparacao ? (this.start(), this.preparacao = false, this.tabataValues.roundsDone++) :
          this.descanso ? ( this.start(), this.tabataValues.roundsDone++ ) : this.countDescanso() :
      (this.reset(), this.soundsService.playDone());
    });
  }

  ngOnDestroy() {
   EventEmitterService.get('background-end').emit();
   this.soundsService.stopAllSounds();
   this.reset();
  }

  start(preparacao?: boolean) {
    preparacao ? (
      this.tabataValues.seconds = this.tempoPreparacao,
      this.tabata.start({precision: 'seconds', countdown: true, startValues: { seconds: this.tempoPreparacao}, target: {secondTenths: 0, seconds: 0, minutes: 0}}),
      this.red = true,
      this.started = true
    ) : (
      this.tabataValues.seconds = Number(this.tempoOn.toString().substr(2, 3)),
      this.tabataValues.minutes = Number(this.tempoOn.toString().substr(0, 2)),
      this.tabata.start({precision: 'secondTenths', countdown: true, startValues: { seconds: this.tempoOnSeconds}, target: {secondTenths: 0, seconds: 0, minutes: 0}}),
      // adiciona um pequeno delay na animação do fundo (para evitar piscar verde quando vai para o descanso)
      EventEmitterService.get('background-start').emit(this.tempoOnSeconds),
      this.soundsService.playStart(),
      this.descanso = false,
      this.red = false
    );
  }

  countDescanso() {
    this.tabataValues.seconds = Number(this.tempoOff.toString().substr(2, 3)),
    this.tabataValues.minutes = Number(this.tempoOff.toString().substr(0, 2)),
    this.tabata.start({precision: 'seconds', countdown: true, startValues: { seconds: this.tempoOffSeconds}, target: {secondTenths: 0, seconds: 0, minutes: 0}});
    this.soundsService.playRest();
    this.descanso = true;
    this.red = true;
  }

  pause() {
    if (!this.preparacao) {
      EventEmitterService.get('background-pause').emit();
    }
    this.isPaused = true;
    this.tabata.pause();
    this.pausedvalues = this.tabata.getTotalTimeValues().secondTenths;
  }

  resume() {
    if (!this.descanso && !this.preparacao) {
     EventEmitterService.get('background-resume').emit(this.tempoOnSeconds)
    }
    this.isPaused = false;
    this.tabata.start({precision: 'secondTenths', countdown: true, startValues: { secondTenths: this.tabataValues.secondTenths}, target: {secondTenths: 0, seconds: 0, minutes: 0}});
  }

  reset() {
    this.tabata.reset();
    this.tabata.pause();
    this.tabata.stop();
    this.tabataValues.minutes = 0;
    this.tabataValues.seconds = 0;
    this.tabataValues.secondTenths = 0;
    this.tabataValues.roundsDone = 0;
    this.started = false;
    this.preparacao = true;
    this.descanso = false;
    this.red = false;
    this.soundsService.stopAllSounds();
    EventEmitterService.get('background-end').emit();
  }

  salvarConfig({value, valid}) {
    this.reset();
    this.addClassbyService = !this.addClassbyService;
  }

  abrirSideBar() {
    this.addClassbyService = !this.addClassbyService;
  }

  isMobileDevice() {
    const isMobile = window.orientation > -1;
    return isMobile;
  }
}
