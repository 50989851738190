<div class="card-aula-component">
  <!-- Cards das aulas -->
  <div class="grid-sizer"></div>

  <mat-card>
    <mat-card-content (click)="openCard(aula)">
      <mat-card-title>{{aula.Titulo}}</mat-card-title>
      <div class="event">
        <span class="date">
          <mat-icon>event</mat-icon>
          <span class="desc">Dia</span>
          <strong>{{aula.DataRealizacao | date: 'dd/MM/yyyy'}}</strong>

          <span class="hora">
            <mat-icon>alarm</mat-icon>
            {{aula.DataRealizacao | date: 'HH:mm'}}h
          </span>
        </span>

        <div class="event-info" [class.hidden]="resumido" id="aula-{{aula.AulaId}}">
          <span class="date">
            <mat-icon>event</mat-icon>
            <span class="desc">Reservas</span>
            <strong>{{aula.DataReservarAte | date: 'dd/MM/yyyy'}}</strong>
            <span class="hora">
              <mat-icon>alarm</mat-icon>
              {{aula.DataReservarAte | date: 'HH:mm'}}h
            </span>
          </span>

          <span *ngIf='aula.Local' class="campo">
            <mat-icon>place</mat-icon>
            <p class="campo-desc">{{aula.Local}}</p>
          </span>

          <span *ngIf='aula.Vagas' class="campo">
           <mat-icon>featured_play_list</mat-icon>
            <p class="campo-desc"><b>Vagas: </b>{{aula.Vagas}}</p>
          </span>

          <span *ngIf='aula.ImagemUrl'>
            <img *ngIf="aula.ImagemUrl" mat-card-image [src]="aula.ImagemUrl" alt="imagem-postagem">
          </span>

          <span *ngIf='aula.Texto'>
            <p>{{aula.Texto}}</p>
          </span>
        </div>

      </div>
    </mat-card-content>

    <mat-card-actions class="aula-acoes">
      <button class="btn {{aula.button.class}}" mat-button [disabled]="aula.button.class == 'disabled'" (click)="reservarAula(aula)">
        {{aula.button.text}}
      </button>
      <a *ngIf="(aula.SituacaoReserva == 'C' || aula.SituacaoReserva == 'E') && aula.button.text != 'AULA FINALIZADA'" class="cancelar"
        (click)="desReservarAula(aula)">
        Não vou mais
      </a>
    </mat-card-actions>
  </mat-card>

  <div class="grid-sizer"></div>
</div>
