import { Component, OnInit, Sanitizer } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'app-visualizar-avaliacao',
  templateUrl: './visualizar-avaliacao.component.html',
  styleUrls: ['./visualizar-avaliacao.component.scss'],
})
export class VisualizarAvaliacaoComponent implements OnInit {
  url: string;
  urlSanitized: any;

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      if (params && params['id']) {
        this.url = `https://app.afig.actuar.com/#/avaliacao/relatorio/${params['id']}`;
      }
    })
  }

  ngOnInit() {
    this.urlSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

}
