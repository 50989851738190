export enum Context {
  assinatura,
  venda ,
  servico
}

export interface IContaReceber {
  PessoaId: string;
  ProvenienteId: string;
  DataVencimento: string;
  VrOriginal: number;
  DataUltimoRecebimento: null;
  VrMultaAtual: number;
  VrJuroAtual: number;
  VrAberto: number;
  VrAbertoAtual: number;
  NomeCliente: string;
  DescricaoProveniente: string;
  TipoProveniente: string;
  DescontoId: null;
  VrTotalRecebido: number;
  Situacao: string;
  TipoCadastro: string;
  RecorrenciaPagueLaAtiva: null;
  Cpf: string;
  Desconto: number;
  DescontoPer: number;
  VrRecebendo: number;
}
