import { Injectable } from '@angular/core';

@Injectable()
export class TranslateAdapter {
  private readonly objectModel: Object;
  constructor(objectModel: Object) {
    this.objectModel = objectModel;
  }

  public translate(originObj: Object, removeNullValues?: boolean): Object {
    if (!originObj || !this.objectModel) {
      throw new Error('Origin object or model object is null');
    }

    const destinyObject = {};
    Object.entries(originObj).forEach(([key, value]) => {
      Object.entries(this.objectModel).forEach(([keyModel, valueModel]) => {
        if (removeNullValues && value === null) {
          return;
        }

        if (Array.isArray(value)) {
          const modelObj: [string, any] = Object.entries(valueModel)[0];
          const auxArr = [];

          value.forEach((item) => {
            if (typeof item === 'object' && item !== null) {
              auxArr.push(new TranslateAdapter(modelObj[1]).translate(item, removeNullValues));
              return;
            }
            auxArr.push(item);
          });

          if (key === keyModel) {
            destinyObject[modelObj[0]] = auxArr;
            return;
          }

          if (key === modelObj[0]) {
            destinyObject[keyModel] = auxArr;
            return;
          }
          return;
        }

        if (typeof originObj[key] === 'object' && originObj[key] !== null) {
          Object.keys(this.objectModel[keyModel]).forEach((objectKey) => {
            if (key === objectKey) {
              destinyObject[keyModel] = new TranslateAdapter(valueModel[key]).translate(originObj[key], removeNullValues);
              return;
            }

            if (key === keyModel && typeof valueModel[objectKey] === 'object') {
              destinyObject[objectKey] = new TranslateAdapter(valueModel[objectKey]).translate(originObj[key], removeNullValues);
              return;
            }
          });
        } 

        if (key === keyModel && typeof valueModel !== 'object') {
          destinyObject[valueModel] = value;
        }
        if (key === valueModel) {
          destinyObject[keyModel] = value;
        }
      });
    });

    return destinyObject;
  }
}