import { Injectable } from '@angular/core';

@Injectable()
export class TransferidorDeDadosService {
  dados: any[] = [];

  constructor() { }

  adicionarDado(id: string, dados: any) {
    this.dados.push({ id, dados });
  }

  obterDadoPorId(id: string): any {
    const dadoRetornar = this.dados.find((dado: any) => {
      return dado.id === id;
    });

    if (typeof dadoRetornar !== 'undefined') {
      return dadoRetornar.dados;
    }

    return null;
  }

  removerDadoPorId(id: string): void {
    this.dados = this.dados.filter((dado: any) => dado.id !== id);
  }
}
