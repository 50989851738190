export enum Mes {
  Janeiro = 1,
  Fevereiro,
  Março,
  Abril,
  Maio,
  Junho,
  Julho,
  Agosto,
  Setembro,
  Outubro,
  Novembro,
  Dezembro
}

export interface Frenquencia {
  AcessoId?: string;
  ConsomePasse?: boolean;
  DataEntrada?: string;
  DataSaida?: string;
  DescricaoAssinatura?: string;
  HoraEntrada?: number;
  HoraSaida?: number;
  NomeCliente?: string;
  PessoaId?: string;
  TipoCadastro?: string;
}
