import { LocalStorageService } from './localstorage.service';
import { SqliteService } from './sqlite.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

declare var window: any;

@Injectable()
export class StorageService {
  sqlite: any = null;

  constructor(
    private sqliteService: SqliteService,
    private localStorageService: LocalStorageService,
  ) {
    document.addEventListener('deviceready', function() {
      this.sqlite = !!window.sqlitePlugin;
    });
  }

  get(key) {
    return this.sqlite
      ? this.sqliteService.get(key)
      : this.localStorageService.get(key);
  }

  set(key, obj) {
    return this.sqlite
      ? this.sqliteService.set(key, obj)
      : this.localStorageService.set(key, obj);
  }

  remove(key) {
    return this.sqlite
      ? this.sqliteService.remove(key)
      : this.localStorageService.remove(key);
  }

  clear() {
    return this.sqlite
      ? this.sqliteService.clear()
      : this.localStorageService.clear();
  }
}
