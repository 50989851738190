import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DevicesService } from './../../../shared/services/devices.service';
import {LoginService} from "../../../shared/services/login.service";
import {SweetalertService} from "../../../shared/services/sweetalert.service";
import {Capacitor} from "@capacitor/core";

declare var window: any;

@Component({
  selector: 'app-share-treino',
  templateUrl: './share-treino.component.html',
  styleUrls: ['./share-treino.component.scss']
})
export class ShareTreinoComponent implements OnInit {
  @Output() fecharPopUp = new EventEmitter();
  isMobile;

  constructor(
    private deviceService: DevicesService,
    private loginService: LoginService,
    private sweetAlert: SweetalertService
  ) { }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
  }

  share() {
    if (Capacitor.getPlatform() === 'web') {
      this.sweetAlert.alert('', 'Baixe o app Trei.no para utilizar este recurso.').subscribe();
      return;
    }

    const options = {
      message: 'Terminei o meu treino de hoje, e você?', // not supported on some apps (Facebook, Instagram)
      subject: 'Trei.no', // fi. for email
      url: 'https://trei.no',
      chooserTitle: 'Compartilhar' // Android only, you can override the default share sheet title
    }

    const onSuccess = function (result) {
      // console.log('Share completed? ' + result.completed);
      // On Android apps mostly return false even while it's true

      // console.log('Shared to app: ' + result.app);
      // On Android result.app is currently empty. On iOS it's empty when sharing is cancelled (result.completed=false)
    }

    const onError = function (msg) {
      // console.log('Sharing failed with message: ' + msg);
    }

    try {
      window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
    } catch (e) {
      console.log(e);
    }
  }

  fechar(e) {
    this.fecharPopUp.emit(e);
  }
}
