<div class="alert-version" [style.display]="isOpened ? 'flex' : 'none'" >
    <div class="flex-top">
      <div class="flex">
        <div class="flex">
          <img src="assets/imagens/logo.png"/>
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88"><defs><style>.cls-1{fill-rule:evenodd;}</style></defs><title>download-file-square</title><path class="cls-1" d="M25.2,0H97.68a25.27,25.27,0,0,1,25.2,25.2V97.68a25.27,25.27,0,0,1-25.2,25.2H25.2A25.27,25.27,0,0,1,0,97.68V25.2A25.27,25.27,0,0,1,25.2,0ZM72.92,49.32a3.83,3.83,0,0,1,5.37,5.46L63.75,69.14a3.84,3.84,0,0,1-5.37,0L44.05,55a3.83,3.83,0,1,1,5.36-5.46l7.89,7.82.08-27.53a3.83,3.83,0,0,1,7.66.06L65,57.19l8-7.87ZM31,84.48l.05-15a3.83,3.83,0,0,1,7.66.07l0,10.91q22.82,0,45.61,0l0-11a3.83,3.83,0,0,1,7.66.07l-.05,14.91h0A3.83,3.83,0,0,1,88,88.13q-26.66,0-53.28,0A3.83,3.83,0,0,1,31,84.48Z"/></svg>
        </div>
          <h3>
            Atualização Importante!
          </h3>
          <p class="flex paragraph">
            Uma nova versão, ainda mais segura e com novos recursos, está disponível. Baixe agora e atualize o seu aplicativo.
          </p>
      </div>
    </div>
    <div class="button-flex">
      <a *ngIf="!forceUserUpdate" (click)="close()"> Atualizar depois </a>
      <div class="button" (click)="navigateToAppStore()">
        ATUALIZAR VERSÃO
      </div>
    </div>
</div>
