<div id="carga-chooser" class="choose-cargas">
  <header class="carga-capa">
    <div>
      <p>Editar carga</p>
    </div>
  </header>

  <section class="content">
    <form [formGroup]="form" #formDirective="ngForm" (ngSubmit)="salvar(form);" class="al-center">
      <mat-form-field id="cargaSlider">
        <input #fieldName class="al-center" matInput type="number" formControlName="Carga" maxlength="4">
        <mat-error *ngIf="form.get('Carga').getError('required')">Campo Obrigatório</mat-error>
      </mat-form-field>
      <div>
        <label>Cargas</label>
      </div>
      <div class="mt-50">
        <button style="margin-right: 50px;" mat-button type="button" class="btn btn-default btn-cargas-edit" (click)="fecharEditar()">Cancelar</button>
        <button mat-button type="submit" class="btn btn-primary btn-cargas-edit" [ladda]="salvando">Salvar</button>
      </div>
    </form>
  </section>
</div>


