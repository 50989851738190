import { Injectable } from '@angular/core';

@Injectable()
export class StorageServiceTimers {
  constructor() {}

  set(key, value) {
    if (!this.verifyStorage()) {
      return false;
    }

    return localStorage.setItem('treinoTimers/' + key, JSON.stringify(value));
  }

  get(key) {
    if (!this.verifyStorage()) {
      return false;
    }

    return JSON.parse(localStorage.getItem('treinoTimers/' + key));
  }

  verifyStorage() {
    return !!localStorage;
  }
}
