<section class="config">
  <h6 class="page-title p-t-20">
    <i class="material-icons">&#xE8B8;</i>
    Configurações
  </h6>

  <ul class="demo-list-control">
    <li class="container" *ngIf="isMobile">
      <span class="texto">
        <span class="title">Vibração no intervalo</span>
        <span class="descricao">Emite vibrações ao finalizar o tempo de descanso ou intervalo dos treinos</span>
      </span>
      <span class="toggle">
        <mat-slide-toggle [color]="'primary'" [checked]="config.vibraintervalo"
          (change)="toggleConfig('vibraintervalo', $event)">
        </mat-slide-toggle>
      </span>
    </li>

    <li class="container" *ngIf="isMobile">
      <span class="texto">
        <span class="title">Notificação no intervalo</span>
        <span class="descricao">Emite uma notificação ao finalizar o tempo de descanso ou intervalo em segundo plano</span>
      </span>
      <span class="toggle">
        <mat-slide-toggle [color]="'primary'" [checked]="config.notificaintervalo"
          (change)="toggleConfig('notificaintervalo', $event)">
        </mat-slide-toggle>
      </span>
    </li>

    <li class="container" (click)="irParaVisualizarPoliticaDePrivacidade()">
      <span class="texto">
        <span class="title">Política de privacidade</span>
        <span class="descricao">Visualizar a política de privacidade e excluir conta</span>
      </span>
    </li>

    <!--
      <li class="container">
        <span class="texto">
          <span class="title">Conteúdo da academia</span>
          <span class="descricao">Receber e-mails, notificações e SMS da academia</span>
        </span>
        <span class="toggle">
          <mat-slide-toggle [color]="'primary'"></mat-slide-toggle>
        </span>
      </li>
    -->

    <!--
      <li class="container">
        <span class="texto">
          <span class="title">Conteúdo do trei.no</span>
          <span class="descricao">Receber e-mails, notificações e SMS da equipe trei.no</span>
        </span>
        <span class="toggle">
          <mat-slide-toggle [color]="'primary'"></mat-slide-toggle>
        </span>
      </li>
    -->
  </ul>
</section>
