import { Component, OnInit, Input } from '@angular/core';



@Component({
  selector: 'app-buttonsidebar',
  templateUrl: './buttonsidebar.component.html',
  styleUrls: ['./buttonsidebar.component.scss']
})
export class ButtonSidebarComponent implements OnInit {
@Input('value') value: boolean;

  constructor() { }

  ngOnInit() {
  }
}
