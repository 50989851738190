import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-401',
  templateUrl: './401.component.html',
  styleUrls: ['./401.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class _401Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
