import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';

import { API } from '../../../environments/environment';

import { StorageService } from './storage.service';

import { SituacaoReservaAulaEnum } from '../enums/SituacaoReservaAula.enum';

import * as moment from 'moment';

@Injectable()
export class ReservasService {
  reservasStream: ReplaySubject<any> = new ReplaySubject(1);

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) { }

  // checkin da reserva
  fazerCheckin(reservaId: string): Observable<Object> {
    return this.http
      .put(`${API.treino}/Reservas/Chekin?reservaId=${reservaId}`, null, {
        withCredentials: true
      });
  }

  // cancelar reserva
  cancelar(reservaId: string): Observable<Object> {
    return this.http
      .put(API.treino + '/Reservas/CancelarReserva?ReservaId=' + reservaId + '&Situacao=1', null, {
        withCredentials: true
      });
  }

  remover(id: string): Observable<Object> {
    return this.http
      .delete(API.treino + '/Reservas/Remover?id=' + id, {
        withCredentials: true
      });
  }

  adicionarReserva(pessoaId: string, turmaId: string, tipoCadastro: string): Observable<Object> {
    const dados = {
      TurmaId: turmaId,
      PessoaId: pessoaId,
      TipoCadastro: tipoCadastro,
      Situacao: SituacaoReservaAulaEnum.Reservado,
    };

    return this.http.post(`${API.treino}/Reservas/Adicionar`, dados, {
      withCredentials: true
    });
  }

  alterarSituacaoReserva(reservaId: string, situacao: number): Observable<Object> {
    return this.http.put(API.treino + '/Reservas/AlterarSituacao?ReservaId=' + reservaId + '&Situacao=' + situacao, null, {
      withCredentials: true
    });
  }

  agendarCReserva(id: any): Observable<Object> {
    return this.http
      .put(API.treino + '/Reservas/AlterarSituacao?ReservaId=' + id + '&Situacao=0', null, {
        withCredentials: true
      });
  }

  obterTurmas(dataRealizacao): Observable<any> {
    if (dataRealizacao) {
      const data = moment(dataRealizacao).format('YYYY-MM-DD');

      return this.http
        .get(`${API.odata}/Turmas?$filter=DataRealizacao ge ${data} and DataRealizacao le ${data}`, {
          withCredentials: true
        });
    }

    const hoje = moment().format('YYYY-MM-DD');

    return this.http
      .get(`${API.odata}/Turmas?$filter=DataRealizacao ge ${hoje} and DataRealizacao le ${hoje}`, {
        withCredentials: true
      });
  }

  obterReservas(PessoaId: string): Observable<any> {
    return this.http
      .get(API.odata + '/Reservas?$filter=PessoaId eq ' + PessoaId, {
        withCredentials: true
      });
  }

  obterReservasPorPessoaId(pessoaId: string): Observable<any> {
    return this.http
      .get(API.treino + '/Reservas/ObterReservasTurmasPorPessoaId?pessoaId=' + pessoaId, {
        withCredentials: true,
      });
  }

  obterModalidades(): Observable<any> {
    return this.http
      .get(`${API.odata}/ModalidadesAulas`, {
        withCredentials: true
      });
  }

  obterTurmasDetalhadasPorId(turmaId: string): Observable<any> {
    return this.http
      .get(`${API.treino}/Turmas/ObterTurmaDetalhadaPorId?id=${turmaId}`, {
        withCredentials: true
      });
  }

  obterParticipantesTurmaPorId(turmaId: string): Observable<any> {
    return this.http
      .get(`${API.treino}/Turmas/ObterPorId?id=${turmaId}`, {
        withCredentials: true
      });
  }
}
