import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import SwiperCore, { Pagination } from 'swiper';


// install Swiper modules
SwiperCore.use([Pagination]);
@Component({
  selector: 'app-exercicio-instrucao',
  templateUrl: './exercicio-instrucao.component.html',
  styleUrls: ['./exercicio-instrucao.component.scss']
})
export class ExercicioInstrucaoComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() instrucao;
  @Output() closeModal = new EventEmitter<any>();
  public open: boolean = false;
  public youtubeEmbedUrl;
  public videoUrl: string = '';
  public picturesUrl = [];
  public animatedImages;

  constructor(
    private domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {

  }

  ngOnInit() {

    this.mostrarInstrucao();
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.fechar();
  }

  public async mostrarInstrucao() {
    if (this.instrucao && this.instrucao.VideoExternalUrl) {
      const videoId = this.getYoutubeVideoIdFromUrl(this.instrucao.VideoExternalUrl);
      this.youtubeEmbedUrl = await this.getYoutubeEmbedUrl(videoId)
    }

    for (const picture in this.instrucao) {
      if (picture.includes('Picture') && this.instrucao[picture] ) {
        this.picturesUrl.push(this.instrucao[picture])
        let i = 0;
        setInterval(() => {
          i++
          if (i === (this.picturesUrl.length)) i = 0
          this.animatedImages = this.picturesUrl[i]?.Url;
        }, 300)
      }
    }
    this.open = true;
  }

  public fechar(): void {
    this.open = false;
    this.instrucao = null;
    this.youtubeEmbedUrl = null;
    this.picturesUrl = [];
    this.animatedImages = null;
    this.closeModal.emit(this.instrucao)
  }

  getYoutubeVideoIdFromUrl(url: string): string | null {
    try {
      const objUrl = new URL(url);
      if (/youtube\.com$/.test(objUrl.host)) {
        let videoId: string;
  
        if (objUrl.searchParams.get('v')) {
          return videoId = objUrl.searchParams.get('v');
        }
  
        if (objUrl.pathname.includes('shorts')) {
          const string = objUrl.pathname.split('/shorts/');
          return videoId = string[1];
        }
  
        if (videoId) {
          return videoId;
        }
      }
    } catch {}
    return null;
  }

  private getYoutubeEmbedUrl(youtubeVideoId): SafeResourceUrl {
    return <string> this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + youtubeVideoId);
  }
}
