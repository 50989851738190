import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

// Pra você que está vendo essa Pipe, vai perguntar o angular
// porque eles nao fazem um pipe de date que respeite a timezone
// que eu quero, sem enfiar horas quando nao deve, é workaround
// mesmo e vai ficar assim
@Pipe({
  name: 'customDate'
})

export class CustomDate implements PipeTransform {
  transform(value: string, format: string): string {

    moment.locale('pt');

    const valueUtc = new Date(value.replace(/\s+/g, 'T').concat('.000+00:00')).getTime();

    const momentDateUtc = moment(valueUtc).utcOffset(0);
    const momentDate = moment(new Date(value));

    if (momentDateUtc.isValid()) {

      return momentDateUtc.format(format);

    } else if (momentDate.isValid()) {

      return momentDate.format(format);

    } else {
      return value;
    }
  }
}
