import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { AvaliacoesService } from '../../shared/services/avaliacoes-service.service';
import { LoginService } from '../../shared/services/login.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-avaliacoes-fisicas',
  templateUrl: './avaliacoes-fisicas.component.html',
  styleUrls: ['./avaliacoes-fisicas.component.scss'],
})
export class AvaliacoesFisicasComponent implements OnInit, OnDestroy {
  pessoaId: string;
  carregando = true;
  QtAvaliacoes = 0;
  dataAfig: any;

  unsubscriber = new Subject();
  constructor(
              private router: Router,
              private route: ActivatedRoute,
              private avaliacoesService: AvaliacoesService,
              private LoginService: LoginService
              ) {
    this.pessoaId = this.LoginService.getUserDataLocal().UserTenantId;
  }

  ngOnInit() {
    this.carregando = true;
    this.QtAvaliacoes = 0;
    this.avaliacoesService.obterAvaliacoesResumidasPorPessoaId(this.pessoaId).pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
      if(data) {
        this.dataAfig = data;
        if(data.Avaliacoes.length > 0 ){
          data.Avaliacoes.forEach(() => this.QtAvaliacoes++)
        }
      }
      this.carregando = false;
    }, (error) => {
      console.error(error);
      this.carregando = false;
    });
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  visualizarAvaliacao(item) {
    this.router.navigate(['/painel/avaliacoes-fisicas/avaliacao', item.AvaliacaoId], { relativeTo: this.route}).then();
  }

}
