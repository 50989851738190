import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { URLS } from '../../../environments/environment';
import { Version, ResolvedVersion } from '../interfaces/app-version.interface';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';


@Injectable({
  providedIn: 'root',
})
export class AlertVersionService {

  constructor(
    private http: HttpClient,
  ) {
  }

  matchPlatform(compare: string): string {
    if (compare === 'ios') {
      return 'iosVersion';
    }
    if (compare === 'android') {
      return 'androidVersion';
    }
    return 'webVersion';
  }

  extractSemanticVersion(version: string) {
    const matcher = version.match(/^((([0-9]+)\.([0-9]+)\.([0-9]+)(?:-([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?)(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?)$/);
    return {
      major: +matcher[3],
      patch: +matcher[4],
      minor: +matcher[5]
    };
  }

  public verifyVersion(platform: string): Observable<ResolvedVersion> {
    return new Observable( (observer: Observer<ResolvedVersion>) => {
      if (Capacitor.isNativePlatform()) {
        App.getInfo().then(({version}) => {
          this.getVersion().subscribe((remote: Version) => {
            const remoteVersion = this.extractSemanticVersion(remote[this.matchPlatform(platform)]);
            const localVersion = this.extractSemanticVersion(version);
            observer.next(
              {
                isDeprecatedVersion:
                  remoteVersion.major > localVersion.major ||
                  remoteVersion.patch > localVersion.patch ||
                  remoteVersion.minor > localVersion.minor,
                forceUpdate: remote.forceUpdate
              });
            observer.complete();
          }, (error) => {
            console.error(error);
          });
        });
      }
      return observer.complete();
    });
  }

  private getVersion(): Observable<Version> {
    return this.http.get<Version>(`${URLS.treino}/assets/app-version.json`, {
     withCredentials: true
    });
  }
}
