import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { LoginService } from '../../shared/services/login.service';
import { SweetalertService } from '../../shared/services/sweetalert.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';

@Component({
  selector: 'app-confirmar-email',
  templateUrl: './confirmar-telefone.component.html',
  styleUrls: ['./confirmar-telefone.component.scss']
})
export class ConfirmarTelefoneComponent implements OnInit {
  sucesso = false;
  confirmar = true;
  verificar = false;
  carregando = false;
  reenviando = false;
  international = false;
  erro: any;
  sid = null;
  pessoaId = null;

  form: UntypedFormGroup;
  formVerificacao: UntypedFormGroup;

  mask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private sweetAlertService: SweetalertService,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
    this.montarFormulario();
    this.montarFormularioVerificacao();
    
    this.obterParametros();
  }

  montarFormulario(): void {
    this.form = this.fb.group({
      Numero: [null, [Validators.required]],
      ContaId: [null]
    });
  }

  montarFormularioVerificacao(): void {
    this.formVerificacao = this.fb.group({
      Numero: [ null ],
      ContaId: [ null ],
      Token: [ null, [ Validators.required, Validators.minLength(6), Validators.maxLength(6) ] ],
    });
  }

  obterParametros(): void {
    this.route.params.subscribe(values => {
      if (values.pessoaId && values.sid) {
        this.sid = values.sid;
        this.pessoaId = values.pessoaId;
        this.form.controls.ContaId.setValue(values.pessoaId);
        this.formVerificacao.controls.ContaId.setValue(values.pessoaId);
      } else {
        this.form.controls.ContaId.setValue(values.contaId);
        this.formVerificacao.controls.ContaId.setValue(values.contaId);
      }
    });
  }

  enviarNovamente(): void {
    if (this.reenviando) { return; }

    this.reenviando = true;
    this.sucesso = false;

    const dados = {
      Origin: 'Treino',
      UserId: this.form.controls['ContaId'].value,
    };

    this.loginService.reenviarCodigoCelular(dados).subscribe(() => {
      this.sucesso = true;
      this.reenviando = false;
    }, (error) => {
      this.reenviando = false;
      this.erro = this.errorHandlerService.getFirstValidationMessage(error);
    });
  }

  submit({ valid, value }): void {
    if (!valid) { return; }

    this.carregando = true;

    const dados = {
      Origin: 'Treino',
      UserId: value.ContaId,
      Numero: (this.international ? '' : '+55') + value.Numero.replace(/\_|\(|\)|\-| /g, ''),
    };

    this.loginService.adicionarNumero(dados).subscribe(() => {
      this.verificar = true;
      this.confirmar = false;
      this.carregando = false;
      this.formVerificacao.controls.Numero.setValue(value.Numero);
    }, error => {
      this.carregando = false;
      this.erro = this.errorHandlerService.getFirstValidationMessage(error);
    });
  }

  submitVerificacao({ valid, value }): void {
    if (!valid) { return; }

    const dados = {
      Token: value.Token,
      UserId: value.ContaId,
      Numero: (this.international ? '' : '+55') + value.Numero.replace(/\_|\(|\)|\-| /g, ''),
    };

    this.carregando = true;

    this.loginService.verificarNumero(dados).subscribe(() => {
      this.sweetAlertService.success('Telefone confirmado com sucesso').subscribe(() => {
        if (this.pessoaId && this.sid) {
          this.irParaAcessoRapido(this.pessoaId, this.sid);
        } else {
          this.irParaLogin();
        }

        this.carregando = false;
      });
    }, (error: any) => {
      console.error(error);
      this.carregando = false;
      this.erro = this.errorHandlerService.getFirstValidationMessage(error);
    });
  }

  irParaAcessoRapido(pessoaId: string, sid: string): void {
    this.router.navigate(['/acessorapido/' + pessoaId + '/' + sid]).then();
  }

  irParaLogin(): void {
    this.router.navigate(['/login']).then();
  }
}
