import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-404',
  templateUrl: './404.component.html',
  styleUrls: ['./404.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class _404Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
