export const pagamentosDictionary = {
    "AccountsReceivableId": "ContaReceberId",
    "ReceivementId": "Recebimentoid",
    "ReceiptId": "ReciboId",
    "AddedOn": "DataCadastro",
    "DueOn": "DataVencimento",
    "SourceDescription": "DescricaoProveniente",
    "IsRefunded": "Estornado",
    "PersonId": "PessoaId",
    "IsActive": "Situacao",
    "RegistrationType": "TipoCadastro",
    "SourceType": "TipoProveniente",
    "AmountDiscount": "VrDesconto",
    "AmountReceived": "VrRecebido",
    "AmountReceivedCard": "VrRecebidoCartao",
    "AmountReceivedCheck": "VrRecebidoCheque",
    "AmountReceivedCredit": "VrRecebidoCredito",
    "AmountReceivedCash": "VrRecebidoDinheiro",
    "AmountReceivedPix": "VrRecebidoPix",
    "StarOn": "PeriodoInicio",
    "EndOn": "PeriodoFinal",
    "PersonSubscriptionId": "AssinaturaPessoaId",
    "SaleId": "VendaProdutoId",
    "PersonServiceId": "ServicoPessoaId",
    "IsActiveRecurrencePagueLa": "RecorrenciaPagueLaAtiva",
    "AmountOpen": "VrAberto",
    "AmountOpenCurrent": "VrAbertoAtual",
    "AmountInterestCurrent": "VrJuroAtual",
    "AmountFineCurrent": "VrMultaAtual",
    "AmountOriginal": "VrOriginal",
    "AmountTotalReceived": "VrTotalRecebido",
    "SourceId": "ProvenienteId",
}

export enum ETipoCadastroPessoa {
    'C' = 1,
    'U' = 4,
    'V' = 5,
}

export enum ETipoProveniente {
    'ASSINATURA',
    'VENDA',
    'SERVICO'
}