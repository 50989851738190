import { SweetalertService } from './../../../shared/services/sweetalert.service';
import { LoginService } from './../../../shared/services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Md5 } from 'ts-md5/dist/md5';

class PasswordValidation {
  static MatchPassword(AC: AbstractControl) {
    const password = AC.get('NewPassword').value; // to get value in input tag
    const confirmPassword = AC.get('ConfirmPassword').value; // to get value in input tag
    if (password !== confirmPassword) {
      AC.get('ConfirmPassword').setErrors({
        MatchPassword: true
      });
    } else {
      return null;
    }
  }
}

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.scss']
})
export class AlterarSenhaComponent implements OnInit, OnDestroy {
  salvando: boolean;
  form: UntypedFormGroup;
  isAlive = true;
  usuario: any;

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private loginService: LoginService,
    private sweetAlertService: SweetalertService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      OldPassword: [null, Validators.required],
      NewPassword: [null, Validators.required],
      ConfirmPassword: [null, Validators.required]
    }, {
      validator: PasswordValidation.MatchPassword
    });

    this.loginService
      .getSessionData()
      .takeWhile(() => this.isAlive)
      .subscribe(user => {
        this.usuario = user;
      });
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  hasError(key): any {
    return this.form.controls[key].errors;
  }

  salvar({ value, valid }) {
    if (!valid) {
      return;
    }

    this.salvando = true;

    const dadosSenha = {
      Email: this.usuario.Email,
      OldPassword: Md5.hashStr(this.usuario.Email + value.OldPassword),
      NewPassword: Md5.hashStr(this.usuario.Email + value.NewPassword)
    };

    this.loginService
      .modificarSenha(dadosSenha)
      .takeWhile(() => this.isAlive)
      .subscribe((response) => {
        this.sweetAlertService.success('Senha alterada com sucesso').subscribe();
        this.form.reset();
        this.salvando = false;
        this.dialog.closeAll();
      }, error => {
        this.salvando = false;

        try {
          error = JSON.parse(error.error);
        } catch (e) { }

        if (!error) {
          this.sweetAlertService.success('Senha alterada com sucesso').subscribe();
          return;
        }

        const errors = {
          'Incorrect password.': 'Senha atual incorreta',
          'The New password must be at least 6 characters long.': 'A nova senha deve ter 6 ou mais caracteres',
          'The new password and confirmation password do not match.': 'As senhas não são iguais'
        };
        console.log(error);
        for (const err in error.ModelState) {
          if (error.ModelState[err]) {
            this.sweetAlertService.error(errors[error.ModelState[err]] || 'Ocorreu um erro').subscribe();
            return;
          }
        }
        if (error?.error?.ValidationResult?.Errors[0]?.ErrorMessage) {
          this.sweetAlertService.error(error?.error?.ValidationResult?.Errors[0]?.ErrorMessage).subscribe();
          return;
        }
        this.sweetAlertService.error('Ocorreu um erro').subscribe();
        this.form.controls['ConfirmPassword'].setValue(null);
      });
  }
}

