import { Component, OnDestroy, OnInit } from '@angular/core';

import { DevicesService } from './../../shared/services/devices.service';
import { TreinoService } from './../../shared/services/treino.service';
import { LicencaTreino } from './../core.interfaces';
import {ApenasNumerosPipe} from "../../shared/pipes/apenas-numeros.pipe";
import {Capacitor} from "@capacitor/core";
import {Browser} from "@capacitor/browser";

declare var cordova: any;

@Component({
  selector: 'app-academia',
  templateUrl: './academia.component.html',
  styleUrls: ['./academia.component.scss']
})
export class AcademiaComponent implements OnInit, OnDestroy {
  licencaTreino: LicencaTreino;
  error: boolean;
  loading: boolean;
  isMobile: any;
  isAlive = true;

  constructor(
    private treinoService: TreinoService,
    private devicesService: DevicesService,
    private device: DevicesService,
  ) { }

  ngOnInit() {
    this.isMobile = this.devicesService.isMobile();
    this.loading = true;

    this.treinoService
      .obterLicencaAcademia()
      .takeWhile(() => this.isAlive)
      .filter(response => response)
      .subscribe(response => {
        this.licencaTreino = response;
        this.prepararInfo();
        this.loading = false;
      }, error => {
        this.loading = false;
        this.error = true;
        console.log(error);
      });
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  // remover barra inicial dos links
  prepararInfo() {
    this.removerBarra('UrlFacebook');
    this.removerBarra('UrlInstragram');
    this.removerBarra('UrlTwitter');
    this.removerBarra('UrlYouTube');
  }

  removerBarra(key) {
    if (this.licencaTreino[key] && this.licencaTreino[key][0] === '/') {
      this.licencaTreino[key] = this.licencaTreino[key].substr(1);
    }
  }

  async goTo(link) {
    if (Capacitor.getPlatform() === 'ios' || 'android') {
      await Browser.open({url: link});
    } else {
      window.open(link, '_blank');
    }
  }

  async openWhatsapp(telefone: string) {
    const numero = '55' + new ApenasNumerosPipe().transform(telefone);
    // window.open('https://trei.no/whatsapp.html?phone=' + numero, '_system', 'location=no');
    await this.goTo('https://trei.no/whatsapp.html?phone=' + numero);
  }
}
