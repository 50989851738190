import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonRouting } from './common.routing';
import { SharedModule } from '../shared/shared.module';

import { CommonComponent } from './common.component';
import { LoginComponent } from './login/login.component';
import { FooterComponent } from './footer/footer.component';
import { CadastrarComponent } from './cadastrar/cadastrar.component';
import { CriarSenhaComponent } from './criar-senha/criar-senha.component';
import { RecuperarSenhaComponent } from './recuperar-senha/recuperar-senha.component';
import { RedefinirSenhaComponent } from './redefinir-senha/redefinir-senha.component';
import { ConfirmarEmailComponent } from './confirmar-email/confirmar-email.component';
import { EmailConfirmadoComponent } from './email-confirmado/email-confirmado.component';
import { ConfirmarTelefoneComponent } from './confirmar-telefone/confirmar-telefone.component';
import { CadastrarAlunoComponent } from './cadastrar/cadastrar-aluno/cadastrar-aluno.component';
import { PoliticaPrivacidadeComponent } from './politica-privacidade/politica-privacidade.component';
import { CadastrarProfissionalComponent } from './cadastrar/cadastrar-profissional/cadastrar-profissional.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    CommonRouting,
    ReactiveFormsModule,
  ],
  declarations: [
    LoginComponent,
    CommonComponent,
    FooterComponent,
    CadastrarComponent,
    CriarSenhaComponent,
    CadastrarAlunoComponent,
    RecuperarSenhaComponent,
    RedefinirSenhaComponent,
    ConfirmarEmailComponent,
    EmailConfirmadoComponent,
    ConfirmarTelefoneComponent,
    PoliticaPrivacidadeComponent,
    CadastrarProfissionalComponent,
  ]
})

export class CommonComponentModule {
}
