import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { API } from './../../../environments/environment';
import { Postagem } from './../../core/core.interfaces';
import { StorageService } from './storage.service';

@Injectable()
export class PostagensService {
  postagensStream: ReplaySubject < any > = new ReplaySubject(1);

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) {}

  // Atualiza as postagens
  updatePostagens(): void {
    this.http
      .get(API.treino + '/Postagens/ObterTodasAtivas', {
        withCredentials: true
      })
      .subscribe((response: any) => {
        this.storageService.set('postagens', response);
        this.postagensStream.next(response);
      }, error => {
        console.log(error);
      });
  }

  // Retorna todos as postagens vinculadas à conta
  getPostagens(): ReplaySubject < Postagem[] > {
        this.postagensStream.next(this.storageService.get('postagens'));
        if (navigator.onLine) {
          this.postagensStream.next({ carregando: true });
          this.updatePostagens();
        }
    return this.postagensStream;
  }
}
