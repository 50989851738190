import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-timers',
  templateUrl: './timers.component.html',
  styleUrls: ['./timers.component.scss']
})
export class TimersEscolhaComponent implements OnInit {
  tabata: boolean;
  cronometro: boolean;
  timer: boolean;
  emom: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
  }

  click(timer) {
    setTimeout(() => {
      this.router.navigate([`../timers/${timer}`], {relativeTo: this.route}).then();
    }, 500);

    if (timer === 'tabata') {
      this.tabata = true;
      return;
    } else if (timer === 'cronometro') {
      this.cronometro = true;
      return;
    } else if (timer === 'emom') {
      this.emom = true;
      return;
    } else {
      this.timer = true;
      return;
    }
  }
}
