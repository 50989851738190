<section id="minhas-avaliacoes">
  <section id="header-minhas-avaliacoes">
    <h2>
      Minhas Avaliações
    </h2>
    <span *ngIf="!carregando" >Proxima avaliaçao física: {{ dataAfig && dataAfig.DataProximaAvaliacao ? (dataAfig?.DataProximaAvaliacao | date: 'dd/MM/YYYY') : ' não definido'}}</span>
    <span>Avaliações: {{QtAvaliacoes}}</span>
  </section>
  <section id="listagem-avaliacoes-fisicas">
    <div class="loading" *ngIf="carregando">
        <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
    </div>
    <div class="sem-avaliacoes" *ngIf="!carregando && dataAfig?.Avaliacoes.length === 0">
      <span>Não há avaliações físicas cadastradas.</span>
    </div>
    <div class="list" *ngIf="!carregando && dataAfig?.Avaliacoes.length > 0">
      <div class="list__row" *ngFor="let item of dataAfig.Avaliacoes" (click)="visualizarAvaliacao(item)">
        <div class="list__row__top">
          <div class="list__row__info">
            <div class="list__row__title">
              Data
            </div>
            <div class="list__row__subtitle">
              {{item.DataAvaliacao | date: 'dd/MM/YYYY'}}
            </div>
          </div>
          <div class="list__row__info">
            <div class="list__row__title">
              Massa
            </div>
            <div class="list__row__subtitle">
              {{item.Massa}}Kg
            </div>
          </div>
          <div class="list__row__info">
            <div class="list__row__title">
              IMC
            </div>
            <div class="list__row__subtitle">
              {{item.IMC | number: '1.2-2'}} Kg/m²
            </div>
          </div>
        </div>
        <div class="list__row__bottom">
          <span>Ver avaliação</span>
        </div>
      </div>
    </div>
  </section>
</section>
