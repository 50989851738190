import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter,
  Input, NgZone, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Rx';

import { Exercicio } from './../../../core/core.interfaces';
import { NotificacoesService } from './../../../shared/services/notificacoes.service';
import { TreinoService } from './../../../shared/services/treino.service';
import { VibracaoService } from './../../../shared/services/vibracao.service';
import {EventEmitterService} from "../../../shared/services/event-emitter.service";

declare var $: any, cordova: any;

@Component({
  selector: 'app-card-exercicio',
  templateUrl: './card-exercicio.component.html',
  styleUrls: ['./card-exercicio.component.scss']
})
export class CardExercicioComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy, AfterViewInit {
  @Input() exercicio: Exercicio;
  @Input() exercicioBiset: Exercicio;
  @Input() exercicioTriset: Exercicio;
  @Input() editarCarga: any;
  @Input() ajusteTreino: any;
  @Output() cargaEditada = new EventEmitter();
  @Output('completarExercicio') completarExercicio: EventEmitter < any > = new EventEmitter();
  @Output() salvarEstado: EventEmitter < any > = new EventEmitter();
  @ViewChild('exercicioDescricao')
  exercicioDescricao: ElementRef;

  instrucao;
  carga: Array < any > ;
  repeticao: Array < any > ;

  cargaBiset: Array < any > ;
  repeticaoBiset: Array < any > ;

  cargaTriset: Array < any > ;
  repeticaoTriset: Array < any > ;
  isDesktop: boolean;

  el: any;
  body: any;
  cardAtivo = 1;
  elementoCardAtivo: any;
  loadingExtra: boolean;
  descanso: number;
  intervalo: any;
  card: any;
  overlay: any;
  checkbox: any;
  descricaoBiset: any;
  finaliza = false;
  notificationTimeout: any;
  currentInterval = 0;
  avancoBloqueado = false;
  descansoIniciado = false;
  extraOpen = false;
  extraInfo: any;
  extraInfoBiset: any;
  extraInfoTriset: any;
  quantidadeExercicio: any = 1;
  descricaoFontSize;

  isAlive = true;
  height;
  exercicioPaddingBottom;

  constructor(
    private zone: NgZone,
    private elRef: ElementRef,
    private treinoService: TreinoService,
    private vibracaoService: VibracaoService,
    private notificacoesService: NotificacoesService
  ) {
  }

  ngOnInit() {
    this.atualizarValores();
    EventEmitterService.get('atualizarCardExercicio').subscribe(() => {
      this.atualizarValores();
    });
  }

  ngAfterViewInit() {
    this.height = this.exercicioDescricao.nativeElement.offsetHeight;

    if (this.height < 20) {
      this.descricaoFontSize = 20 + 'px';
      this.exercicioPaddingBottom = 15 + 'px';
    }
  }

  atualizarValores(){
    this.setIsDesktop();
    // Fazer split de exercicio e repeticao para poder alterar dinamicamente caso tenha mais de um "10/20/30"
    this.repeticao = this.split(this.exercicio, 'Repeticao');
    this.carga = this.split(this.exercicio, 'Carga');


    this.repeticaoBiset = this.split(this.exercicioBiset, 'Repeticao');
    this.cargaBiset = this.split(this.exercicioBiset, 'Carga');


    this.repeticaoTriset = this.split(this.exercicioTriset, 'Repeticao');
    this.cargaTriset = this.split(this.exercicioTriset, 'Carga');

  }

  setInstrucao(exercicio: Exercicio) {
    if (exercicio && exercicio.ExerciceHasMedia) {
      this.treinoService.getMediasByExercise(exercicio.ExercicioId)
      .pipe().subscribe((media: any) => {
        this.mostrarInstrucao(media)
      })
    }
  }

  split(obj, key) {
    if (obj && obj[key]) {
      try {
        if (obj[key].indexOf('/') !== -1) {
          return obj[key].split('/');
        } else if (obj[key].indexOf('-') !== -1) {
          return obj[key].split('-');
        } else {
          return [obj[key]];
        }
      } catch (e) {
        return [];
      }
    } else {
      return [];
    }
  }

  ngAfterViewChecked(): void {
    this.tipoExercicio();

    this.body = $('body');
    this.el = $(this.elRef.nativeElement);
    this.card = this.el.find('mat-card');
    this.overlay = this.el.find('#descanso-overlay');
    this.checkbox = this.el.find('.mat-checkbox-input');
    this.descricaoBiset = this.el.find('#descricao-biset');
    this.elementoCardAtivo = this.el.find('#card-1');

    this.eventoTapCheckbox();
    this.eventoDrag();
  }

  ngOnChanges(): void {
    this.tipoExercicio();

    if (this.exercicio.Serie && this.exercicio.Serie <= this.exercicio.SeriesConcluidas ||
      !this.exercicio.Serie && this.exercicio.SeriesConcluidas > 0) {

      this.completarExercicio.emit({
        marcado: 'marcado_automatico',
        quantidade: this.quantidadeExercicio
      });

      this.finaliza = true;
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.desativaFoco();
  }

  // Obtem as informações do exercício na API
  getInfoAjuda(exercicio): Observable < any > {
    return this.treinoService.obterExercicioAjuda(exercicio);
  }

  abrirEdicaoCarga(exercicio): void {
    this.cargaEditada.emit({
      opened: true,
      exercicioData: exercicio
    });

    EventEmitterService.get('abrir-edicao-carga').emit();
  }

  // Abre ou fecha a seção de informações de ajuda sobre o exercício
  toggleExtra(): void {
    this.extraOpen = !this.extraOpen;
    this.loadingExtra = true;

    if (!this.extraInfo) {
      this.getInfoAjuda(this.exercicio.ExercicioId)
        .takeWhile(() => this.isAlive)
        .subscribe(response => {
          this.extraInfo = response;
          this.loadingExtra = false;
        }, error => {
          this.loadingExtra = false;
        });

      if (this.exercicioBiset) {
        this.getInfoAjuda(this.exercicioBiset.ExercicioId)
          .takeWhile(() => this.isAlive)
          .subscribe(response => {
            this.extraInfoBiset = response;
            this.loadingExtra = false;
          }, error => {
            this.loadingExtra = false;
          });
      }

      if (this.exercicioTriset) {
        this.getInfoAjuda(this.exercicioTriset.ExercicioId)
          .takeWhile(() => this.isAlive)
          .subscribe(response => {
            this.extraInfoTriset = response;
            this.loadingExtra = false;
          }, error => {
            this.loadingExtra = false;
          });
      }
    } else {
      this.extraInfo = null;
    }

    this.checkCardSize();
  }

  // Faz a redução do contador, executado periodicamente pelo setInterval (iniciaCountdown)
  intervalCount(): void {
    this.zone.run(() => {
      this.currentInterval -= 100;
    });
    if (this.currentInterval === 3000 || this.currentInterval === 2000) {
      this.vibracaoService.vibrar(100);
    } else if (this.currentInterval === 1000) {
      this.vibracaoService.vibrar(1000);
    } else if (this.currentInterval === 0) {
      this.finalizaDescanso();
    }
  }

  // Remove os eventos de pause e resume
  resetListener(): void {
    document.removeEventListener('pause', () => {}, false);
    document.removeEventListener('resume', () => {}, false);
  }

  // Inicia o contador de descanso
  iniciaCountdown(): void {
    clearInterval(this.intervalo);

    // Inicia o intervalo do contador
    this.zone.run(() => {
      this.intervalo = setInterval(() => {
        this.intervalCount();
      }, 100);

      // Inicia o evento de pause
      this.resetListener();
      document.addEventListener('pause', () => {
        if (this.descansoIniciado) {
          clearInterval(this.intervalo);
          this.intervalo = setInterval(() => {
            if (this.currentInterval <= 1000) {
              this.notificacoesService.criar(new Date(), 'Seu descanso acabou');
              this.finalizaDescanso();
            }
            this.currentInterval = this.currentInterval - 1000;
          }, 1000);
        }
      }, false);

      // Inicia o evento de resume
      document.addEventListener('resume', () => {
        if (this.descansoIniciado) {
          clearInterval(this.intervalo);
          this.iniciaCountdown();
        }
      }, false);

    });
  }

  // Reseta o contador de descanso
  resetCountdown(): void {
    this.zone.run(() => {
      clearInterval(this.intervalo);
      this.currentInterval = this.descanso;
    });
  }

  // Adiciona uma série às SeriesConcluidas
  incrementarSerie(): void {
    this.exercicio.SeriesConcluidas++;
    this.treinoService
      .incrementarSerie(this.exercicio.FichaTreinoItemId)
      .takeWhile(() => this.isAlive)
      .subscribe();
    this.salvarEstado.emit(true);
  }

  // Remove uma série das SeriesConcluidas
  decrementarSerie(): void {
    this.exercicio.SeriesConcluidas--;
    this.treinoService
      .decrementarSerie(this.exercicio.FichaTreinoItemId)
      .takeWhile(() => this.isAlive)
      .subscribe();
    this.salvarEstado.emit(true);
  }

  // Retorna as classes da flag pelo id
  getFlagClass(id: number): string {
    let returnString = '';

    if (id === this.cardAtivo) {
      returnString += 'active';
    }

    if (!this.finaliza) {
      switch (this.cardAtivo) {
        case 1:
          if (id === 2) {
            returnString += this.exercicioTriset ? 'biset' : 'biset';
          } else if (id === 3) {
            returnString += 'triset';
          }
          break;
        case 2:
          if (id === 1) {
            returnString += this.exercicioTriset ? 'triset' : 'biset';
          } else if (id === 3) {
            returnString += 'biset';
          }
          break;
        case 3:
          if (id === 1) {
            returnString += 'biset';
          } else if (id === 2) {
            returnString += 'triset';
          }
      }
    } else {
      if (this.exercicioBiset && this.exercicio.TipoExercicio === this.exercicioBiset.TipoExercicio && id === 1 ||
        this.exercicioBiset && this.exercicioTriset &&
        this.exercicioBiset.TipoExercicio === this.exercicioTriset.TipoExercicio && id === 2) {
        returnString += ' no-content';
      }
    }

    if (id === 1 && !this.exercicioBiset && !this.exercicioTriset ||
      this.cardAtivo === 1 && !this.exercicioTriset && id === 2 ||
      this.cardAtivo === 1 && this.exercicioTriset && id === 3 ||
      this.cardAtivo === 2 && !this.exercicioTriset && id === 1 ||
      this.cardAtivo === 2 && this.exercicioTriset && id === 1 ||
      this.cardAtivo === 3 && id === 2) {
      returnString += ' rounded';
    }

    return returnString;
  }

  // Restaura a ordenação original dos cards
  resetElements(): void {
    if (this.cardAtivo !== 1) {
      this.cardAtivo = 1;
      this.avancaDescricao(1);

      setTimeout(() => {
        if (this.cardAtivo === 3) {
          this.moveCard(3, 1, 'left', false);
        } else {
          this.moveCard(2, 1, 'right', false);
        }
      }, 300);
    }
  }

  // Restaura um elemento para o centro da exibição
  resetElement(element: any): void {
    element.stop(true).transition({
      transform: 'translate3d(0, 0, 0)'
    });
  }

  // Prepara o próximo card para o centro da exibição
  prepareNext(element: number, active: number, direction: string, transitionSerie ?: boolean): void {
    const dir = direction === 'left' ? '+' : '-';
    if (transitionSerie) {
      if (direction === 'left') {
        this.finalizaSerie();
      } else {
        this.retornaSerie();
      }
    }

    this.el.find('#card-' + element).stop(true)
      .transition({
        transform: 'translate3d(' + dir + '100%, 0, 0)'
      }, 0, () => {
        setTimeout(() => {
          this.avancoBloqueado = false;
          this.eventoDrag();
        }, 0);
      });
  }

  // Evento quando a transição é finalizada
  transitionEnd(direction: string, cardAtivo: number, transitionSerie ?: boolean): void {
    if (direction === 'left') {
      switch (cardAtivo) {
        case 1:
          if (this.exercicioTriset) {
            this.prepareNext(3, 2, direction, false);
          } else {
            this.prepareNext(1, 2, direction, false);
          }
          break;

        case 2:
          if (this.exercicioTriset) {
            this.prepareNext(1, 3, direction, false);
          } else {
            this.prepareNext(2, 1, direction, transitionSerie === undefined || transitionSerie === true);
          }
          break;

        case 3:
          this.prepareNext(2, 1, direction, transitionSerie === undefined || transitionSerie === true);
          break;
      }

    } else if (direction === 'right') {
      switch (cardAtivo) {
        case 1:
          if (this.exercicioTriset) {
            this.prepareNext(2, 3, direction, transitionSerie === undefined || transitionSerie === true);
          } else {
            this.prepareNext(1, 2, direction, transitionSerie === undefined || transitionSerie === true);
          }
          break;

        case 2:
          if (this.exercicioTriset) {
            this.prepareNext(3, 1, direction, false);
          } else {
            this.prepareNext(2, 1, direction, false);
          }
          break;

        case 3:
          this.prepareNext(1, 2, direction, false);
          break;
      }
    }
  }

  // Função para movimentação dos cards
  moveCard(activeCard: number, nextCard: number, direction: string, transitionSerie ?: boolean): void | boolean {
    if (this.avancoBloqueado) {
      return false;
    }

    this.avancoBloqueado = true;

    const cardOne = this.el.find('#card-' + activeCard);
    const cardTwo = this.el.find('#card-' + nextCard);

    let porcOne: string;
    let porcTwo: string;

    switch (direction) {
      case 'left':
        porcOne = '-100%';
        porcTwo = '0';
        break;

      case 'right':
        porcOne = '100%';
        porcTwo = '0';
        break;
    }

    cardOne.stop(true).transition({
        transform: 'translate3d(' + porcOne + ', 0, 0)'
      },
      () => this.transitionEnd(direction, activeCard, transitionSerie)
    );

    cardTwo.stop(true).transition({
      transform: 'translate3d(' + porcTwo + ', 0, 0)'
    });

    this.avancaDescricao(nextCard);
  }

  // Move a descrição do exercício em caso de biset e triset
  moveDescricao(rowNumber: number, height: number, background ?: boolean): void {
    const row = this.el.find('#card-row-' + rowNumber);
    if (background) {
      this.el.find('#card-row-' + rowNumber).addClass('background');
    }

    row.transition({
      transform: 'translate3d(0, ' + height + 'px, 0)'
    }, () => {
      row.removeClass('background');
    });
  }

  // Verifica o tamanho do card para que não haja overflow de informações
  checkCardSize(): void {
    if (this.extraOpen) {
      const cardAtivo = this.el.find('#card-' + this.cardAtivo);
      const heightContent = cardAtivo.find('.mat-card-content').height();
      const heightActions = cardAtivo.find('.mat-card-actions').height();
      const heightInfo = cardAtivo.find('.treino-info').height();

      const heightTotal = heightContent + heightActions + heightInfo;

      this.el.find('.status-content').css({
        'min-height': heightTotal
      });

    } else {
      this.el.find('.status-content').css({
        'min-height': 'auto'
      });
    }
  }

  // Move a descrição do exercício quando biset ou triset
  avancaDescricao(nextCard: number): void {
    this.zone.run(() => {
      this.cardAtivo = nextCard;
      this.checkCardSize();
    });

    switch (nextCard) {
      case 1:
        this.moveDescricao(1, 0, true);
        this.moveDescricao(2, 0);
        this.moveDescricao(3, 0);
        break;

      case 2:
        this.moveDescricao(1, this.exercicioTriset ? 100 : 50);
        this.moveDescricao(2, -50, true);
        this.moveDescricao(3, -50);
        break;

      case 3:
        this.moveDescricao(1, 49);
        this.moveDescricao(2, 49);
        this.moveDescricao(3, -101, true);
        break;
    }
  }

  // Avança o exercício quando biset ou triset
  avancaExercicio(): void {
    switch (this.cardAtivo) {
      case 1:
        this.moveCard(1, 2, 'left');
        break;
      case 2:
        if (this.exercicioTriset) {
          this.moveCard(2, 3, 'left');
        } else {
          this.moveCard(2, 1, 'left');
        }
        break;
      case 3:
        this.moveCard(3, 1, 'left');
        break;
    }
  }

  // Retorna o exercício quando biset ou triset
  retornaExercicio(): void {
    switch (this.cardAtivo) {
      case 1:
        if (this.exercicioTriset) {
          this.moveCard(1, 3, 'right');
        } else {
          this.moveCard(1, 2, 'right');
        }
        break;
      case 2:
        this.moveCard(2, 1, 'right');
        break;
      case 3:
        this.moveCard(3, 2, 'right');
        break;
    }
  }

  // Reduz uma série finalizada
  retornaSerie(): void {
    const p = this.el.find('#series').find('p');
    this.zone.run(() => {
      this.decrementarSerie();
    });
    p.css({
      'animation': 'series-pop .5s ease'
    }).one('animationend', () => {
      p.css('animation', 'none');
    });
  }

  // Finaliza uma série
  finalizaSerie(): void | boolean {
    if (this.exercicio.Serie - this.exercicio.SeriesConcluidas === 1) {
      this.finalizaExercicio(true, true);
      return false;
    }

    const p = this.el.find('#series').find('p');
    const i = this.el.find('#series').find('i');
    p.css('color', '#00c853');

    this.zone.run(() => {
      i.css('display', 'block').one('animationend', () => {
        i.css('display', 'none');
      });

      setTimeout(() => {
        this.incrementarSerie();
        p.css({
          'color': 'black',
          'animation': 'series-pop .5s ease'
        })
        .one('animationend', () => {
          p.css('animation', 'none');
        });
      }, 500);
    });
  }

  // Finaliza o exercício
  finalizaExercicio(close: boolean, reset ?: any): void | boolean {
    if (close) {
      if (reset) {
        this.resetElements();
      }

      this.finaliza = true;

      // Enviar que mais um exercicio foi completo
      this.completarExercicio.emit({
        marcado: 'marcado',
        quantidade: this.quantidadeExercicio
      });

      this.extraOpen = false;
      this.checkCardSize();

      if (this.exercicio.Serie) {
        this.exercicio.SeriesConcluidas = this.exercicio.Serie;
      } else {
        this.exercicio.SeriesConcluidas = 1;
      }

      this.treinoService
        .finalizarSerie(this.exercicio.FichaTreinoItemId)
        .takeWhile(() => this.isAlive)
        .subscribe();

      this.salvarEstado.emit(true);
      this.currentInterval = this.descanso;

    } else {
      this.completarExercicio.emit({
        marcado: '0',
        quantidade: this.quantidadeExercicio
      });
      this.finaliza = false;
      this.exercicio.SeriesConcluidas = 0;

      this.treinoService
        .reiniciarSerie(this.exercicio.FichaTreinoItemId)
        .takeWhile(() => this.isAlive)
        .subscribe();

      this.salvarEstado.emit(true);
    }
  }

  // Inicia o descanso
  iniciaDescanso(descanso?: number): void {
    this.descanso = descanso * 1000;
    this.currentInterval = this.descanso;

    if (descanso <= 0) {
      setTimeout(() => {
        this.finalizaDescanso();
        this.finalizaExercicio(true);
      }, 1000);
    }

    this.atribuiFoco();
    this.descansoIniciado = true;
    this.resetCountdown();
    this.vibracaoService.vibrar(100);
    this.iniciaCountdown();

    if (descanso) {
      this.overlay.css({
        transform: 'translate3d(100%, 0, 0)'
      });
      this.elementoCardAtivo.css({
        transform: 'translate3d(0, 0, 0)'
      });
    }

    this.zone.run(() => {
      this.overlay.stop(true).transition({
        transform: 'translate3d(0, 0, 0)'
      });
      this.elementoCardAtivo.stop(true).transition({
        transform: 'translate3d(-100%, 0, 0)'
      });
    });
  }

  // Finaliza o descanso
  finalizaDescanso(button ?: boolean): void {
    clearInterval(this.intervalo);

    if (this.exercicio.Serie - this.exercicio.SeriesConcluidas === 1) {
      this.finalizaExercicio(true);
    }

    this.descansoIniciado = false;
    this.desativaFoco();

    if (button) {
      this.overlay.css({
        transform: 'translate3d(0, 0, 0)'
      });
      this.elementoCardAtivo.css({
        transform: 'translate3d(100%, 0, 0)'
      });
    }

    if (this.cardAtivo === 1) {
      this.overlay.stop(false).transition({
        transform: 'translate3d(100%, 0, 0)'
      });
      this.elementoCardAtivo.stop(false).transition({
        transform: 'translate3d(0, 0, 0)'
      });
    } else {
      this.zone.run(() => {
        this.overlay.stop(false).transition({
          transform: 'translate3d(100%, 0, 0)'
        });
        this.elementoCardAtivo.stop(false).transition({
          transform: 'translate3d(-100%, 0, 0)'
        });
      });
    }

    this.finalizaSerie();
  }

  // Cancela o descanso
  cancelaDescanso(): void {
    this.descansoIniciado = false;
    this.desativaFoco();

    this.zone.run(() => {
      this.overlay.stop(true).transition({
        transform: 'translate3d(100%, 0, 0)'
      }, () => {
        this.resetCountdown();
      });
      this.elementoCardAtivo.stop(true).transition({
        transform: 'translate3d(0, 0, 0)'
      });
    });
  }

  // Atribui o foco ao exercício
  atribuiFoco(): void {
    const body = $('body, main');
    body.css('overflow', 'hidden');
    // let card = this.el.find('mat-card');
    // if (card) {
    //     let offsetTop = card.offset().top + 150;
    //     body.animate({
    //         scrollTop: offsetTop
    //     }, 500);
    // }
  }

  // Remove o foco do exercício
  desativaFoco(): void {
    const body = $('body, main');
    body.css('overflow', 'initial');
  }

  private setIsDesktop(): void {
    this.isDesktop = window.outerWidth > 1200;
  }

  eventoTapCheckbox(): void {
    const evento = window.outerWidth <= 767 ? 'touchstart' : 'click';
    this.el.find('.checkbox-tap').off().on(evento, ev => {
      ev.stopPropagation();
      this.finalizaExercicio(!this.finaliza, true);
    });
  }

  tipoExercicio() {
    // Mandar a quantidade de exercício que precisa contabilizar
    if (this.exercicioBiset) {
      this.quantidadeExercicio = 2;
    }
    if (this.exercicioTriset) {
      this.quantidadeExercicio = 3;
    }
  }

  // Evento que manipula o swipe dos cards, para avançar ou retroceder os exercícios
  eventoDrag(): void {
    const container = this.el.find('mat-card');
    const card = this.el.find('#card-' + this.cardAtivo);
    let nextCard: number | any;
    let prevCard: number | any;

    $.Finger = {
      pressDuration: 300,
      doubleTapInterval: 300,
      flickDuration: 150,
      motionThreshold: 50
    };

    switch (this.cardAtivo) {
      case 1:
        nextCard = 2;
        if (this.exercicioTriset) {
          prevCard = 3;
        } else {
          prevCard = 2;
        }
        break;
      case 2:
        if (this.exercicioTriset) {
          nextCard = 3;
        } else {
          nextCard = 1;
        }
        prevCard = 1;
        break;
      case 3:
        nextCard = 1;
        prevCard = 2;
        break;
    }

    nextCard = this.el.find('#card-' + nextCard);
    prevCard = this.el.find('#card-' + prevCard);

    const nextCardWidth = nextCard.outerWidth();
    const prevCardWidth = prevCard.outerWidth();

    const overlayDescansoWidth = this.overlay.outerWidth();
    const cardWidth = card.outerWidth();

    container.off('drag').on('drag', (e: any) => {

      // Evento de drag apenas na horizontal
      if (e.orientation === 'horizontal' && !this.avancoBloqueado && !this.finaliza) {
        this.body.css('overflow', 'hidden');

        // Condição para exercícios únicos
        if (!this.exercicioBiset && !this.exercicioTriset) {
          // Realiza ação apenas se houver descanso
          if ((e.dx <= 0 && !this.descansoIniciado) || this.descansoIniciado) {
            const distanceOverlay = this.descansoIniciado ? e.dx : e.dx + overlayDescansoWidth;
            const distanceCard = this.descansoIniciado ? e.dx + cardWidth * (e.direction * -1) : e.dx;

            this.overlay.css({
              transform: 'translate3d(' + distanceOverlay + 'px, 0, 0)'
            });
            this.elementoCardAtivo.css({
              transform: 'translate3d(' + distanceCard + 'px, 0, 0)'
            });

            // Evento final
            if (e.end) {
              if (!this.descansoIniciado) {
                this.body.css('overflow', 'auto');
              }

              // Arrastado para a esquerda
              if (e.dx < -100) {
                // Inicia o descanso
                if (!this.descansoIniciado) {
                  this.iniciaDescanso(this.exercicio.Descanso);

                  // Finaliza o descanso
                } else {
                  this.finalizaDescanso();
                }

                // Arrastado para a direita
              } else if (e.dx > 100) {
                this.cancelaDescanso();

                // Evento cancelado
              } else {
                this.overlay.stop(true).transition({
                  transform: 'translate3d(' + (this.descansoIniciado ? '0' : '100%') + ', 0, 0)'
                });
                this.elementoCardAtivo.stop(true).transition({
                  transform: 'translate3d(' + (this.descansoIniciado ? (e.direction * -1) + '00%' : '0') + ', 0, 0)'
                });
              }
            }
          }

          // Caso haja biset ou triset
        } else {
          // Move o card ativo para a direita apenas se não estiver no estado inicial
          if (!(e.dx >= 0 && this.exercicio.SeriesConcluidas === 0 && this.cardAtivo === 1)) {
            card.css({
              transform: 'translate3d(' + e.dx + 'px, 0, 0)'
            });

            // Reseta os cards ao mudar a direção do drag
            if (this.exercicioTriset) {
              if (e.dx >= 0) {
                nextCard.css({
                  transform: 'translate3d(100%, 0, 0)'
                });
              } else if (e.dx <= 0) {
                prevCard.css({
                  transform: 'translate3d(-100%, 0, 0)'
                });
              }
            }

            // DIREITA
            if (e.dx >= 0) {
              prevCard.css({
                transform: 'translate3d(' + (e.dx - prevCardWidth) + 'px, 0, 0)'
              });

              // ESQUERDA
            } else if (e.dx <= 0) {
              nextCard.css({
                transform: 'translate3d(' + (e.dx + nextCardWidth) + 'px, 0, 0)'
              });
            }

            // Evento final
            if (e.end) {
              this.body.css('overflow', 'auto');

              // Arrastado para a esquerda
              if (e.dx < -100) {
                this.zone.run(() => {
                  this.avancaExercicio();
                });

                // Arrastado para a direita
              } else if (e.dx > 100) {
                this.retornaExercicio();

                // Evento cancelado
              } else {
                // Cancelado ao arrastar para a direita
                if (e.dx >= 0) {
                  // Caso exista o triset
                  if (this.exercicioTriset) {
                    prevCard.stop(true, true).transition({
                      transform: 'translate3d(-100%, 0, 0)'
                    });

                    // Não existe triset
                  } else {
                    nextCard.stop(true, true).transition({
                      transform: 'translate3d(-100%, 0, 0)'
                    });
                  }

                  // Cancelado ao arrastar para a esquerda
                } else {
                  nextCard.stop(true, true).transition({
                    transform: 'translate3d(100%, 0, 0)'
                  });
                }
                card.stop(true, true).transition({
                  transform: 'translate3d(0, 0, 0)'
                });
              }
            }
          }
        }
      }
    });
  }

  mostrarInstrucao(instrucao) {
    this.instrucao = instrucao;
  }

  closeModal(event) {
    this.instrucao = event;
  }
}
