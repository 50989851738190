import {Component, OnInit, Input, OnDestroy, Output, EventEmitter} from '@angular/core';
import { Subject, pipe } from 'rxjs';


@Component({
  selector: 'app-card-assinatura',
  templateUrl: './card-assinatura.component.html',
  styleUrls: ['./card-assinatura.component.scss'],
})
export class CardAssinaturaComponent implements OnInit, OnDestroy {
  @Input() permitirPagarComPaguela: boolean;
  @Input() assinaturasAtuais = [];
  @Input() vendasPaguela: any[] = [];
  @Input() situacaoPaguela: number;
  @Input() contasReceber: any;
  @Output() vincularCartao = new EventEmitter();

  unsubscriber = new Subject();

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  getBrandSource(brand): string {
    const brands = [
      { name: 'Visa', fileName: 'visa.svg' },
      { name: 'Amex', fileName: 'american-express.svg' },
      { name: 'Master', fileName: 'mastercard.svg' },
      { name: 'Elo', fileName: 'elo.svg'},
      { name: 'JCB', fileName: 'jbc.png'},
      { name: 'Aura', fileName: 'aura.png'},
      { name: 'Diners', fileName: 'diners.svg'  },
      { name: 'Discover', fileName: 'discover.svg' },
      { name: 'Hipercard', fileName: 'hipercard.png' }
    ];

    for ( const b of brands ) {
      if (b.name === brand) {
        return `/assets/imagens/brands/${b.fileName}`;
      }
    }
  }

  adicionarContasReceberParaVendaCartao(assinatura, alterar?: boolean) {
    this.vincularCartao.emit(this.contasReceber.filter(cr => assinatura.AssinaturaPessoaId === cr.AssinaturaPessoaId));
  }

}
