import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ErrorHandlerService } from '../../../../shared/services/error-handler.service';
import { EventEmitterService } from '../../../../shared/services/event-emitter.service';
import { LocalStorageService } from '../../../../shared/services/localstorage.service';
import { SweetalertService } from '../../../../shared/services/sweetalert.service';
import { TreinoService } from '../../../../shared/services/treino.service';

@Component({
  selector: 'app-editar-carga',
  templateUrl: './editar-carga.component.html',
  styleUrls: ['./editar-carga.component.scss']
})
export class EditarCargaComponent implements OnInit {

  @Input() carga: any;

  @Output() cargaOutput = new EventEmitter();

  @ViewChild(FormGroupDirective) formDirective;

  @ViewChild('fieldName') fieldName: ElementRef;

  form: UntypedFormGroup;
  isAlive = true;
  salvando: boolean;
  error: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private treinoService: TreinoService,
    private errorHandlerService: ErrorHandlerService,
    private sweetAlertService: SweetalertService,
    private storageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      FichaTreinoItemId: null,
      Carga: [null, [Validators.required]]
    });

    EventEmitterService.get('abrir-edicao-carga').subscribe( ev =>{
      setTimeout(() => {
        this.fieldName.nativeElement.focus();
      }, 50);
    });
  }

  fecharEditar(): void {
    const fileChooser = document.getElementById('carga-chooser');
    if (!fileChooser) { return; }

    if (fileChooser) {
      const isOpen = fileChooser.classList.value.indexOf('slideUp') > -1;
      if (isOpen) {
        fileChooser.classList.remove('slideUp');
        fileChooser.classList.add('slideDown');
      } else {
        fileChooser.classList.remove('slideDown');
        fileChooser.classList.add('slideUp');
      }
    }
    this.formDirective.resetForm();
    this.form.reset();
  }

  salvar({ valid }): void {
    if (!valid) { return; }
    this.salvando = true;

    if (!this.carga.FichaTreinoItemId) {
      this.sweetAlertService.error('Ocorreu um erro interno.').subscribe();
      return;
    }

    this.form.controls.FichaTreinoItemId.setValue(this.carga.FichaTreinoItemId);
    this.treinoService.atualizarCarga(this.form.get('FichaTreinoItemId').value, this.form.get('Carga').value).takeWhile(() => this.isAlive).subscribe(() => {
      this.carga.Carga = this.form.get('Carga').value.toString();
      this.sweetAlertService.success('Alterações realizadas com sucesso').subscribe();

      EventEmitterService.get('atualizarCarga').emit(this.carga);

      this.formDirective.resetForm();
      this.form.reset();
      this.fecharEditar();
      this.salvando = false;
    }, error => {
      console.error(error);

      this.form.reset();
      this.salvando = false;
      this.errorHandlerService.error(error);
    });
  }
}
