<section class="texto-cadastro-aluno">
  <div>
    <h2 class="text-white ">Crie seu login em sua
      <span class="texto-verde-100">academia</span> ou com seu
      <span class="texto-verde-100">personal trainer</span>!</h2>
  </div>
  <div class="m-t-20">
    <button (click)="voltarParaCadastro()" class="btn" mat-button mat-ripple>Voltar a seleção de cadastros</button>
  </div>

  <app-footer></app-footer>
</section>
