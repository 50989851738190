import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { LoginService } from '../../shared/services/login.service';
import { SweetalertService } from '../../shared/services/sweetalert.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';

@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.scss']
})
export class RecuperarSenhaComponent implements OnInit {
  form: UntypedFormGroup;
  formConfirmaCodigo: UntypedFormGroup;
  contas: any;
  erro: string;
  carregando= false;
  codigoEnviado = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private sweetAlertService: SweetalertService,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      Email: [ null, [ Validators.required, Validators.email ] ],
    });

    this.formConfirmaCodigo = this.fb.group({
      Codigo: [ null, [ Validators.required, Validators.email ] ],
    });

    this.route.params.subscribe(values => {
      this.form.controls['Email'].setValue(values.email);
    });
  }

  submit({ value, valid }): void {
    if (!valid) { return; }
    this.recuperarSenha(value);
  }

  selecionarConta(): void {
    const dados = {
      Email: this.form.controls.Email.value,
    };

    this.recuperarSenha(dados);
  }

  recuperarSenha(value): void {
    this.carregando = true;
    this.erro = null;

    this.loginService.enviarEmailParaRedefinicaoDeSenha(value).subscribe(() => {
      this.codigoEnviado = true;

      this.carregando = false;
    }, error => {
      console.error(error);
      if (error?.error?.errors?.Email[0]) {
        this.sweetAlertService.error(error?.error?.errors?.Email[0]).subscribe(() => {});
      }

      this.carregando = false;
      this.erro = this.errorHandlerService.getFirstValidationMessage(error);
    });
  }

  validarCodigo( ) {
    const value = {
      Email: this.form.controls.Email.value,
      Code: this.formConfirmaCodigo.controls.Codigo.value,
    };

    this.carregando = true;
    this.erro = null;

    this.loginService.checarCodigoRedefinicaoSenha(value).subscribe(() => {
      this.router.navigate(['/redefinir-senha', value.Email, value.Code]).then();

      this.carregando = false;
    }, error => {
      console.error(error);
      this.carregando = false;
      this.erro = this.errorHandlerService.getFirstValidationMessage(error);
    });
  }

  irParaLogin(): void {
    this.router.navigate(['/login']).then();
  }
}
