import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Swal, {SweetAlertOptions} from 'sweetalert2';

@Injectable()
export class SweetalertService {

  constructor() { }

  show(data: SweetAlertOptions): Observable<any> {
    return new Observable(observer => {
      Swal.fire({
        showCloseButton: data.showCloseButton,
        showDenyButton: data.showDenyButton,
        denyButtonColor: data.denyButtonColor,
        denyButtonText: data.denyButtonText,
        title: data.title,
        text: data.text,
        icon: data.icon,
        allowEscapeKey: data.allowEscapeKey,
        customClass: data.customClass,
        allowOutsideClick: data.allowOutsideClick,
        showCancelButton: data.showCancelButton,
        showConfirmButton: data.showConfirmButton,
        confirmButtonText: data.confirmButtonText,
        confirmButtonColor: data.confirmButtonColor,
        cancelButtonText: data.cancelButtonText,
        imageUrl: data.imageUrl,
        imageHeight: data.imageHeight,
        imageWidth: data.imageWidth,
        timer: data.timer,
        html: data.html,
        inputPlaceholder: data.inputPlaceholder,
        inputValue: data.inputValue,
        showLoaderOnConfirm: data.showLoaderOnConfirm,
        backdrop: false
      }).then(({isConfirmed}) => {
        observer.next(isConfirmed);
        observer.complete();
      });
    });
  }

  alert(titulo: string, str: string): Observable<any> {
    return new Observable(observer => {
      Swal.fire({
        title: titulo,
        text: str,
        icon: 'warning',
        confirmButtonColor: '#4ce364',
        backdrop: false,
        iconColor: '#4ce364',
      }).then(({isConfirmed}) => {
          observer.next(isConfirmed);
          observer.complete();
      });
    });
  }

  toast(icon: SweetAlertOptions['icon'], title: SweetAlertOptions['title']): Observable<any> {
   return new Observable((observer) => {
     const toast = Swal.mixin({
       toast: true,
       position: 'top-end',
       showConfirmButton: false,
       timer: 3000,
       timerProgressBar: true,
       didOpen: (el) => {
         el.addEventListener('mouseenter', Swal.stopTimer);
         el.addEventListener('mouseleave', Swal.resumeTimer);
       }
     });
     toast.fire({
       icon,
       title
     }).then((data) => {
       observer.next(data);
       observer.complete();
       });
     });

  }

  success(str: string, title?: string): Observable<any> {
    return new Observable(observer => {

      Swal.fire({
        title: title ? title : 'Sucesso!',
        text: str,
        icon: 'success',
        backdrop: false
      }).then(({isConfirmed}) => {
        observer.next(isConfirmed);
        observer.complete();
      });
    });
  }

  error(str: string): Observable<any> {
    return new Observable(observer => {
      Swal.fire({
        title: 'Oops!',
        text: str,
        icon: 'error',
        backdrop: false
      }).then(({isConfirmed}) => {
        observer.next(isConfirmed);
        observer.complete();
      });
    });
  }

  notificacao(titulo, texto, hideConfirmButton?): Observable<any> {
    return new Observable(observer => {
      Swal.fire({
        title: titulo,
        text: texto,
        imageUrl: 'assets/imagens/logo-treino.svg',
        imageWidth:'150px',
        imageHeight:'150px',
        showConfirmButton: !hideConfirmButton,
        confirmButtonText: 'Visualizar',
        confirmButtonColor: '#4ce364',
        showCancelButton: true,
        cancelButtonText: 'Fechar',
        backdrop: false
      }).then(({isConfirmed}) => {
          observer.next(isConfirmed);
          observer.complete();
      });
    });
  }

  confirm(titulo, texto): Observable<any> {
    return new Observable(observer => {
      Swal.fire({
        title: titulo,
        text: texto,
        showConfirmButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#ffb74d',
        showCancelButton: true,
        cancelButtonText: 'Não',
        backdrop: false
      }).then(({isConfirmed}) => {
          observer.next(isConfirmed);
          observer.complete();
        });
    });
  }

  loading(message) {
    let timerInterval;
    Swal.fire({
      title: 'Carregando...',
      html: `${message}`,
      // timer: 2000,
      // timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        // const b = Swal.getHtmlContainer().querySelector('b');
        // timerInterval = setInterval(() => {
        //   b.textContent = String(Swal.getTimerLeft());
        // }, 100);
      },
      willClose: () => {
        // clearInterval(timerInterval);
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      // if (result.dismiss === Swal.DismissReason.timer) {
      //   console.log('I was closed by the timer');
      // }
    });
  }

  close() {
    Swal.close();
  }
}
