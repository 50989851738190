<section *ngIf="!contas">
  <!--Form de login -->
  <form (ngSubmit)="submit(form)" [formGroup]="form" *ngIf="!codigoEnviado">
    <div class="title">
      <p>Recupere sua senha utilizando o e-mail cadastrado!</p>
    </div>

    <mat-form-field>
      <input matInput formControlName="Email" placeholder="E-mail">
      <mat-error *ngIf="form.get('Email').getError('required')">Campo Obrigatório</mat-error>
      <mat-error *ngIf="form.get('Email').getError('email')">Digite um email válido</mat-error>
    </mat-form-field>

    <div class="botao-entrar">
      <button type="submit" [ladda]="carregando" data-spinner-color="black" class="btn">Enviar</button>
    </div>
  </form>

  <form *ngIf="codigoEnviado" [formGroup]="formConfirmaCodigo" (ngSubmit)="validarCodigo()">
    <div class="title">
      <p>Confirme sua identidade com o código enviado no e-mail</p>
    </div>
    <mat-form-field>
      <input matInput formControlName="Codigo" placeholder="Código">
      <mat-error *ngIf="form.get('Email').getError('required')">Campo Obrigatório</mat-error>
      <mat-error *ngIf="form.get('Email').getError('email')">Digite um email válido</mat-error>
    </mat-form-field>

    <div class="botao-entrar">
      <button type="submit" [ladda]="carregando" data-spinner-color="black" class="btn">Enviar</button>
    </div>
  </form>

  <div *ngIf="erro" class="m-t-20 clearfix">
    <p class="erro">{{erro}}</p>
  </div>

  <div class="m-t-30">
    <a class="green" [routerLink]="['/login', form.get('Email').value]">Voltar ao login</a>
  </div>

  <app-footer></app-footer>
</section>

<section *ngIf="contas" class="varias-contas">
  <p class="text-center title">Escolha a sua conta</p>

  <div *ngFor="let conta of contas" class="m-b-10" (click)="selecionarConta()">
    <div class="account-tile">
      <div class="conta-content">
        <div class="text-center">
          <span>{{conta.name}}</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="erro" class="m-t-20 clearfix">
    <p class="erro">{{erro}}</p>
  </div>

  <div class="m-t-30">
    <a class="green" [routerLink]="['/login']">Voltar ao login</a>
  </div>

  <app-footer></app-footer>
</section>
