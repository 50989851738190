import { Directive, ElementRef, HostListener } from '@angular/core';

import { DevicesService } from './../services/devices.service';
import { SweetalertService } from './../services/sweetalert.service';

import * as moment from 'moment';
import { Capacitor } from '@capacitor/core';

declare var window: any, $: any;

@Directive({
  selector: '[appcalendarioMobile]'
})
export class CalendarioMobileDirective {

  constructor(
    private elRef: ElementRef,
    private devices: DevicesService,
    private sweetAlertService: SweetalertService
  ) {}

  @HostListener('click')
  onClick() {
    const notes = '';
    const title = $(this.elRef.nativeElement).data('titulo');
    const local = $(this.elRef.nativeElement).data('local');
    const useUTC = $(this.elRef.nativeElement).data('useutc');
    const startDate = new Date($(this.elRef.nativeElement).data('initdate'));
    const endDate = $(this.elRef.nativeElement).data('enddate') ? new Date($(this.elRef.nativeElement).data('enddate')) : null;
    let dataInicio = null;
    let dataFim = null;

    if (startDate) {
      if (useUTC) {
        dataInicio = new Date(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDay(), startDate.getUTCHours(), startDate.getUTCMinutes(), startDate.getUTCSeconds());
      } else {
        dataInicio = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDay(), startDate.getHours(), startDate.getMinutes(), startDate.getSeconds());
      }
    }

    if (endDate) {
      if (useUTC) {
        dataFim = new Date(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDay(), endDate.getUTCHours(), endDate.getUTCMinutes(), endDate.getUTCSeconds());
      } else {
        dataFim = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDay(), endDate.getHours(), endDate.getMinutes(), endDate.getSeconds());
      }
    }

    if (Capacitor.getPlatform() === 'ios' || 'android') {
      try {
        window.plugins.calendar.createEventInteractively(title, local, notes, dataInicio, dataFim, () => {}, () => {});
      } catch (e) {
        this.sweetAlertService.alert('', 'Baixe o app Trei.no para utilizar este recurso.').subscribe();
      }
    } else {
      this.sweetAlertService.alert('', 'Baixe o app Trei.no para utilizar este recurso.').subscribe();
    }

    window.onerror = function (msg, file, line) {
      alert(msg + '; ' + file + '; ' + line);
    };
  }
}
