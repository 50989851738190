import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs/Rx';

export interface CanComponentDeactivate {
  canDeactivate: () => boolean | Observable < boolean > | any;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate < CanComponentDeactivate > {
  canDeactivate(component: CanComponentDeactivate): Observable < any > | boolean {
    return true;
  }

  canDeactivateChild(component: CanComponentDeactivate): Observable < any > | boolean {
    return true;
  }
}
