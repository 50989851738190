<div class="loading" *ngIf="loading">
  <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
</div>

<section class="participantes" *ngIf="!loading">
    <article class="top-participantes">
      <div class="title" >
        {{turma.descricao}}
      </div>
      <div>
       Data: {{turma.dataRealizacao}}h
      </div>
      <div *ngFor="let professor of professores">
        Professor(a): {{professor.NomeCompleto}}
      </div>
    </article>

  <div class="body-participantes">
    <div class="participantes-order">
      <div>Participantes</div>
      <div [ngClass]="{'sort': true, 'a-z': !reverse, 'z-a': reverse}" >
        <span class="material-icons" (click)="changeOrder()">
        sort_by_alpha
        </span>
      </div>
    </div>
    <div class="participantes-lista-pessoas">
      <div class="flex" *ngIf="participantes.length === 0">
        Não há reservas para esta turma.
      </div>
      <div class="capsule" *ngFor="let itr of participantes | orderBy: 'itr.Pessoa.PrimeiroNome' : reverse ">
        <div class="pessoa" >
          <div class="pessoa-foto">
            <div [style.display]="itr.showAvatar ? 'block' : 'none'">
              <img [src]="itr.AvatarUrl" alt="" (load)="showAvatar(itr)"/>
            </div>
            <div [style.display]="itr.showAvatar ? 'none' : 'block'">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(255, 255, 255); display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <path d="M9 50A41 41 0 0 0 91 50A41 43.8 0 0 1 9 50" fill="#55ec23" stroke="none">
                  <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51.4;360 50 51.4"></animateTransform>
                </path>
              </svg>
            </div>

          </div>
          <div class="pessoa-info">{{itr.Pessoa.PrimeiroNome}}</div>
          <div *ngIf="itr.Situacao === 0" class="pessoa-situacao">
            <div class="reservado"></div>
            Reservado</div>
          <div *ngIf="itr.Situacao === 1" class="pessoa-situacao">
            <div class="cancelado"></div>
            Cancelado</div>
          <div *ngIf="itr.Situacao === 2" class="pessoa-situacao">
            <div class="confirmado"></div>
            Confirmado</div>
        </div>
        <legend></legend>
      </div>
    </div>
  </div>
</section>
