import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import * as Raven from 'raven-js';
import {LocalStorageService} from './localstorage.service';


@Injectable()
export class CrashReportingService {
  private dsn = 'https://58c3d824927c45a1bf6ff0a295b7bcdf@sentry.io/1730630';

  constructor(
    private localStorageService: LocalStorageService
  ) {
    if (environment.production) {
      Raven
        .config(this.dsn)
        .install();
    }
  }

  private async getUserInfo() {
    const user = await this.localStorageService.get('userData').toPromise();
    return {
      id: user.Id,
      email: user.Email,
    };
  }

  private setUser({id, email}) {
    Raven.setUserContext({id, email});
  }

  private setEnvironment() {
    Raven.setTagsContext({
      environment: environment.production ? 'production' : 'development'
    });
  }

  private async captureException(err: Error|any) {
    let error;
    if (environment.production && this.filterExceptions(err)) {
      this.setEnvironment();
      try {
        this.setUser(await this.getUserInfo());
      } catch {
        this.setUser({id: null, email: null});
      }

      try {
        if (!err) {
          error = err;
        } else if (err.error) {
          error = err.error.error_description || err.error;
        } else if (err.message) {
          error = err.message;
        } else {
          error = err;
        }
      } catch (e) {
        error = err;
      }

      if (!this.knownError(error)) {
        Raven.captureMessage(error);
      }
    }
  }

  handleError(err) {
    this.captureException(err);
    if (err && err.error && !err.error.ModelState && err.error.error !== 'account_specify') {
      console.error('RavenJS', err);
    }
  }

  private filterExceptions(error): boolean {
    switch (error.message) {
      case 'Tooltip is transitioning':
      case 'Usuário ou senha incorretos':
      case 'Conta inativa':
      case 'Usuário inválido':
      case 'Refresh token is issued to a different clientId.':
      case 'Usuário ainda não criou senha':
      case 'Collapse is transitioning':
      case 'Authorization has been denied for this request.':
      case 'Senha precisa ser redefinida':
      case 'Modal is transitioning':
        return false;
    }

    return true;
  }

  private knownError(error) {
    if (typeof error !== 'string') {
      return false;

      // Quando usuário tenta logar mais ainda não confirmou telefone e/ou email
    } else if (error.indexOf('ainda não confirmou') > -1) {
      return true;

      // Quando usuário possui mais de uma conta atrelada ao email
    } else if (error.indexOf('"accounts":[{"') > -1) {
      return true;

      // Erros do bootstrap ({Collapse, Tooltip, Accordion} is transitioning)
    } else {
      return error.indexOf(' is transitioning') > -1;
    }
  }
}

