import { Component } from '@angular/core';

@Component({
  selector: 'app-cadastrar',
  templateUrl: './cadastrar.component.html',
  styleUrls: ['./cadastrar.component.scss']
})
export class CadastrarComponent {
  secaoAtiva = 'selecao';
  segmento: string;

  constructor() { }

  voltar(): void {
    this.secaoAtiva  = 'selecao';
  }
}
