<header class="capa bg-cinza-logo" id="header-feed" [ngStyle]="{ 'backgroundImage': 'url(assets/imagens/pattern-treino.png)' }">
  <div class="nome m-t-10">
    <h4 class="feed-nome-usuario">{{userData?.FirstName}} {{userData?.LastName}}</h4>
    <h5 class="nome-conta">{{userData?.AccountName}}</h5>
  </div>
  <app-avatar *ngIf="userData?.UserTenantId" [pessoaId]="userData?.UserTenantId" class="avatar" [buttons]="false"></app-avatar>
</header>

<section class="content" id="content">
  <div class="bem-vindo-container">
    <p class="msg-bem-vindo">Bem
      <span *ngIf="userData?.Sex == 'F'">vinda,</span>
      <span *ngIf="userData?.Sex == 'M' || !userData?.Sex">vindo,</span>
      <strong>{{ userData?.FirstName || '' }}</strong>!
      <br/>
      <ng-container *ngIf="dataVencimentoAssinatura && isAcademia">
        <br>
        <span *ngIf="!assinaturaVencida">
          O vencimento da sua mensalidade na {{ nomeAcademia ? nomeAcademia : 'academia' }} {{ dataVencimentoAssinatura.em }} <b>{{ dataVencimentoAssinatura.data }}</b>
        </span>
        <span *ngIf="assinaturaVencida">
          <span>Sua mensalidade na {{ nomeAcademia ? nomeAcademia : 'academia' }} venceu em <b>{{ dataVencimentoAssinatura.data }}</b></span>
          <br />
          <br />
          <span>Por favor, entre em contato ou procure a recepção/administração para regularizar sua matrícula</span>
        </span>
        <br />
        <br />
      </ng-container>
      <span *ngIf="postagens && postagens.length">Veja as postagens recentes de sua academia</span>
      <span *ngIf="!postagens || !postagens.length">Sua academia não fez nenhuma postagem.</span>
    </p>
  </div>

  <div class="m-t-20 m-b-20" *ngIf="obtendoPostagens">
    <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
  </div>

  <div class="content-masonry" *ngIf="!obtendoPostagens">
    <div *ngFor="let card of feed; let i = index">
      <!-- Cards das postagens -->
      <app-card-postagem *ngIf="card.PostagemId" [postagem]="card"></app-card-postagem>

      <!-- Cards das aulas -->
      <!-- <app-card-aula *ngIf="card.AulaId" [aula]="card"></app-card-aula> -->
    </div>
  </div>
</section>

<footer class="fixed">
  <button class="btn-iniciar-treino shadow-up" mat-button mat-ripple [disabled]="!possuiTreino || carregandoTreino" [routerLink]="['../treino']">
    <ng-container *ngIf="carregandoTreino">
      Carregando treino ...
    </ng-container>

    <ng-container *ngIf="!carregandoTreino">
      {{ possuiTreino ? 'Começar o treino' : 'Você ainda não tem treino :( '}}
    </ng-container>
  </button>
</footer>

<a (click)="abrirChat()">
  <div class="floating-chat">
    <div class="inner-floating-chat">
    <span>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs><style>.a{fill:none;}.b{fill:#00c853;}</style></defs><path class="a" d="M0,0H24V24H0Z"/><path class="b" d="M4,4H20V16H5.17L4,17.17V4M4,2A2,2,0,0,0,2.01,4L2,22l4-4H20a2.006,2.006,0,0,0,2-2V4a2.006,2.006,0,0,0-2-2ZM6,12h8v2H6ZM6,9H18v2H6ZM6,6H18V8H6Z"/></svg>
    </span>
    <div *ngIf="novaMensagemChat" class="point"></div>
    </div>
  </div>
</a>
