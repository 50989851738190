<aside
  [ngClass]="{'sidebar': true, 'openOnHover': addClassbyService}">

  <form [formGroup]="form" id="timer-form" (ngSubmit)="salvarConfig(form)">
    <h4>Timer</h4>

    <div class="form-group">
      <label for="Tempo">Tempo</label>
      <input id="Tempo" formControlName="tempo" type="text" inputmode="numeric" placeholder="00:00" mask="00:00"  autocomplete="off">
      <div class="form-border"></div>
    </div>

    <div class="form-group form-checkbox">
      <input id="AlarmeAoTerminar" formControlName="alarmeAoTerminar" type="checkbox" hidden>
      <label for="AlarmeAoTerminar">Alarme ao terminar</label>
      <div class="form-border"></div>
    </div>

    <div class="form-group form-checkbox" *ngIf="!isMobileDevice()">
      <input id="TelaCheia" formControlName="telaCheia" type="checkbox" hidden>
      <label for="TelaCheia">Tela Cheia</label>
      <div class="form-border"></div>
    </div>

    <div class="btn-divider">
      <button type="submit" class="btn-sidebar">
        Salvar
      </button>
    </div>
  </form>

  <div class="sidebar-menu">
    <h4>Outros Timers</h4>

    <a [routerLink]="'/tabata'">Tabata</a>
    <a [routerLink]="'/timer'">Timer</a>
  </div>
</aside>

<section class="content">
  <h1 class="timer">
    <span id="timer-minutes" class="minutes">{{ (timerValues?.minutes || 0) | number: '2.' }}</span>
    <span class="separator">:</span>
    <span id="timer-seconds" class="seconds">{{ (timerValues?.seconds || 0) | number: '2.' }}</span>
    <span class="separator">.</span>
    <span id="timer-miliseconds" class="miliseconds">{{ timerValues?.secondTenths || 0 }}</span>
  </h1>

  <div class="action">
    <button *ngIf="!isRunning" id="cronometro-start" type="button" class="btn-timers btn-timers-primary" (click)="start()">
      Iniciar
    </button>

    <button *ngIf="isRunning" id="cronometro-pause" type="button" class="btn-timers btn-timers-ghost" (click)="pause()">
      Pausar
    </button>

    <button id="cronometro-reset" type="button" class="btn-timers btn-timers-ghost" (click)="reset()">
      Zerar
    </button>
  </div>
  <app-buttonsidebar (click)="abrirSideBar()" [value]="addClassbyService"></app-buttonsidebar>
</section>
<app-alert #alert></app-alert>
