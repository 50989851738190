<ng-container *ngIf="data && data.VrOriginal !== data.VrTotalRecebido && !data.Recebimentoid">
  <div class="wrapper" *ngIf="data.TipoProveniente === 'ASSINATURA'">
    <div class="title-wrapper">
      <h5 class="title">{{ data.DescricaoProveniente }}</h5>
    </div>
    <p class="vencimento"> Proveniente de: <strong> Assinatura </strong> </p>
    <p class="vencimento"> Vencimento: <strong> {{ data.DataVencimento | date : 'dd/MM/YYYY'}} </strong> </p>
    <div *ngIf="!data.PeriodoInicio || !data.PeriodoFinal"> <p> Proveniente de Assinatura </p></div>
    <div *ngIf="data.PeriodoInicio && data.PeriodoFinal">
      <p> Cobrança pelo uso </p>
      <p> de: <strong> {{ data.PeriodoInicio | date : 'dd/MM/YYYY'}} </strong> até <strong> {{ data.PeriodoFinal | date : 'dd/MM/YYYY'}} </strong></p>
    </div>
    <div class="pagamento-pagar">
      <h5>{{data.VrOriginal | moeda }}</h5>
      <button class="btn-pagamento" *ngIf="!pagamentoAgendado && permitirPagarComPaguela && permitirTransacoesPaguela" (click)="abrirMeiosPagamento(data)"><h6>Pagar</h6></button>
      <button *ngIf="pagamentoAgendado" [disabled]="true" class="btn-pagamento disabled"><h6>Agendado</h6></button>
    </div>
  </div>
  <div class="wrapper vendas" *ngIf="data.TipoProveniente === 'VENDA' || data.TipoProveniente === 'SERVICO'">
    <div class="title-wrapper">
      <h5 class="title">{{ data.DescricaoProveniente }}</h5>
    </div>
    <p class="vencimento"> Proveniente de: <strong>{{ data.TipoProveniente === 'VENDA' ? 'Venda' : 'Serviço'}}</strong> </p>
    <p class="vencimento"> Vencimento: <strong>{{ data.DataVencimento | date : 'dd/MM/YYYY'}}</strong> </p>
    <div class="pagamento-pagar">
      <h5>{{data.VrOriginal | moeda }}</h5>
      <button class="btn-pagamento" *ngIf="permitirTransacoesPaguela && permitirPagarComPaguela" (click)="abrirMeiosPagamento(data)"><h6>Pagar</h6></button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="data && data.Recebimentoid">
  <div class="wrapper" *ngIf="data.TipoProveniente === 'ASSINATURA'">
    <div class="title-wrapper">
      <h5 class="title">{{ data.DescricaoProveniente }}</h5>
    </div>
    <p class="vencimento"> Proveniente de: <strong> Assinatura </strong> </p>
    <p class="vencimento"> Vencimento: <strong> {{ data.DataVencimento | date : 'dd/MM/YYYY'}} </strong> </p>
    <p class="vencimento"> Pagamento efetuado em: <strong> {{ data.DataCadastro | date : 'dd/MM/YYYY'}} </strong> </p>
    <div *ngIf="data.PeriodoInicio && data.PeriodoFinal">
      <p> Cobrança pelo uso </p>
      <p> de: <strong> {{ data.PeriodoInicio | date : 'dd/MM/YYYY'}} </strong> até <strong> {{ data.PeriodoFinal | date : 'dd/MM/YYYY'}} </strong></p>
    </div>
    <h5> {{data.VrRecebido | moeda }} </h5>
  </div>
  <div class="wrapper vendas" *ngIf="data.TipoProveniente === 'VENDA' || data.TipoProveniente === 'SERVICO'">
    <div class="title-wrapper">
      <h5 class="title">{{ data.DescricaoProveniente }}</h5>
    </div>
    <p class="vencimento"> Proveniente de: <strong>{{ data.TipoProveniente === 'VENDA' ? 'Mercadorias' : 'Serviço'}}</strong> </p>
    <p class="vencimento"> Vencimento: <strong> {{ data.DataVencimento | date : 'dd/MM/YYYY'}} </strong> </p>
    <p class="vencimento"> Pagamento efetuado em: <strong> {{ data.DataCadastro | date : 'dd/MM/YYYY'}} </strong> </p>
    <h5> {{data.VrRecebido | moeda }} </h5>
  </div>
</ng-container>
