import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'apenasNumeros',
  pure: false
})
export class ApenasNumerosPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) { return; }
    const numb = value.match(/\d/g);
    return numb.join('');
  }
}
