import { Subject } from 'rxjs/Subject';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, from as fromPromise, forkJoin } from 'rxjs';
import { take, switchMap  } from 'rxjs/operators';

declare var window: any;

@Injectable()
export class SqliteService {
  db: any = null;
  dbStream = new ReplaySubject(1);

  constructor() {
    const self = this;

    document.addEventListener('deviceready', function() {
      window.sqlitePlugin.openDatabase({
        name: 'treino.db',
        location: 'default'
      }, (db) => {
        self.db = db;
        self.dbStream.next(true);
      });
    });
  }

  get(key) {
    const data$ = new Subject();

    this.dbStream.pipe(switchMap(() => fromPromise(
      this.db.transaction( (tx) => {
        tx.executeSql('CREATE TABLE IF NOT EXISTS ? (data)', [key]);
        tx.executeSql('SELECT data from ?', [key]);
      }, (error) => {
        console.log('Transaction ERROR: ' + error.message);
        data$.error(error);
        data$.complete();
      }, (data) => {
        alert('GET' + key);
        data$.next(JSON.parse(data));
        data$.complete();
      })
    )))
      .subscribe();

    return data$;
  }

  set(key, obj) {
    const data$ = new Subject();

    this.dbStream.pipe(switchMap(() => fromPromise(
      this.db.transaction( (tx) => {
        tx.executeSql('CREATE TABLE IF NOT EXISTS ? (data)', [key]);
        tx.executeSql('INSERT INTO ? VALUES (?)', [key, JSON.stringify(obj)]);
      }, (error) => {
        console.log('Transaction ERROR: ' + error.message);
        data$.error(error);
        data$.complete();
      }, (data) => {
        alert('SET' + key);
        data$.next(data);
        data$.complete();
      })
    )))
      .subscribe();

    return data$;
  }

  remove(key) {
    const data$ = new Subject();

    this.dbStream.pipe(switchMap(() => fromPromise(
      this.db.transaction( (tx) => {
        tx.executeSql('CREATE TABLE IF NOT EXISTS ? (data)', [key]);
        tx.executeSql('DELETE FROM ?', [key]);
      }, (error) => {
        console.log('Transaction ERROR: ' + error.message);
        data$.error(error);
        data$.complete();
      }, (data) => {
        alert('REMOVE' + key);
        data$.next(data);
        data$.complete();
      })
    )))
      .subscribe();

    return data$;
  }

  clear() {
    const keys = Object.keys(['fichaAtiva', 'licenca', 'userData', 'postagens', 'aulas']);
    const arr = [];

    keys.forEach(key => {
      arr.push(this.remove(key));
    });

    return forkJoin(arr);
  }
}
