import { Component, OnInit } from '@angular/core';
import {ReservasService} from '../../../shared/services/reservas.service';
import {ActivatedRoute, Params} from '@angular/router';
import * as moment from 'moment';
import {PerfilService} from '../../../shared/services/perfil.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-participantes-turma',
  templateUrl: './participantes-turma.component.html',
  styleUrls: ['./participantes-turma.component.scss']
})
export class ParticipantesTurmaComponent implements OnInit {
  participantes = [];
  loading = false;
  turma = {
    descricao: null,
    dataRealizacao: null
  };
  professores = [];
  noCache = new Date().getTime();
  reverse = false;
  public unsubscriber = new Subject();

  constructor(
    private reservaService: ReservasService,
    private route: ActivatedRoute,
    private perfilService: PerfilService,
  ) {
    this.loading = true;
    this.route.params.subscribe((params: Params) => {
      this.obterParticipantes(params['turmaId']);
    });
  }

  ngOnInit() {
  }

  obterParticipantes(turmaId) {
    this.reservaService.obterParticipantesTurmaPorId(turmaId).subscribe((data) => {

      this.turma = {
        descricao: data.Descricao,
        dataRealizacao: moment(data.dataRealizacao).format("DD/MM/YYYY HH:mm"),
      };

      for (const professor of data.Professores) {
        this.professores.push(professor);
      }

      for (const pessoa of data.Reservas) {
        this.participantes.push({
          ...pessoa,
            showAvatar: false
        });
      }

      if (this.participantes) {
        this.participantes.forEach((participante) => {
          this.perfilService.obterAvatarTreinoPorPessoaId(participante.PessoaId)
          .subscribe((data) => {
            if (data[0]?.File?.Url) {
             participante['AvatarUrl'] = data[0].File.Url
            } else {
              participante && participante?.Sexo === 'F'? participante['AvatarUrl'] = 'assets/imagens/avatar-f.jpg' : participante['AvatarUrl'] = 'assets/imagens/avatar-m.jpg';
            }
          })
        })
      }
      this.loading = false;
    });
  }

  showAvatar(pessoa) {
  pessoa.showAvatar = true;
  }

  changeOrder() {
    this.reverse = !this.reverse;
  }

}
