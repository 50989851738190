import { Component, OnInit, OnDestroy } from '@angular/core';

import { EventEmitterService } from './../../shared/services/event-emitter.service';



@Component({
  selector: 'app-timers-core',
  templateUrl: 'core.component.html',
  styleUrls: ['core.component.scss']
})
export class TimersCoreComponent implements OnInit, OnDestroy {
  backgroundClass: string;
  backgroundAnimation: string;

  constructor() { }

  ngOnInit() {
    EventEmitterService
      .get('background-start')
      .subscribe((totalSeconds) => this.backgroundStart(totalSeconds));

    EventEmitterService
      .get('background-show')
      .subscribe(() => this.backgroundShow());

    EventEmitterService
      .get('background-end')
      .subscribe(() => this.backgroundEnd());

    EventEmitterService
      .get('background-pause')
      .subscribe(() => this.backgroundPause());

    EventEmitterService
      .get('background-resume')
      .subscribe((totalSeconds) => this.backgroundResume(totalSeconds));

  }

  ngOnDestroy() {
    this.backgroundEnd();
  }

  backgroundShow = () => {
    this.backgroundClass = 'active';
  };

  backgroundStart = (tempo) => {
    this.backgroundShow();
    this.backgroundClass = 'active';
    this.backgroundAnimation = `background ${tempo + 0.2}s normal linear forwards` ;
  };

  backgroundResume = (tempo) => {
    this.backgroundShow();
    this.backgroundClass = 'active';
    this.backgroundAnimation = `background ${tempo + 0.5}s normal linear forwards`;
  };

  backgroundEnd = () => {
    this.backgroundClass = '';
    this.backgroundAnimation = 'none';
    // macete pro navegador renderizar a animação do background
    // a linha abaixo serve para o linter não entender como erro
    // tslint:disable-next-line:no-unused-expression
    // void (<any> document.querySelector('.background')).offsetWidth;
  };

  backgroundPause = () => {
    this.backgroundClass = 'active paused';
  };


}
