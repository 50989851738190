import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { CustomValidators } from '../../shared/validators/custom-validators';

import { LoginService } from '../../shared/services/login.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { TransferidorDeDadosService } from '../../shared/services/transferidor-de-dados-service';

import { Md5 } from 'ts-md5/dist/md5';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-criar-senha',
  templateUrl: './criar-senha.component.html',
  styleUrls: ['./criar-senha.component.scss']
})
export class CriarSenhaComponent implements OnInit, OnDestroy {
  nome = '';
  erro: string;
  email: string;
  carregando = false;
  emailConfirmado = false;

  error: boolean;
  loading: boolean;
  public unsubscriber = new Subject<void>();
  form: UntypedFormGroup;
  private code: string;


  submitted = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private errorHandlerService: ErrorHandlerService,
    private transferidorDeDadosService: TransferidorDeDadosService,
  ) { }

  ngOnInit(): void {
    this.montarFormulario();
    this.obterParametros();
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  montarFormulario(): void {
    this.form = this.fb.group({
      Email: [ null, [ Validators.required, Validators.email ] ],
      NovaSenha: [ null, [ Validators.required ] ],
      TermsAccepted: [false, [Validators.requiredTrue]],
      PrivacyAccepted: [false, [Validators.requiredTrue]],
      NovaSenhaConfirmacao: [ null, [ Validators.required ] ],
    }, {
      validator: CustomValidators.matchPasswordNew,
    });
  }

  checarStatusEmail() {
    this.emailConfirmado = false;
    this.loading = true;
    this.loginService.checarStatusEmail(this.email).pipe(takeUntil(this.unsubscriber)).subscribe((response: any) => {
      this.error = false;
      this.loading = false;
      if (response && !response.EmailConfirmed ) {
        this.confirmarEmail();
        return;
      }
      if (response && response.EmailConfirmed && response.HasPassword) {
        this.router.navigate(['/login']).then();
        return;
      }
      this.emailConfirmado = true;
    }, (error) => {
      this.error = true;
      this.loading = false;
      this.erro = this.errorHandlerService.getFirstValidationMessage(error);
    });
  }

  confirmarEmail() {
    this.loading = true;
    const dados = {
      Email: this.email,
      Code: this.code
    };

    this.loginService.confirmarEmailNovoUsuario(dados).pipe(takeUntil(this.unsubscriber)).subscribe(() => {
      this.error = false;
      this.loading = false;
      this.emailConfirmado = true;

    }, (eroor) => {
      this.error = true;
      this.loading = false;
      this.erro = this.errorHandlerService.getFirstValidationMessage(eroor);
    });
  }

  obterParametros(): void {
    this.route.queryParams.subscribe(values => {
      const token = JSON.parse(atob(values.token));
      if (token && token.Email) {
        this.form.get('Email').setValue(token.Email);
        this.email = token.Email;
        this.code = token.Code;
        this.checarStatusEmail();
        return;
      }
      this.erro = 'Ocorreu um Erro! Tente novamente mais tarde.';
    });
  }


  submit({ value, valid }): void {
    this.submitted = true;

    if (!valid) { return; }

    this.erro = null;
    this.carregando = true;

    const data = {
      Email: value.Email,
      Password: Md5.hashStr(value.Email + value.NovaSenhaConfirmacao),
    };

    this.loginService.criarSenha(data).subscribe(() => {
      this.login();
      this.carregando = false;
    }, (error) => {
      console.error(error);
      this.carregando = false;
      this.erro = this.errorHandlerService.getFirstValidationMessage(error);
    });
  }

  login(): void {
    const loginData: any = {
      AppSource: 'treino,actuar,facilfit',
      Email: this.email.trim(),
      Password: <any>Md5.hashStr(this.email + this.form.get('NovaSenha').value),
    };

    this.loginService.login(loginData).subscribe(response => {
      if (response && response.phoneConfirmed === false) {
        this.salvarDadosLogin();
        this.router.navigate(['confirmar-telefone', response.userId]).then();
        return;
      }

      if (response && response.emailConfirmed === false) {
        this.salvarDadosLogin();
        this.router.navigate(['confirmar-email', loginData.userName, response.userId]).then();
        return;
      }

      this.router.navigate(['/painel']).then();
    }, (error) => {
      this.carregando = false;
      this.erro = this.errorHandlerService.getFirstValidationMessage(error);
    });
  }

  salvarDadosLogin(): void {
    this.transferidorDeDadosService.adicionarDado('dadosLogin', { email: this.email, senha: this.form.get('NovaSenha').value })
  }
}
