<div class="card-feed-component">
  <mat-card class="mb-20">
    <img *ngIf="postagem?.PictureFile && postagem?.PictureFile?.Url" mat-card-image [src]="postagem.PictureFile?.Url" alt="imagem-postagem">

    <mat-card-title *ngIf="postagem.Titulo">{{postagem.Titulo}}</mat-card-title>

    <mat-card-content *ngIf="postagem.Texto || postagem.DataInicioEvento">
      <p style="word-break: break-word;" [class.mb-0]="postagem.UrlConteudo">{{postagem.Texto}}</p>
      <p style="word-break: break-word;"><a (click)="abrirLink(postagem.UrlConteudo)">{{postagem.UrlConteudo}}</a></p>
      <div *ngIf="postagem.DataInicioEvento" class="event">
        <span class="date">
          <mat-icon>event</mat-icon>
          <span *ngIf="postagem.DataInicioEvento" class="desc">Início:</span>
          <strong>{{postagem.DataInicioEvento | date: 'dd/MM/yyyy'}}</strong>
          <span class="hora" *ngIf="postagem.DataInicioEvento.indexOf('T') > -1">
            <mat-icon>alarm</mat-icon>
            {{postagem.DataInicioEvento | date: 'HH:mm'}}h
          </span>
        </span>

        <span class="date" *ngIf="postagem.DataFimEvento">
          <mat-icon>event</mat-icon>
          <span class="desc">Final:</span>
          <strong>{{postagem.DataFimEvento | date: 'dd/MM/yyyy'}}</strong>
          <span class="hora" *ngIf="postagem.DataFimEvento.indexOf('T') > -1">
            <mat-icon>alarm</mat-icon>
            {{postagem.DataFimEvento | date: 'HH:mm'}}h
          </span>
        </span>
      </div>
    </mat-card-content>

    <mat-card-actions *ngIf="postagem.DataInicioEvento">
      <button mat-button appcalendarioMobile class="btn"
        [attr.data-initdate]="postagem.DataInicioEvento"
        [attr.data-enddate]="postagem.DataFimEvento"
        [attr.data-local]="postagem.Local"
        [attr.data-titulo]="postagem.Titulo">
        SALVAR NA AGENDA
      </button>
    </mat-card-actions>
  </mat-card>
</div>
