import { Injectable } from '@angular/core';
import {Observable, Observer, of} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CachedHttpClient
{
  cachedItems: any[] = [];

  constructor(private http: HttpClient) {

  }

  public wipeCache(): Observable<boolean> {
    return new Observable( (observer: Observer<boolean>) => {
        this.cachedItems = [];
        observer.next(true);
      observer.complete();
    });
  }

  getCached<T>(url: string): Observable<T> {
    if (this.cachedItems[url])
    {
      return of(this.cachedItems[url] as T);
    }

    return this.http.get<T>(url, {
      withCredentials: true
    }).pipe(map((item: T) => {
      this.cachedItems[url] = item;
      return item;
    }));
  }
}
