import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'horaminuto'
})
export class HoraMinutoPipe implements PipeTransform {
  transform(value: string): string {
    return moment(value).utc(false).locale('pt-br').format('HH:mm');
  }
}
