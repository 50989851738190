import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CoreComponent } from './core/core.component';
import { _401Component } from './errors/401/401.component';
import { _404Component } from './errors/404/404.component';
import { AuthGuard } from './guards/auth.guard';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { LoginService } from './shared/services/login.service';

const AppRoutes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '401', component: _401Component },
  { path: '404', component: _404Component },
  { path: 'panel', component: CoreComponent },
  { path: '**', redirectTo: '404', pathMatch: 'full' },
];

export const AuthProviders = [
  AuthGuard,
  CanDeactivateGuard,
  LoginService
];

export const AppRoutingProviders: any[] = [
  AuthProviders
];

export const AppRouting: ModuleWithProviders<RouterModule> = RouterModule.forRoot(AppRoutes, {
  useHash: true
});
