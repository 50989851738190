import { Component, Input, OnInit, OnDestroy, AfterViewInit, AfterViewChecked } from '@angular/core';
import { MatDialog, } from '@angular/material/dialog';
import * as $ from 'jquery';
import { EventEmitterService } from '../../../../shared/services/event-emitter.service';

@Component({
  selector: 'app-avatar-crop',
  templateUrl: './avatar-crop.component.html',
  styleUrls: ['./avatar-crop.component.scss']
})
export class AvatarCropComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() objeto;

  cropperReady: boolean;
  uploading: boolean;
  croppedImage: any;
  image: any;

  avatarUrl;

  constructor() { }
  ngOnDestroy(): void {
  }

  ngOnInit() {
    EventEmitterService.get('alterar-avatar').subscribe((data) => {
      this.fileChangeListener(data);
    });
  }

  ngAfterViewInit() {

  }

  fileChangeListener($event): void {
    this.uploading = true;
    this.image = $event;
    this.uploading = false;
  }

  imageCropped(image: any) {
    this.croppedImage = { imagem: image };
  }

  imageLoaded() {
    this.cropperReady = true;
  }

  imageLoadFailed() {
    console.log('Load failed');
  }
}
