<div class="dark-bg">
	<section>
		<img src="assets/imagens/logo-invertida.png" class="logo-sobre">
		<div class="sobre-cell sobre-cell--12-col">

      <!-- DICAS -->
	<a style="text-decoration:none" [routerLink]="['/politica-privacidade']">
	 	 	<div appmdlAccordion class="sobre-accordion" >
				<div class="sobre-navigation__link sobre-accordion__button">
					<i class="material-icons">
						&#xE315;
					</i> Política de privacidade
				</div>
       		</div>
		</a>

      <!-- TERMOS -->
			<!-- <div appmdlAccordion class="sobre-accordion">
				<div class="sobre-accordion__button">
					<i class="material-icons mdl-accordion__icon mdl-animation--default">

          </i> Termos
				</div>
				<div class="mdl-accordion__content-wrapper">
					<div class="mdl-accordion__content mdl-animation--default">
						<div class="mdl-navigation__link">Aqui ficarão os termos</div>
					</div>
				</div>
      </div> -->

      <!-- FALE CONOSCO -->
			<!-- <div appmdlAccordion class="mdl-accordion">
				<div class="mdl-navigation__link mdl-accordion__button">
					<i class="material-icons mdl-accordion__icon mdl-animation--default">

          </i> Fale conosco
				</div>
				<div class="mdl-accordion__content-wrapper">
					<div class="mdl-accordion__content mdl-animation--default">
						<div class="mdl-navigation__link">Aqui ficará o fale conosco</div>
					</div>
				</div>
      </div> -->


			
    </div>

		<footer class="m-t-30 m-b-10 text-center">
      <img src="assets/imagens/logo-actuar-invertida@2x.png" class="footer-logo-invertida">
		</footer>
	</section>
</div>