import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitTo'
})
export class LimitToPipe implements PipeTransform {

  transform(value: string, args: any): string {
    if (!value) {
      return;
    }

    if (args === undefined || value.length <= args) {
      return value;
    } else {
      return value.substring(0, args - 3) + '...';
    }
  }
}
