import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moeda',
  pure: true
})
export class MoedaPipe implements PipeTransform {

  transform(value: number | string): string {

    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });

    return formatter.format(Number(value));
  }

}
