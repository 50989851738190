import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '../../../environments/environment';
import { LoginService } from './login.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class FrequenciasService {
  private pessoaId: string;

  constructor(private http: HttpClient,
              private loginService: LoginService) {
  }


  public async obterFrequencias(ano): Promise<Observable< any >> {
    const session = await this.loginService.getSessionData().pipe(take(1)).toPromise();
    const orderby = `DataEntrada desc,DataSaida desc`;
    const skip = `10`;
    const top = `90`;
    const select = `DataEntrada,HoraEntrada,DataSaida,HoraSaida,AcessoId,PessoaId`;
    const datefilter = `((DataEntrada ge ${ano}-01-01T00:00:00Z) and (DataEntrada le ${ano}-12-31T23:59:59.999Z))`;

    return this.http.get(`${API.odata}/Acessos?$orderby=${orderby}&$select=${select}&$filter=PessoaId eq ${session.UserTenantId} and ${datefilter}`, {
      withCredentials: true
    });
  }

}
