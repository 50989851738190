import { Component, OnInit } from '@angular/core';
import { AlertVersionService } from '../../services/alert-version.service';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-alert-version',
  templateUrl: './alert-version.component.html',
  styleUrls: ['./alert-version.component.scss'],
})

export class AlertVersionComponent implements OnInit {
  isOpened = false;
  forceUserUpdate = false;

  constructor(
    private alertVersionService: AlertVersionService
  ) { }

  ngOnInit() {
    this.verifyVersion();
  }


  close() {
    this.isOpened = !this.isOpened;
  }

  async navigateToAppStore(){
    if (!Capacitor.isNativePlatform) {
      return;
    }

    if (Capacitor.getPlatform() === 'ios') {

      try {
        await Browser.open({url : 'itms://itunes.apple.com/app/id1173571338'});
      }

      catch {
        await Browser.open({url : 'https://apps.apple.com/br/app/trei-no/id1173571338?itsct=apps_box_link&itscg=30200'});
      }
    }

    if (Capacitor.getPlatform() === 'android') {

      try {
        await Browser.open({url: 'market://details?id=trei.no'});
      }

      catch {
        await Browser.open({url : 'https://play.google.com/store/apps/details?id=trei.no&hl=pt'});
      }
    }
  }

  verifyVersion() {
    this.alertVersionService.verifyVersion(Capacitor.getPlatform()).subscribe((result) => {
      if (result && result.isDeprecatedVersion ) {
        this.isOpened = !this.isOpened;
      }
      if (result.forceUpdate) {
        this.forceUserUpdate = true;
      }
    });
  }

}


