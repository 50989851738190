import { Pipe, Injectable, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy',
  pure: false
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], filter: Object): any {
      if (!items || !filter) {
          return items;
      }
      return items.filter(item => String(item[Object.keys(filter)[0]]).includes(filter[Object.keys(filter)[0]]));
  }
}