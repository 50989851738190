import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { LoginService } from '../../../shared/services/login.service';
import { DevicesService } from '../../../shared/services/devices.service';
import { SweetalertService } from '../../../shared/services/sweetalert.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';

import { CustomValidators } from '../../../shared/validators/custom-validators';

import { Md5 } from 'ts-md5/dist/md5';

declare var $: any;

@Component({
  selector: 'app-cadastrar-profissional',
  templateUrl: './cadastrar-profissional.component.html',
  styleUrls: ['./cadastrar-profissional.component.scss']
})
export class CadastrarProfissionalComponent implements OnInit, OnDestroy {
  submitted: boolean;

  @Input() segmento: string;
  @Output() voltar = new EventEmitter();

  erro: any;
  form: UntypedFormGroup;
  carregando: boolean;
  assistirVideo = true;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private loginService: LoginService,
    private devicesService: DevicesService,
    private sweetAlertService: SweetalertService,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      Segment: [ this.segmento],
      LastName: [ null, [ Validators.required ] ],
      FirstName: [ null, [ Validators.required ] ],
      TermsAccepted: [ null, [ Validators.required ] ],
      ConfirmPassword: [ null, [ Validators.required ] ],
      PrivacyAccepted: [false, [Validators.requiredTrue]],
      Email: [ null, [ Validators.required, Validators.email ] ],
      Password: [ null, [ Validators.required, Validators.minLength(6)] ],
    }, {
      validator: CustomValidators.matchPasswordOld,
    });

    $('.common-container').css('flex', '0 1 800px');

    if (this.devicesService.isMobile()) {
      $('.common-container').css('top', '-30px');
    }
  }

  ngOnDestroy(): void {
    $('.common-container').css('flex', '0 1 400px');
    $('.common-container').css('top', 'auto');
  }

  _voltar(): void {
    this.voltar.emit();
  }

  fazerCadastro(): void {
    this.assistirVideo = false;
    $('.common-container').css('flex', '0 1 400px');
    $('.common-container').css('top', 'auto');
  }

  submit({ value, valid }): void {
    this.submitted = true;

    if (!valid) { return; }
    this.carregando = true;

    value['Email'] = value['Email'].trim();
    value['Password'] = Md5.hashStr(value['Email'] + value['Password']);
    value['ConfirmPassword'] = Md5.hashStr(value['Email'] + value['ConfirmPassword']);

    this.loginService.criarConta(value).subscribe(response => {
      const data: any = {
        Module: 'Treino',
        UserId: response.Id,
      };

      this.loginService.enviarEmailConfirmacao(data).subscribe(() => {
        this.erro = null;
        this.sweetAlertService.success('Sua conta foi criada com sucesso! Enviamos um email de confirmação.').subscribe(() => {
          this.irParaConfirmarEmail(value.Email, response.Id);
        });
        this.carregando = false;
      }, (error) => {
        this.erro = this.errorHandlerService.getFirstValidationMessage(error);
      });
    }, (error)  => {
      this.erro = this.errorHandlerService.getFirstValidationMessage(error);
    });
  }

  irParaConfirmarEmail(email: string, id: string): void {
    this.router.navigate(['/confirmar-email/', email, id]).then();
  }
}
