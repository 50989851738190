<div class="dark-bg">
  <div class="container-messages" id="container-messages">
    <div class="loading" *ngIf="carregando">
      <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
    </div>

    <ng-container *ngFor="let mensagem of messages; trackBy: trackByFn">
      <div *ngIf="mensagem.isData" class="mensagem-data">
        <span>{{ obterData(mensagem) }}</span>
      </div>
      <div *ngIf="mensagem.isNaoLida" [class.show-message]="mensagem.isNaoLida && !mensagem.isOculto" [class.hide-message]="mensagem.isOculto" class="mensagem-nao-lida">
        <span class="linha-mensagem-nao-lida"></span>
        <span>Mensagens não lidas</span>
      </div>

      <div class="container" [class]="isTenant(mensagem.SenderId) ? 'mensagem-direita' : 'mensagem-esquerda'">
        <span [class.min-height]="!mensagem.isImageLoaded && mensagem.isImage" [class.no-text]="!mensagem.Text || mensagem.Text === '1 arquivo recebido.'" [class.full-content]="mensagem.isImage || mensagem.isVideo" class="conteudo-mensagem">
          <img [class.hidden]="!mensagem.isImageLoaded" [class.loaded]="mensagem.isImageLoaded" (click)="openMediaShadowPreview(mensagem)" (load)="imageLoaded(mensagem)" *ngIf="mensagem.isImage" [src]="mensagem?.File?.Url" />
          <div class="loadingImage" *ngIf="!mensagem.isImageLoaded && mensagem.isImage">
            <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
          </div>
          <video (click)="openMediaShadowPreview(mensagem)" *ngIf="mensagem.isVideo" [src]="mensagem?.File?.Url" muted>
            <source [src]="mensagem.File.Url" type="video/mp4" />
          </video>

          <div (click)="openMediaShadowPreview(mensagem)" *ngIf="mensagem.isVideo" class="wrap-play-icon">
            <span class="material-icons">play_arrow</span>
          </div>

          <span [class.message-content-with-file]="(mensagem.isImage || mensagem.isVideo) && mensagem.Text && mensagem.Text !== '1 arquivo recebido.'">{{ mensagem.Text === '1 arquivo recebido.' ? '' : mensagem.Text }}</span>
          <span class="hora-mensagem">{{ obterHora(mensagem.SendAt) }}</span>
        </span>
        <app-avatar *ngIf="mensagem.isAvatar" [pessoaId]="mensagem.SenderId"  [buttons]="false" [heigth]="36" [width]="36"></app-avatar>
      </div>
    </ng-container>
  </div>
</div>

<div id="file-chooser" class="choose-files">
  <div class="media-type" (click)="openMediaChooser('image/*')">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs><style>.a{fill:none;}.b{fill:#fff;}</style></defs><path class="a" d="M0,0H24V24H0Z"/><path class="b" d="M20,4H16.83L15,2H9L7.17,4H4A2.006,2.006,0,0,0,2,6V18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,14H4V6H8.05L9.88,4h4.24l1.83,2H20ZM12,7a5,5,0,1,0,5,5A5,5,0,0,0,12,7Zm0,8a3,3,0,1,1,3-3A3.009,3.009,0,0,1,12,15Z"/></svg>
    <span>Imagem</span>
  </div>
  <div class="media-type" (click)="openMediaChooser('video/*')">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs><style>.a{fill:none;}.b{fill:#fff;}</style></defs><path class="a" d="M0,0H24V24H0Z"/><path class="b" d="M15,8v8H5V8H15m1-2H4A1,1,0,0,0,3,7V17a1,1,0,0,0,1,1H16a1,1,0,0,0,1-1V13.5l4,4V6.5l-4,4V7A1,1,0,0,0,16,6Z"/></svg>
    <span>Vídeo</span>
  </div>
</div>

<footer class="fixed">
  <div class="send-message">
    <ng-container *ngIf="!sending">
      <input type="file" accept="image/*,video/*" id="input-media" (change)="onChangeMedia($event)" />
      <span class="new-file" (click)="toggleFileChooser()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path class="b" d="M16.5,6V17.5a4,4,0,0,1-8,0V5a2.5,2.5,0,0,1,5,0V15.5a1,1,0,0,1-2,0V6H10v9.5a2.5,2.5,0,0,0,5,0V5A4,4,0,0,0,7,5V17.5a5.5,5.5,0,0,0,11,0V6Z"/></svg>
      </span>
    </ng-container>
    <textarea [disabled]="sending" [placeholder]="!sending ? 'Digite uma mensagem...' : 'Enviando...'" name="mensagem" id="mensagem"></textarea>
    <div *ngIf="!sending" class="btn-submit" (click)="enviarMensagem()">
      <img src="assets/imagens/icone-enviar.svg">
    </div>
    <div *ngIf="sending" class="sending">
      <mat-progress-spinner mode="indeterminate" diameter="40"></mat-progress-spinner>
    </div>

  </div>
</footer>

<div class="full-media-preview animated" [class.fadeIn]="isConfirmMedia" [class.fadeOut]="!isConfirmMedia">
  <span class="close-media-preview" (click)="closeConfirmMedia()">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.295 12.295"><defs><clipPath ><path d="M12.295,9.835,8.6,6.147,12.295,2.46,9.835,0,6.147,3.689,2.458,0,0,2.46,3.688,6.147,0,9.835l2.458,2.46L6.147,8.606l3.688,3.689Z"/></clipPath></defs><g><path d="M12.295,1.229,11.065,0,6.147,4.918,1.229,0,0,1.229,4.918,6.147,0,11.065l1.229,1.229L6.147,7.377l4.918,4.918,1.229-1.229L7.377,6.147Z"/></g></svg>
  </span>
  <div class="container-img-preview">
    <img *ngIf="imageBase64" [src]="imageBase64" />
  </div>
  <video *ngIf="videoBase64" controls>
    <source [src]="videoBase64" type="video/mp4" />
  </video>
  <div class="footer-media-preview">
    <textarea placeholder="Digite uma mensagem..." id="text-media"></textarea>
    <div class="btn-submit" (click)="enviarMidia()">
      <img src="assets/imagens/icone-enviar.svg">
    </div>
  </div>
</div>

<div id="full-media-shadow-preview" class="full-media-shadow-preview animated" [class.fadeIn]="isPreviewMedia" [class.fadeOut]="!isPreviewMedia && !slideUp" [class.fadeOutUp]="slideUp">
  <span class="close-media-shadow-preview" (click)="closeMediaShadowPreview()">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.295 12.295"><defs><clipPath ><path d="M12.295,9.835,8.6,6.147,12.295,2.46,9.835,0,6.147,3.689,2.458,0,0,2.46,3.688,6.147,0,9.835l2.458,2.46L6.147,8.606l3.688,3.689Z"/></clipPath></defs><g><path d="M12.295,1.229,11.065,0,6.147,4.918,1.229,0,0,1.229,4.918,6.147,0,11.065l1.229,1.229L6.147,7.377l4.918,4.918,1.229-1.229L7.377,6.147Z"/></g></svg>
  </span>
  <img *ngIf="imageUrl" [src]="imageUrl" />
  <video *ngIf="videoUrl" controls>
    <source [src]="videoUrl" type="video/mp4" />
  </video>
</div>
