<div class="container-modal-instrucao open opened">
    <div class="background">
      <mat-icon class="close" (click)="fechar()">close</mat-icon>
    </div>
    <ng-container>
      <swiper
      class="swiper"
      [pagination]="true"
      [centeredSlides]="true"
        >
        <ng-template *ngIf="instrucao?.VideoFile" swiperSlide>
          <video class="instrucao-content" autoplay controls>
            <source [src]="instrucao.VideoFile.Url">
          </video>
        </ng-template>
        <ng-template *ngIf="animatedImages" swiperSlide>
          <img [src]="animatedImages">
        </ng-template>
        <ng-template *ngIf="youtubeEmbedUrl" swiperSlide>
          <iframe class="instrucao-content" [src]="youtubeEmbedUrl" frameborder="0" allowfullscreen
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
        </ng-template>
        <ng-template *ngIf="instrucao.GifFile" swiperSlide>
          <img class="instrucao-content" [src]="instrucao.GifFile?.Url" alt="instrucao-gif">
        </ng-template>
      </swiper>
    </ng-container>
</div>

