import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { API } from './../../../environments/environment';
import { Postagem } from './../../core/core.interfaces';
import { StorageService } from './storage.service';
import { LoginService } from './login.service';

@Injectable()
export class AulasService {
  aulasStream: ReplaySubject < any > = new ReplaySubject(1);

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private storageService: StorageService
  ) {}

  // Atualiza as aulas
  updateAulas(take: number, skip: number): void {
    this.http
      .get(API.base + '/Aulas/ObterTodasParaPessoa', {
        withCredentials: true
      })
      .subscribe((response: any) => {
        this.aulasStream.next(response);
        this.storageService.set('aulas', response);
      });
  }

  // Retorna todos as aulas vinculadas à conta
  getAulas(take: number, skip: number): ReplaySubject < Postagem[] > {
    if (navigator.onLine) {
      this.updateAulas(take, skip);
    }

    this.storageService.get('aulas')
      .subscribe(data => {
        this.aulasStream.next(data)
      });

    return this.aulasStream;
  }

  obterPorId(aulaId): Observable < Object > {
    return this.http
      .get(API.base + '/Aulas/ObterPorId?id=' + aulaId, {
        withCredentials: true
      })
  }

  reservar(aulaId: any): Observable < Object > {
    return this.http
      .put(API.base + '/Aulas/Reservar?aulaId=' + aulaId, null, {
        withCredentials: true
      })
  }

  desReservar(aulaId: any): Observable < Object > {
    return this.http
      .put(API.base + '/Aulas/Desreservar?aulaId=' + aulaId, null, {
        withCredentials: true
      })
  }
}
