import { AfterViewChecked, Directive, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

declare var $: any;

@Directive({
  selector: '[appmasonry]'
})
export class MasonryDirective implements AfterViewChecked {
  grid: any;
  timeout: any;

  constructor(private elRef: ElementRef, private router: Router) {
    if (window.outerWidth >= 767) {
      this.grid = $(this.elRef.nativeElement);
      this.grid.masonry({
        itemSelector: '.mdl-card, md-card',
        columnWidth: '.grid-sizer',
        fitWidth: true,
        gutter: 10,
        transitionDuration: 0
      });
    }
  }

  ngAfterViewChecked() {

    if (typeof this.grid === 'undefined') {
      return;
    }

    if (window.outerWidth >= 767) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.grid.masonry('reloadItems');
        this.grid.masonry('layout');
      }, 50);
    }
  }
}
