<mat-tab-group>
  <mat-tab label="Próximas cobranças">
    <section class="full-background">
      <section *ngIf="(carregandoContasReceber$ | async); else loading" class="cards-wrapper">
        <ng-container *ngIf="!hasContasReceber('assinatura') &&
         !hasContasReceber('servico') && !hasContasReceber('venda')
         then dontHaveData"></ng-container>
        <ng-container *ngIf="hasContasReceber('assinatura')">
          <app-card-pagamento [data]="contaReceber" *ngFor="let contaReceber of proximasCobrancas()"
                              (eventMeioPagamento)="abrirFormasPagamento($event)"
                              [permitirPagarComPaguela]="transacoesPermitidas"
                              [situacaoPaguela]="situacaoPaguela"
                              [pagamentoAgendado]="contaReceberAgendado(contaReceber)"
          ></app-card-pagamento>
        </ng-container>
      </section>
    </section>
  </mat-tab>
  <mat-tab label="Itens pagos">
    <section *ngIf="(carregandoRecebimentos$ | async); else loading">
      <ng-container *ngIf="(existeRecebimento('assinatura') || existeRecebimento('servico') || existeRecebimento('venda')); else dontHaveData"></ng-container>
      <ng-container *ngIf="recebimentos?.length > 0">
        <app-card-pagamento [data]="recebimento" *ngFor="let recebimento of recebimentos"></app-card-pagamento>
      </ng-container>
    </section>
  </mat-tab>
  <mat-tab label="Minha assinatura">
    <section *ngIf="(carregandoAssinaturaPessoa$ | async); else loading" class="minha-assinatura">
      <ng-container *ngIf="assinaturasAtuais.length > 0; else dontHaveData"></ng-container>
      <app-card-assinatura [assinaturasAtuais] ="assinaturasAtuais"
                           [vendasPaguela] = "vendasPaguela"
                           [contasReceber]="contasReceber"
                           (vincularCartao)="criarVenda($event, true, false)"
                           [situacaoPaguela]="situacaoPaguela"
                           [permitirPagarComPaguela]="transacoesPermitidas">
      </app-card-assinatura>
    </section>
  </mat-tab>
</mat-tab-group>

<section *ngIf="academiaPossuiPix" id="modal" [ngClass]="{'show': modalShow}">
  <div class="modalContent">
    <div class="modalHeader">
      <h4>Forma de pagamento</h4>
    </div>
    <div class="modalHeaderClose">
      <span class="close-modal" (click)="abrirFormasPagamento()">+</span>
    </div>
    <div class="modal_body">
      <button (click)="criarVenda(contaReceberContextoAtual, false, false)"><h6>Cartão de crédito</h6></button>
      <button (click)="criarVenda(contaReceberContextoAtual, false, true)"><h6>Pix</h6></button>
    </div>
  </div>
</section>

<section id="modal" [ngClass]="{'show': modalShowAlert}">
  <div class="modalContentAlert">
    <div class="modalHeaderClose">
      <span class="close-modal" (click)="modalShowAlert = false">+</span>
    </div>
    <h4>Cliente não possui endereço cadastrado</h4>
  </div>
</section>

<ng-template #dontHaveData>
  <article class="align-center">
    <span>Não há registros a serem exibidos.</span>
  </article>
</ng-template>

<ng-template #loading>
  <article class="align-center">
    <mat-progress-spinner mode="indeterminate" diameter="50" strokeWidth="2"></mat-progress-spinner>
  </article>
</ng-template>


