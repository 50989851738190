import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ChatService } from '../../shared/services/chat.service';
import { TreinoService } from '../../shared/services/treino.service';
import { EventEmitterService } from '../../shared/services/event-emitter.service';
import * as moment from 'moment';
import {GetMessages, IUploadMidia, MyChatInfo} from '../../shared/interfaces/chat.interface';
import {Messages} from './chat-extends.interface';
import {Capacitor} from '@capacitor/core';

declare let $: any;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewInit {
  tenantId = '';
  tenantIdLocal = '';

  messages: Messages[] = [];
  chatId = '';
  chatInfo = null;

  isConfirmMedia = false;
  isPreviewMedia = false;

  imageBase64 = null;
  videoBase64 = null;

  mediaFile = null;

  imageUrl = null;
  videoUrl = null;
  dateNow = new Date();

  slideUp = false;
  sending = false;
  carregando = false;

  constructor(
    private chatService: ChatService,
    private treinoService: TreinoService,
  ) { }

  ngOnInit(): void {


    this.carregando = true;
    const mensagensLocal = JSON.parse(this.chatService.obterMensagensLocal());
    const userData = JSON.parse(localStorage.getItem('treino/userData'));
    const userDataLocal = JSON.parse(localStorage.getItem('treino/userData'));
    this.tenantIdLocal = userDataLocal && userDataLocal.UserTenantId ? userDataLocal.UserTenantId : null;
    this.tenantId = userData && userData.UserTenantId ? userData.UserTenantId : null;

    if (mensagensLocal && mensagensLocal && mensagensLocal.length && this.tenantId) {
      this.messages = mensagensLocal;

      this.organizarMidia();
      this.organizarDatas();
      this.organizarAvatares();

      this.scrollChat();
    }


    this.chatService.getChatInfo().subscribe((data: MyChatInfo) => {
      this.chatId = data.ChatId;
      this.chatInfo = data;
      this.iniciarChat();
      this.getMessages(data.ChatId);
    }, (err) => {
      console.error(err);
      this.carregando = false;
    });

    document.addEventListener('click', (e: any) => {
      if (e && e.target && e.target.id === 'full-media-shadow-preview') {
        this.closeMediaShadowPreview();
      }
    });

    EventEmitterService.get('nova-mensagem-chat').subscribe((data) => {
      if (data) {
        this.adicionarNovaMensagem(data);
      }
    });

    this.onKeyboardChange();
  }

  ngAfterViewInit(): void {
    this.onResume();
    this.onSwipe();
  }

  onKeyboardChange(): void {
    window.addEventListener('keyboardDidHide', () => {
      this.scrollChat();
    });

    window.addEventListener('keyboardDidShow', () => {
      this.scrollChat();
    });
  }

  onResume(): void {
    window.addEventListener('resume', () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 0);
    });

    if (Capacitor.getPlatform() === 'web') {
      const mensagem = document.getElementById('mensagem');
      if (mensagem) {
        mensagem.focus();
      }
    }
  }

  imageLoaded(mensagem) {
    mensagem.isImageLoaded = true;
    this.scrollChat();
  }
  
  adicionarNovaMensagem(data): void {
    const message = this.messages.find((m) => m.MessageId === data.messageId);
    if (message) { return; }

    let foundMessage = false;
    this.messages.forEach((mensagem) => {
      if (mensagem.Text === data.text && mensagem.sending) {
        foundMessage = true;

        mensagem.sending = false;
        mensagem.Text = data.text;
        mensagem.Sender = data.sender;
        mensagem.SenderId = data.senderId;
        mensagem.reciver = data.reciver;
        mensagem.ReciverId = data.reciverId;
        mensagem.MessageId = data.messageId;
        mensagem.SendAt = data.sendAt;
        mensagem.readed = true;

        if (mensagem.File) {
          mensagem.File.Url = data?.file?.url;
          mensagem.File.FileName = data?.file?.fileName;
          mensagem.File.Data = data?.file?.data;
        }

        return;
      }
    });

    if (!foundMessage) {
      this.messages.push({
        Text: data.text,
        Sender: data.sender,
        SenderId: data.senderId,
        reciver: data.reciver,
        ReciverId: data.reciverId,
        MessageId: data.messageId,
        SendAt: data.sendAt,
        File: {
          Url: data?.file?.url,
          FileName: data?.file?.fileName,
          Data: data?.file?.data
        },
        readed: true,
      });
    }

    const mensagensLidas: GetMessages['MessageId'][] = [];

    this.messages.forEach((m) => {
      if (!m.readed) {
        mensagensLidas.push(m.MessageId);
      }
    });

    this.chatService.marcarMensagensComoLidas(mensagensLidas);

    this.organizarMidia();
    this.organizarDatas();
    this.organizarAvatares();
    this.marcarMensagensComoLidas();
    if (data.senderId !== this.tenantId) {
      EventEmitterService.get('mostrar-chat-header').emit({ pessoaId: data.senderId, nome: data.sender});
    }

    this.chatService.salvarMensagensLocal(this.messages);
    this.scrollChat();
  }

  getMessages(chatId: string): void {
    this.chatService.getMessages(chatId).subscribe((messages: [GetMessages]) => {
      const enviando = this.messages.filter(m => m.sending);

      if (!messages) {
        this.messages = [];
      } else {
        this.messages = messages;
      }

      this.messages.sort((a, b) => {
        if (!moment(a.SendAt).isBefore(b.SendAt)) {
          return 1;
        }
        if (moment(a.SendAt).isBefore(b.SendAt)) {
          return -1;
        }
        return 0;
      });

      let ultimaMensagemRecebida: GetMessages = null;

      this.messages.forEach((message) => {
        if (message.SenderId !== this.tenantId) {
          ultimaMensagemRecebida = message;
        }
      });

      if (enviando && enviando.length) {
        enviando.forEach((mensagem) => {
          this.chatService.messagesQueue.push({
            chatId,
            mensagem: mensagem.Text
          });

          this.messages.push(mensagem);
        });
      }

      this.chatService.enviarFila();

      this.organizarMidia();
      this.organizarDatas();
      this.organizarAvatares();
      this.organizarNaoLidas();

      if (this.messages && this.messages.length === 0) {
        this.treinoService
          .obterLicencaAcademia()
          .subscribe(response => {
            if (response) {
              console.log('response obterLicencaAcademia',response);
              if (!response.NomeEmpresaExibicao && !response.ImagemUrl) {
                console.log('mostrar-chat-header',response);
                console.log('mostrar-chat-header CHAT INFO',this.chatInfo);
                EventEmitterService.get('mostrar-chat-header').emit({
                  pessoaId: this.chatInfo.TenantId,
                  nome: this.chatInfo.Tenant
                });
              } else {

                console.log('mostrar-chat', response);
                EventEmitterService.get('mostrar-chat-header').emit({

                  url: response.ImagemUrl ? response.ImagemUrl : '/assets/imagens/academia.jpg',
                  nome: response.NomeEmpresaExibicao ? response.NomeEmpresaExibicao : this.chatInfo.Tenant
                });
              }
            }
          });
      } else if (ultimaMensagemRecebida) {
        EventEmitterService.get('mostrar-chat-header').emit({
          pessoaId: ultimaMensagemRecebida.SenderId,
          nome: ultimaMensagemRecebida.Sender
        });
      } else {
        EventEmitterService.get('mostrar-chat-header').emit({
          pessoaId: this.chatInfo.TenantId,
          nome: this.chatInfo.Tenant
        });
      }

      this.chatService.salvarMensagensLocal(this.messages);
      this.scrollChat();

      this.carregando = false;
    }, (err) => {
      console.error(err);
      this.carregando = false;
    });
  }

  ngOnDestroy(): void {
    EventEmitterService.get('esconder-chat-header').emit();
  }

  iniciarChat(): void {
    this.chatService.abrirConexao().then(() => {
      this.chatService.entrarNoChat(this.chatId);
    });
  }

  enviarMensagem(): void {
    const mensagem: any = document.getElementById('mensagem');
    const { value } = mensagem;
    if (!mensagem || !mensagem.value) { return; }

    mensagem.focus();

    this.messages.push({
      Text: value,
      Sender: null,
      SenderId: this.tenantId,
      reciver: null,
      ReciverId: null,
      MessageId: null,
      SendAt: new Date().toISOString(),
      sending: true,
      readed: true,
    });

    this.chatService.salvarMensagensLocal(this.messages);

    this.organizarMidia();
    this.organizarDatas();
    this.organizarAvatares();
    this.marcarMensagensComoLidas();

    this.esconderNaoLidas();

    this.chatService.enviarMensagem(this.chatId, mensagem.value);

    mensagem.value = '';

    this.scrollChat();
  }

  scrollChat(): void {
    setTimeout(() => {
      const element = document.getElementById('container-messages');
      if (element) {
        element.scrollTop = element.scrollHeight - element.clientHeight;
      }
    }, 100);
  }

  isTenant(pessoaId: string): boolean {
    return this.tenantIdLocal === pessoaId;
  }

  obterHora(data: string): string {
    return moment(data).format('HH:mm');
  }

  obterData(mensagem) {
    const hoje = moment();
    const ontem = moment().subtract(1, 'days');
    const datamensagem = moment(mensagem.sendAt);

    if (datamensagem.format('DD/MM/YYYY') === hoje.format('DD/MM/YYYY')) {
      return 'Hoje';
    }

    if (datamensagem.format('DD/MM/YYYY') === ontem.format('DD/MM/YYYY')) {
      return 'Ontem';
    }

    return moment(datamensagem).format('DD/MM/YYYY');
  }

  async organizarAvatares() {
    const arrKeys = this.messages.map(el => el.SenderId);
    const lastMessages = [];
    const uniqueIds = [...new Set(arrKeys)];
    uniqueIds.forEach((itr) => {
      lastMessages.push(this.messages[arrKeys.lastIndexOf(itr)]);
    });
    this.messages.forEach((itr) => {
      if (itr.isAvatar) {
        itr.isAvatar = false;
      }
    });
    let atualMessage;
    for await (const itr of lastMessages) {
      atualMessage = this.messages.find( message => message.MessageId === itr.MessageId );
      atualMessage.isAvatar = true;
    }
  }

  organizarDatas(): void {
    const messageDates = [];
    const indexIdsMessages = [];

    for (let i = 0; i < this.messages.length; i++) {
      if (indexIdsMessages.length === 0 || moment(messageDates[messageDates.length - 1])
        .format('DD/MM/YYYY') !== moment(this.messages[i].SendAt).format('DD/MM/YYYY')) {
        messageDates.push(this.messages[i].SendAt);
        indexIdsMessages.push(i);
      }
    }

    indexIdsMessages.forEach((i) => {
      this.messages[i].isData = true;
    });
  }

  trackByFn(index: number): number {
    return index;
  }

  organizarMidia(): void {
    const videoExtensions = ['mp4'];
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];

    for (let i = 0; i < this.messages.length; i++) {
      if (this.messages[i].File && this.messages[i].File.FileName ) {
        const split = this.messages[i].File.FileName.split('.');
        const extension = split[split.length - 1];

        if (videoExtensions.includes(extension)) {
          this.messages[i].isVideo = true;
        }

        if (imageExtensions.includes(extension)) {
          this.messages[i].isImage = true;
        }
      }

    }
  }

  public isImage(fileName: string): boolean {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const split = fileName.split('.');
    const extension = split[split.length - 1];

    if (imageExtensions.includes(extension)) {
      return true;
    }

    return false
  }

  toggleFileChooser() {
    const fileChooser = document.getElementById('file-chooser');

    if (!fileChooser) { return; }

    if (fileChooser) {
      const isOpen = fileChooser.classList.value.indexOf('slideUp') > -1;
      if (isOpen) {
        fileChooser.classList.remove('slideUp');
        fileChooser.classList.add('slideDown');
      } else {
        fileChooser.classList.remove('slideDown');
        fileChooser.classList.add('slideUp');
      }
    }
  }

  public openMediaChooser(mediaType: string): void {
    this.toggleFileChooser();
    const inputMedia = document.getElementById('input-media') as HTMLInputElement;
    inputMedia.accept = mediaType;
    inputMedia.click();
  }


  onChangeMedia(event: any) {
    const arquivo = event.target.files[0];

    this.mediaFile = arquivo;

    const reader = new FileReader();
    reader.readAsDataURL(arquivo);

    reader.onload = () => {
      this.openConfirmMedia();
      this.isImage(this.mediaFile.name) ? this.imageBase64 = reader.result : this.videoBase64 = reader.result;
    };

    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  openConfirmMedia(): void {
    this.isConfirmMedia = true;

    if (Capacitor.getPlatform() === 'web') {
      const input = document.getElementById('text-media');
      if (input) {
        setTimeout(() => {
          input.focus();
        }, 200);
      }
    }

  }

  closeConfirmMedia(): void {
    this.isConfirmMedia = false;

    const inputMedia = document.getElementById('input-media') as HTMLInputElement;

    inputMedia.value = null;

    if (Capacitor.getPlatform() === 'web') {
      const input = document.getElementById('mensagem');
      if (input) {
        setTimeout(() => {
          input.focus();
        }, 200);
      }
    }
  }

  enviarMidia() {
    this.sending = true;
    this.organizarDatas();
    this.organizarAvatares();
    this.marcarMensagensComoLidas();
    this.esconderNaoLidas();
    this.scrollChat();
    this.closeConfirmMedia();

    const textMedia = document.querySelector('#text-media') as HTMLInputElement;
  
    const uploadMidia: IUploadMidia = {
      ChatId: this.chatId,
      Text: textMedia?.value ? textMedia?.value : '1 arquivo recebido.',
      File: {
        FileName: this.mediaFile.name,
        Data: this.imageBase64 ? this.imageBase64.replace(/^data:[\w/]+;base64,/, '') : this.videoBase64.replace(/^data:[\w/]+;base64,/, ''),
        Url: null
      },
    }

    this.chatService.enviarArquivo(uploadMidia).subscribe(() => {
      this.mediaFile = null;
      this.imageBase64 = null;
      this.videoBase64 = null;
      textMedia.value = null;
      this.sending = false;
    }, error => {
      console.error(error);
      this.sending = false;
    });
  }

  openMediaShadowPreview(mensagem) {
    if (!mensagem || !mensagem.File || !mensagem.File) { return; }

    if (mensagem.isImage) {
      this.imageUrl = mensagem.File.Url;
    }

    if (mensagem.isVideo) {
      this.videoUrl = mensagem.File.Url;
    }

    this.slideUp = false;
    this.isPreviewMedia = true;
  }

  closeMediaShadowPreview() {
    this.isPreviewMedia = false;

    this.videoUrl = '';
    this.imageUrl = '';
  }

  onSwipe() {
    $('.full-media-shadow-preview').swipe( {
      swipe: (event, direction) => {
        if (direction === 'up') {
          this.isPreviewMedia = false;
          this.slideUp = true;
        }
      },
      excludedElements: 'button, input, select, textarea, .noSwipe'
    });
  }

  organizarNaoLidas() {
    for (let i = this.messages.length - 1; i >= 0; i--) {
      if (this.messages.length === 1 && !this.messages[i].readed && !this.messages[i].isNaoLida) {
        this.messages[i].isNaoLida = true;
        return;
      } else {
        this.messages[i].isNaoLida = false;
      }

      if (this.messages.length >= 2 && i > 0) {
        if (!this.messages[i].readed && this.messages[i - 1].readed && !this.messages[i].isNaoLida) {
          this.messages[i].isNaoLida = true;
          return;
        } else {
          this.messages[i].isNaoLida = false;
        }
      }
    }
  }

  marcarMensagensComoLidas() {
    const mensagensLidas = [];

    this.messages.forEach((m) => {
      if (!m.readed && m.MessageId) {
        m.readed = true;
        mensagensLidas.push(m.MessageId);
      }
    });

    setTimeout(() => {
      this.chatService.marcarMensagensComoLidas(mensagensLidas);
    }, 100);
  }

  esconderNaoLidas() {
    this.messages.forEach((message) => {
      if (message.isNaoLida) {
        message.isOculto = true;
      }
    });
  }
}
