import { Component, OnInit } from '@angular/core';
import { SoundsService } from './../../services/sounds.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  message = '';
  active = false;

  constructor(
    private soundsService: SoundsService
  ) { }

  open(message) {
    this.message = message;
    this.active = true;
  }

  close() {
    this.message = '';
    this.active = false;
  }

  alarme(sound?, title?, message?) {
    this.open('ACABOU');
    this.soundsService.playDone();
  }
}
