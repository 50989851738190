import { Directive, ElementRef, OnInit } from '@angular/core';

declare var $: any;
@Directive({
  selector: '[appmdlAccordion]'
})
export class MdlAccordionDirective implements OnInit {

  constructor(private elRef: ElementRef) {}

  ngOnInit() {
    const content = $(this.elRef.nativeElement).find('.sobre-accordion__content');
    content.css('display', 'none');
    content.css('margin-top', -content.height());
    setTimeout(() => {
      content.css('display', 'block');
    }, 1000);

    $(this.elRef.nativeElement).find('.sobre-accordion__button').on('click', () => {
      $(this.elRef.nativeElement).toggleClass('sobre-accordion--opened');
    });
  }
}
