export interface AvatarObject {
  AvatarAcesso: boolean;
  AvatarActuar: boolean;
  AvatarAfig: boolean;
  AvatarFacial: boolean;
  AvatarTreino: boolean;
  DataCadastro: string;
  DataModificacao: string | null;
  IsValid: boolean;
  ObjetoId: string;
  PessoaId: string;
  TemplateFacialId: string | null;
  ValidationResult: {
    Message: string | null;
    IsValid: boolean;
    Erros: [];
  };
}

export interface ProxyResponse {
  response: string;
}
