<header class="capa bg-cinza-logo">
  <div class="nome m-t-10">
    <h4>{{userData?.FirstName}} {{userData?.LastName}}</h4>
    <h5 class="nome-conta">{{userData?.AccountName}}</h5>
  </div>
  <img class="avatar" [src]="getAvatarUrl()" alt="avatar-capa">
</header>

<section class="content">
  <div class="selecao">
    <section class="login">
      <main>
        <section>
          <h2>Escolha o que quer fazer:</h2>
          <ul class="opcoes-usuario">
            <li>
              <p>Tenha acesso para criar, editar e excluir seus Treinos, Fichas, Exercicíos, e também a impressão de treinos.</p>
              <a>Gerenciar Treinos</a>
            </li>

            <li>
              <p>Veja as postagens da sua Academia e visualize os treinos criados para você.</p>
              <a>Visualizar Treinos</a>
            </li>
          </ul>
        </section>
      </main>
    </section>
  </div>
</section>
