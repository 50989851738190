import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import { Context } from '../dados-pagamento';

@Component({
  selector: 'app-card-pagamento',
  templateUrl: './card-pagamento.component.html',
  styleUrls: ['./card-pagamento.component.scss'],
})
export class CardPagamentoComponent implements OnInit {
  @Input() permitirPagarComPaguela: boolean;
  @Input() situacaoPaguela: number;
  @Input() data: any;
  @Input() context: number;
  @Input() pagamentoAgendado: boolean;
  @Output() eventMeioPagamento = new EventEmitter();

  public dataProveniente: any;
  public provenienteContaReceber: string;
  public provenienteRecebimento: string;
  public permitirTransacoesPaguela = false;

  constructor() {}

  public ngOnInit(): void {
    this.permitirTransacoesPaguela = this.situacaoPaguela === 0;
    this.provenienteContaReceber = this.data.TipoProveniente;
    this.provenienteRecebimento = this.data.TipoProveniente;
  }

  public abrirMeiosPagamento(data) {
    this.eventMeioPagamento.emit(data);
  }

}
