<div *ngIf="show" class="menu-treino-overlay">
  <div class="menu-treino">
    <div class="close-menu" (click)="close()">
      <!-- este icone é o clear -->
      <i class="material-icons">&#xE14C;</i>
    </div>
    <ul>
      <li *ngFor="let treino of ficha.Treinos">
        <div (click)="mudaTreino(treino)" class="treino" [class.active]="treino == treinoAtivo">
          Treino {{treino.LetraTreino}}
          <span *ngIf="treino.Descricao"> | {{treino.Descricao}}</span>
        </div>
      </li>
    </ul>
  </div>
</div>
