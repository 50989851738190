import { LoginService } from './login.service';
import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';

declare var cordova: any;

@Injectable()
export class NotificacoesService {
  config: any;

  constructor(
    private configService: ConfigService,
    private loginService: LoginService
  ) {
    this.configService.getConfig().subscribe(value => {
      this.config = value;
    });
  }

  criar(data: Date, titulo: string) {
    if (this.config.notificaintervalo === true && this.loginService.getAuthStatus()) {
      cordova.plugins.notification.local.schedule({
        id: 1,
        text: titulo,
        at: data,
        icon: 'res://icon',
        smallIcon: 'res://icon',
        badge: 0,
      });
    }
  }

  cancelar(id: number) {
    cordova.plugins.notification.local.cancel(id, function () {});
  }
}
