import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EventEmitterService } from './../../../shared/services/event-emitter.service';
import { SoundsService } from './../../../shared/services/sounds.service';
import { StorageServiceTimers } from './../../../shared/services/storage-timers.service';
import { UtilsService } from './../../../shared/services/utils.service';
import { Timer } from 'easytimer.js';

@Component({
  selector: 'app-emom',
  templateUrl: './emom.component.html',
  styleUrls: ['./emom.component.scss']
})
export class EmomComponent implements OnInit, OnDestroy {
  @ViewChild('alert') alerta;
  addClassbyService = false;
  tempoSeconds: number;
  tempo = '0100';
  emom = new Timer();
  emomValues: any = {
    roundsDone: 0,
    roundsTotal: 10,
  };
  emomIsPaused = false;
  emomTempoPreparacao = 10;
  emomPausedValues: number;
  emomPreparacao = true;
  emomForm: UntypedFormGroup;
  openSidebar = false;
  emomStarted = false;
  emomtempoOn = '0020';
  vermelho = false;

  constructor(
    private fb: UntypedFormBuilder,
    private storage: StorageServiceTimers,
    private utilsService: UtilsService,
    private soundsService: SoundsService,
    private router: Router,
  ) { }

  ngOnInit() {

    this.emomForm = this.fb.group({
      Rounds: [10],
      Tempo: ['0100'],
      TempoWorkout: [{value: null, disabled: true}],
      TempoPreparacao: [null],
      TelaCheia: [null],
    });
    this.tempoSeconds = this.utilsService.tempoToSeconds(this.tempo);
    this.emomForm.controls.Rounds.valueChanges.subscribe((rounds) => {
      this.reset();
      this.emomValues.roundsTotal = rounds;
    });

    this.emomForm.controls.Tempo.valueChanges.subscribe((tempo) => {
      this.reset();
      this.tempo = tempo;
      this.tempoSeconds = this.utilsService.tempoToSeconds(this.tempo);
    });

    this.emom.addEventListener('secondTenthsUpdated', () => {
      this.emomValues.seconds = this.emom.getTimeValues().seconds
      this.emomValues.minutes = this.emom.getTimeValues().minutes
      this.emomValues.secondTenths = this.emom.getTimeValues().secondTenths
    });

    this.emomForm.controls.TelaCheia.valueChanges.subscribe((value) => this.utilsService.toggleTelaCheia(value))

    this.emom.addEventListener('targetAchieved', () => {
      EventEmitterService.get('background-end').emit()
      this.emomValues.roundsDone < this.emomValues.roundsTotal ?
        this.emomPreparacao ? (this.start(), this.emomPreparacao = false, this.emomValues.roundsDone++) :
         ( this.start(), this.emomValues.roundsDone++ ) :
      (this.reset(), this.soundsService.playDone());
    });
  }

  ngOnDestroy() {
   EventEmitterService.get('background-end').emit();
   this.soundsService.stopAllSounds();
   this.reset();
  }

  start(preparacao?: boolean) {
    preparacao ? (
      this.emomValues.seconds = this.emomTempoPreparacao,
      this.emom.start({precision: 'seconds', countdown: true, startValues: { seconds: this.emomTempoPreparacao}, target: {secondTenths: 0, seconds: 0, minutes: 0}}),
      this.vermelho = true,
      this.emomStarted = true
    ) : (
      this.emomValues.seconds = Number(this.tempo.toString().substr(2, 3)),
      this.emomValues.minutes = Number(this.tempo.toString().substr(0, 2)),
      this.emom.start({precision: 'secondTenths', countdown: true, startValues: { seconds: this.tempoSeconds}, target: {secondTenths: 0, seconds: 0, minutes: 0}}),
      // adiciona um pequeno delay na animação do fundo
      setTimeout(() => EventEmitterService.get('background-start').emit(this.tempoSeconds), 200),
      this.soundsService.playStart(),
      this.vermelho = false
    );
  }

  pause() {
    if (!this.emomPreparacao) {
      EventEmitterService.get('background-pause').emit();
    }
    this.emomIsPaused = true;
    this.emom.pause();
    this.emomPausedValues = this.emom.getTotalTimeValues().secondTenths;
  }

  resume() {
    if (!this.emomPreparacao) {
     EventEmitterService.get('background-resume').emit(this.tempoSeconds)
    }
    this.emomIsPaused = false;
    this.emom.start({precision: 'secondTenths', countdown: true, startValues: { secondTenths: this.emomValues.secondTenths}, target: {secondTenths: 0, seconds: 0, minutes: 0}});
  }

  reset() {
    this.emom.reset();
    this.emom.pause();
    this.emom.stop();
    this.emomValues.minutes = 0;
    this.emomValues.seconds = 0;
    this.emomValues.secondTenths = 0;
    this.emomValues.roundsDone = 0;
    this.emomStarted = false;
    this.emomPreparacao = true;
    this.vermelho = false;
    this.soundsService.stopAllSounds();
    EventEmitterService.get('background-end').emit();
  }

  salvarConfig({value, valid}) {
    this.reset();
    this.addClassbyService = !this.addClassbyService;
  }

  abrirSideBar() {
    this.addClassbyService = !this.addClassbyService;
  }

  isMobileDevice() {
    const isMobile = window.orientation > -1;
    return isMobile;
  }
}
