import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss']
})
export class SobreComponent implements OnInit {

  constructor(
    private router: Router
  ) {}

  ngOnInit() {
    // Easter egg
    let rotation = 0;
    $('.logo-sobre').on('press', function (e) {
      rotation += 720;
      $(this).css({
        transform: 'rotate(' + rotation + 'deg)'
      });
    });
  }
}
