<div class='finaliza-exercicios animated fadeIn'>
  <svg class="check" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200.62 118">
    <defs>
      <style>
        .cls-1 {
          fill: none;
          stroke: #fff;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 6.01px;
        }
      </style>
    </defs>
    <title>check</title>
    <polyline class="cls-1" points="2.81 70.02 57.08 115 197.81 3" />
  </svg>

  <h1>Parabéns</h1>
  <h2>Treino concluído!</h2>

<!--  <div class="compartilhar">-->
<!--    <button *ngIf="isMobile" class="btn" (click)="share()" mat-button mat-ripple>Compartilhar</button>-->

<!--    <ng-container *ngIf="!isMobile">-->
<!--      <h4>Compartilhar</h4>-->

<!--      &lt;!&ndash; Facebook &ndash;&gt;-->
<!--      <a class="resp-sharing-button__link" href="https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftrei.no" target="_blank" rel="noopener" aria-label="Facebook">-->
<!--        <img src="assets/imagens/facebook.svg" class="facebook">-->
<!--      </a>-->

<!--      &lt;!&ndash; Twitter &ndash;&gt;-->
<!--      <a class="resp-sharing-button__link" href="https://twitter.com/intent/tweet/?text=Terminei%20o%20meu%20treino%20de%20hoje%2C%20e%20voc%C3%AA%3F&amp;url=https%3A%2F%2Ftrei.no" target="_blank" rel="noopener" aria-label="Twitter">-->
<!--        <img src="assets/imagens/twitter.svg" class="twitter">-->
<!--      </a>-->

<!--    </ng-container>-->
<!--  </div>-->

  <button type="button" (click)="fechar($event)" class="botao-fechar" mat-button mat-ripple>Fechar</button>
</div>
