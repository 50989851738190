import { Component, Input, NgZone, ViewContainerRef } from '@angular/core';
import { OnChanges } from '@angular/core';
import { Router } from '@angular/router';

import { AulasService } from '../../../shared/services/aulas.service';
import { LoginService } from '../../../shared/services/login.service';
import { NetworkService } from '../../../shared/services/network.service';
import { PostagensService } from '../../../shared/services/postagens.service';
import { TreinoService } from '../../../shared/services/treino.service';
import { SweetalertService } from './../../../shared/services/sweetalert.service';

declare var navigator: any, $: any;

@Component({
  selector: 'app-card-aula',
  templateUrl: './card-aula.component.html',
  styleUrls: ['./card-aula.component.scss']
})
export class CardAulaComponent implements OnChanges {
  userData: any;
  postagens: any;
  conexaoInternet = true;
  possuiTreino = false;
  dialogRef: any;
  @Input('aula') aula: any;
  @Input('resumido') resumido: boolean;

  constructor(private router: Router,
    private loginService: LoginService,
    private zone: NgZone,
    private postagensService: PostagensService,
    private aulasService: AulasService,
    private networkService: NetworkService,
    private treinoService: TreinoService,
    public viewContainerRef: ViewContainerRef,
    private sweetAlert: SweetalertService) {
  }

  tratarAcaoCard(response) {

    // Ao efetuar alguma acao
    // Mudar label do botao com relacao ao retorno (response)
    if (response) {

      switch (response.Mensagem) {
        case 'já passou da data de reserva':
          this.aula.button.text = 'AULA FINALIZADA';
          this.aula.button.class = 'disabled';
          this.aula.SituacaoReserva = '';
          break;

        case 'em espera':
          this.aula.button.text = 'EM ESPERA';
          this.aula.button.class = 'disabled';
          this.aula.SituacaoReserva = 'C';
          break;

        case 'Você saiu da aula!':
          this.aula.button.text = 'PARTICIPAR';
          this.aula.button.class = '';
          this.aula.SituacaoReserva = '';
          break;

        case 'Reserva feita com sucesso!':
          this.aula.button.text = 'PARTICIPANDO';
          this.aula.button.class = 'disabled';
          this.aula.SituacaoReserva = 'C';
          break;

        case 'em lista de espera':
        case 'pessoa já está em lista espera':
          this.aula.button.text = 'EM ESPERA';
          this.aula.button.class = 'disabled';
          this.aula.SituacaoReserva = 'C';
          this.sweetAlert.alert('', 'A aula já encheu! Você foi adicionado a lista de espera.').subscribe();
          break;
      }

      // Ao iniciar
      // Mudar label de botao ao carregar aulas
      // Caso já esteja participando, caso esteja com a data vencida, caso esteja cheio (botao para espera)
    } else {
      let button;

      button = {
        text: '',
        class: ''
      };

      if (new Date(this.aula.DataReservarAte) < new Date) {
        button.text = 'AULA FINALIZADA';
        button.class = 'disabled';
      } else if (this.aula.SituacaoReserva === 'C') {
        button.text = 'PARTICIPANDO';
        button.class = 'disabled';
      } else if (this.aula.SituacaoReserva === 'E') {
        button.text = 'EM ESPERA';
        button.class = 'disabled';
      } else {
        button.text = 'PARTICIPAR';
        button.class = '';
      }

      this.aula.button = button;
    }
  }

  reservarAula(aula) {
    this.aulasService.reservar(aula.AulaId)
      .subscribe((response: any) => {
        this.tratarAcaoCard(response);
      });
  }

  desReservarAula(aula) {
    this.aulasService.desReservar(aula.AulaId)
      .subscribe((response: any) => {
        this.tratarAcaoCard(response);
      },
      error => {
        console.log('erro ', error);
      })
  }

  openCard(aula) {
    $('#aula-' + aula.AulaId).toggleClass('hidden');
  }

  ngOnChanges() {
    this.tratarAcaoCard(undefined);
  }
}
