import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { LoginService } from '../../shared/services/login.service';
import { SweetalertService } from '../../shared/services/sweetalert.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';

@Component({
  selector: 'app-confirmar-email',
  templateUrl: './confirmar-email.component.html',
  styleUrls: ['./confirmar-email.component.scss']
})
export class ConfirmarEmailComponent implements OnInit, OnDestroy {
  erro: string;
  Email: string;
  contaId: string;
  loading: boolean;
  sucesso: boolean;
  carregando = false;

  isAlive = true;

  formVerificacao: UntypedFormGroup;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private sweetAlertService: SweetalertService,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
    this.montarFormularioVerificacao();
    this.obterParametros();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  montarFormularioVerificacao(): void {
    this.formVerificacao = this.fb.group({
      Id: [this.contaId],
      Code: [ null, [ Validators.required, Validators.minLength(6), Validators.maxLength(6) ] ],
    });
  }

  obterParametros(): void {
    this.route.params.takeWhile(() => this.isAlive).subscribe(params => {
      if (params['email']) {
        this.Email = params['email'];
      }

      if (params['contaId']) {
        this.contaId = params['contaId'];
        this.formVerificacao.get('Id').setValue(this.contaId);
      }
    });
  }

  reenviarEmail(): void {
    this.erro = null;
    this.loading = true;
    this.loginService.enviarEmailConfirmacao({ Module: 'Treino', UserId: this.contaId }).takeWhile(() => this.isAlive).subscribe(() => {
      this.sucesso = true;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.erro = this.errorHandlerService.getFirstValidationMessage(error);
    });
  }

  confirmarEmail(): void {
    if (this.formVerificacao && this.formVerificacao.value && !this.formVerificacao.value.Code) {
      this.erro = 'Código não informado';
    } else {
      this.carregando = true;
      this.loginService.confirmarEmail(this.formVerificacao.value).subscribe(() => {
        this.sweetAlertService.success('Email confirmado com sucesso!').subscribe(() => {
          this.carregando = false;
          this.irParaLogin();
        });
      }, (error) => {
        console.error(error);
        this.carregando = false;
        this.erro = this.errorHandlerService.getFirstValidationMessage(error);
      });
    }
  }

  irParaLogin(): void {
    this.router.navigate(['/login']).then();
  }
}
