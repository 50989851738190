<aside
  [ngClass]="{'sidebar': true, 'openOnHover': addClassbyService}">

  <form [formGroup]="form" id="timer-form" (ngSubmit)="salvarConfig(form)">
    <h4>Tabata Timer</h4>

    <div class="form-group">
      <label for="Rounds">Rounds</label>
      <input id="Rounds" formControlName="Rounds" type="text" inputmode="numeric" placeholder="8" mask="00" autocomplete="off">
      <div class="form-border"></div>
    </div>

    <div class="form-group">
      <label for="TempoOn">Tempo On</label>
      <input id="TempoOn" formControlName="TempoOn" type="text" inputmode="numeric" placeholder="00:20" mask="00:00" autocomplete="off">
      <div class="form-border"></div>
    </div>

    <div class="form-group">
      <label for="TempoOff">Tempo Off</label>
      <input id="TempoOff" formControlName="TempoOff" type="text" inputmode="numeric" placeholder="00:10" mask="00:00" autocomplete="off">
      <div class="form-border"></div>
    </div>

    <!-- <div class="form-group">
      <label for="TempoWorkout">Tempo Workout</label>
      <input id="TempoWorkout" formControlName="TempoWorkout" type="text" placeholder="00:00" mask="00:00" autocomplete="off">
      <div class="form-border"></div>
    </div> -->
<!-- 
    <div class="form-group">
      <label for="TempoPreparacao">Tempo preparação</label>
      <input id="TempoPreparacao" formControlName="TempoPreparacao" type="text" placeholder="00:00" mask="00:00" autocomplete="off">
      <div class="form-border"></div>
    </div> -->

    <div class="form-group form-checkbox" *ngIf="!isMobileDevice()">
      <input id="TelaCheia" formControlName="TelaCheia" type="checkbox" hidden >
      <label for="TelaCheia">Tela Cheia</label>
      <div class="form-border"></div>
    </div>

    <div class="btn-divider">
      <button type="submit" class="btn-sidebar">
        Salvar
      </button>
    </div>
  </form>

</aside>

<section class="content-timers" [class.descanso]="red">
  <div>
    <h1 class="timer">
      <span id="tabata-minutes" class="minutes">{{ (tabataValues.minutes || 0) | number: '2.' }}</span>
      <span class="separator">:</span>
      <span id="tabata-seconds" class="seconds">{{ (tabataValues.seconds || 0) | number: '2.' }}</span>
    </h1>

    <h1 class="timer timer-prep">
      <span id="tabata-prep-minutes" class="minutes">{{ (tabataPrepValues.minutes || 0) | number: '2.' }}</span>
      :
      <span id="tabata-prep-seconds" class="seconds">{{ (tabataPrepValues.seconds || 0) | number: '2.' }}</span>
    </h1>

    <div class="action">
      <button *ngIf="!started" id="cronometro-start" type="button" class="btn-timers btn-timers-primary" (click)="start(true)">
        Iniciar
      </button>

      <button *ngIf="isPaused && started" id="cronometro-start" type="button" class="btn-timers btn-timers-primary" (click)="resume()">
        Iniciar
      </button>

      <button *ngIf="started && !isPaused" id="cronometro-pause" type="button" class="btn-timers btn-timers-ghost" (click)="pause()">
        Pausar
      </button>

      <h2 class="rounds">
        <span id="tabata-rounds-done" class="round-done">{{ (tabataValues.roundsDone || 0) }}</span>
        /
        <span id="tabata-rounds-total" class="round-total">{{ (tabataValues.roundsTotal || 0) }}</span>
      </h2>

      <button id="cronometro-reset" type="button" class="btn-timers btn-timers-ghost" (click)="reset()">
        Zerar
      </button>
    </div>
  </div>
  <app-buttonsidebar (click)="abrirSideBar()" [value]="addClassbyService"></app-buttonsidebar>
</section>
<app-alert #alert></app-alert>


