<ng-container *ngIf="!loginTransiente && !isEmailCriacaoSenhaEnviado && !isLogandoAcessoRapido">
  <!-- ENTRAR COM LOGIN E SENHA -->
  <section *ngIf="(!contas && !contasSalvas?.length) || habilitaLogin">
    <form (ngSubmit)="submitLogin()">
      <mat-form-field>
        <input matInput name="email" required type="email" placeholder="E-mail" maxlength="100" [(ngModel)]="loginData.email" dividerColor="accent">
      </mat-form-field>

      <mat-form-field *ngIf="isPassword">
        <input matInput name="senha" required type="password" autocomplete="password" placeholder="Senha" maxlength="100" [(ngModel)]="loginData.senha" dividerColor="accent">
      </mat-form-field>

      <div class="login-lembrar">
        <mat-checkbox [(ngModel)]="lembrar" name="lembrar">Lembrar de mim</mat-checkbox>
      </div>

      <div *ngIf="erro" class="clearfix">
        <p class="erro">{{ erro }}</p>
      </div>

      <div class="botao-entrar">
        <button type="submit" [ladda]="carregando" data-spinner-color="black" mat-ripple class="btn">Entrar</button>
      </div>

      <div class="m-t-20">
        <a class="flat-link" [routerLink]="['/recuperar-senha', this.loginData.email ? this.loginData.email : '']">Esqueci minha senha</a>
      </div>

      <!--<div class="m-t-20">
        <a [routerLink]="['/cadastrar']" class="green"> Não tem uma conta? </a>
      </div>
    -->
      <div class="m-t-20">
        <a [routerLink]="['/politica-privacidade']" class="green"> Politica de Privacidade </a>
      </div>
    </form>

    <app-footer></app-footer>
  </section>

  <!-- ENTRAR EM CONTA SALVA -->
  <section *ngIf="contasSalvas?.length && !habilitaLogin && !contas">
    <div class="m-b-10" *ngFor="let conta of contasSalvas" (click)="selecionarContaSalva(conta)" [id]="conta.Email">
      <form (ngSubmit)="submitLogin()">
        <input type="hidden" id="email" name="email" [(ngModel)]="conta.Email">
        <div class="account-tile">
          <div class="conta-content">
            <div class="infos-conta">
              <span>{{conta.Email}}</span>
            </div>
            <i *ngIf="conta !== contaSelecionada" class="material-icons icone-proximo">&#xE5CC;</i>
            <i *ngIf="carregando && conta === contaSelecionada && !loginData.senha" [ladda]="true" class="icone-proximo"></i>
          </div>

          <div *ngIf="contaSelecionada === conta" class="senha-content">
            <mat-form-field>
              <input matInput name="senha" required type="password" autocomplete="password" placeholder="Senha" maxlength="100" [(ngModel)]="loginData.senha" dividerColor="accent">
            </mat-form-field>

            <div *ngIf="erro" class="clearfix">
              <p class="erro">{{erro}}</p>
            </div>

            <div class="actions botao-entrar">
              <a class="flat-link" (click)="removerConta(conta)">Esquecer esta conta</a>
              <button type="submit" [ladda]="carregando" data-spinner-color="black" mat-ripple class="btn">Entrar</button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div (click)="habilitarLogin()" class="m-t-20">
      <a href="#" class="green">Entrar com uma conta diferente</a>
    </div>

    <app-footer></app-footer>
  </section>

  <!-- ESCOLHER UMA DAS VÁRIAS CONTAS DO CLIENTE -->
  <section *ngIf="contas" class="varias-contas">
    <div class="m-b-10" *ngFor="let conta of contas" (click)="submitConta(conta)" [id]="conta.account">
      <form>
        <div class="account-tile">
          <div class="conta-content">
            <span>{{ conta.name ? conta.name : conta.TenantName}}</span>
            <mat-icon *ngIf="conta !== contaSelecionada" class="icone-proximo">&#xE5CC;</mat-icon>
            <i *ngIf="carregando && conta === contaSelecionada" [ladda]="true" class="icone-proximo"></i>
          </div>
        </div>
      </form>
    </div>

    <div (click)="habilitarLogin()" class="m-t-20">
      <a href="#" class="green">Entrar com uma conta diferente</a>
    </div>

    <app-footer></app-footer>
  </section>
</ng-container>

<section *ngIf="loginTransiente || isLogandoAcessoRapido" class="widget-login animated fadeInUp">
  <h3 class="text-center mb-20">Realizando login automático...</h3>
</section>

<section *ngIf="isEmailCriacaoSenhaEnviado" class="widget-login animated fadeInUp">
  <h2 class="txt-white text-center mb-20">Usuário ainda não criou senha</h2>
  <h3 class="txt-white text-center mb-20">Um email foi enviado para {{ loginData.email }} com as instruções para criação de senha e login</h3>
  <a class="green text-center mb-20" (click)="isEmailCriacaoSenhaEnviado = false">Voltar</a>
</section>
