import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CommonComponent } from './common.component';
import { LoginComponent } from './login/login.component';
import { CadastrarComponent } from './cadastrar/cadastrar.component';
import { CriarSenhaComponent } from './criar-senha/criar-senha.component';
import { ConfirmarEmailComponent } from './confirmar-email/confirmar-email.component';
import { RecuperarSenhaComponent } from './recuperar-senha/recuperar-senha.component';
import { RedefinirSenhaComponent } from './redefinir-senha/redefinir-senha.component';
import { EmailConfirmadoComponent } from './email-confirmado/email-confirmado.component';
import { ConfirmarTelefoneComponent } from './confirmar-telefone/confirmar-telefone.component';
import { PoliticaPrivacidadeComponent } from './politica-privacidade/politica-privacidade.component';

import { CommonGuard } from '../guards/common.guard';

export const CommonRoutes: Routes = [{
  path: '',
  component: CommonComponent,
  canActivate: [CommonGuard],
  children: [
    { path: 'login', component: LoginComponent },
    { path: 't/:sid', component: LoginComponent },
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'acessorapido/:pessoaId/:codigo', component: LoginComponent },
    { path: 'politica-privacidade', component: PoliticaPrivacidadeComponent },
    { path: 'login/email-confirmation', component: CriarSenhaComponent },
    { path: 'login/:email', component: LoginComponent },
    { path: 'login/:token/:codigo', component: LoginComponent },
    { path: 'confirmar-email/:email/:contaId', component: ConfirmarEmailComponent },
    { path: 'email-confirmado/:email', component: EmailConfirmadoComponent },
    { path: 'recuperar-senha', component: RecuperarSenhaComponent },
    { path: 'recuperar-senha/:email', component: RecuperarSenhaComponent },
    { path: 'redefinir-senha/:email/:codigo', component: RedefinirSenhaComponent },
    { path: 'criar-senha/:id/:code', component: CriarSenhaComponent },
    { path: 'cadastrar', component: CadastrarComponent },
    { path: 'confirmar-telefone/:contaId', component: ConfirmarTelefoneComponent },
    { path: 'confirmar-telefone/:pessoaId/:sid', component: ConfirmarTelefoneComponent },
    { path: '**', redirectTo: '/login' },
  ]
}];

export const CommonRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(CommonRoutes);
