<section>
  <div *ngIf="confirmar" class="confirmar m-b-20">
    <div class="title"> Vamos adicionar um celular a sua conta </div>

    <form [formGroup]="form" (ngSubmit)="submit(form)">
      <mat-form-field class="m-t-10 m-b-20" *ngIf="!international">
        <input matInput name="Numero" formControlName="Numero" [textMask]="{mask: mask}" placeholder="Telefone" dividerColor="accent">
        <mat-error *ngIf="form.get('Numero').getError('required')">Campo Obrigatório</mat-error>
      </mat-form-field>

      <mat-form-field class="m-t-10 m-b-10" *ngIf="international">
        <input matInput name="Numero" formControlName="Numero" placeholder="Telefone com DDI" dividerColor="accent">
        <mat-error *ngIf="form.get('Numero').getError('required')">Campo Obrigatório</mat-error>
      </mat-form-field>

      <p class="helper" *ngIf="international">O formato deve ser '+XX xxxxxxxxxx'</p>

      <div class="m-b-20">
        <a class="flat-link" (click)="international = !international">
          Meu número é
          {{ international ? 'nacional' : 'internacional' }}
        </a>
      </div>

      <div class="btn-conf-email m-b-20">
        <button class="btn" type="submit" [ladda]="carregando">Adicionar</button>
      </div>
    </form>
  </div>

  <div *ngIf="verificar" class="verificar texto-verde-200 m-b-20">
    <div class="title">
      Agora digite o código que foi enviado no seu celular
    </div>

    <form [formGroup]="formVerificacao" (ngSubmit)="submitVerificacao(formVerificacao)">
      <mat-form-field class="m-t-10 m-b-20">
        <input matInput name="codigo" formControlName="Token" placeholder="Código Verificação" dividerColor="accent">
        <mat-error *ngIf="formVerificacao.get('Token').getError('required')">Campo Obrigatório</mat-error>
        <mat-error *ngIf="formVerificacao.get('Token').getError('minlength')">Código de verificacão deve ter 6 dígitos</mat-error>
        <mat-error *ngIf="formVerificacao.get('Token').getError('maxlength')">Código de verificacão deve ter 6 dígitos</mat-error>
      </mat-form-field>

      <p class="m-b-20" *ngIf="reenviando"><b>Reenviando código ...</b></p>
      <p class="m-b-20" *ngIf="sucesso"><b>Código reenviado com sucesso</b></p>
      <p class="m-b-20 erro" *ngIf="erro"><b>{{ erro }}</b></p>

      <div class="m-b-20">
        <button class="btn" type="submit" [ladda]="carregando">Verificar</button>
      </div>

      <div class="m-b-20">
        <a class="green" (click)="enviarNovamente()">Enviar código novamente</a>
      </div>

    </form>
  </div>

  <a [routerLink]="['/login']" class="green">Voltar para o início</a>

  <app-footer></app-footer>
</section>
