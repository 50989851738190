
<h1 mat-dialog-title>Enviar Imagem</h1>
<div>
  <image-cropper
    output="base64"
    [imageChangedEvent]="image"
    [aspectRatio]="1 / 1"
    format="png"
    [resizeToWidth]="600"
    [onlyScaleDown]="true"
    (imageCropped)="imageCropped($event)"
    (loadImageFailed)="imageLoadFailed()"
    (imageLoaded)="imageLoaded()"
    [style.display]="cropperReady ? null : 'none'"
  ></image-cropper>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null">Cancelar</button>
  <button mat-button [mat-dialog-close]="croppedImage" class="btn btn-primary">Enviar</button>
</div>
