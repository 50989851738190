import { Injectable } from '@angular/core';

@Injectable()
export class ErrorService {
  constructor(
  ) { }
    
  public erro: any;

  mostrarErro(error: any): string {
    console.error(error);
    
    try {
      error.error = JSON.parse(error.error);
    } catch(e) { }

    const msgErro = 'Ocorreu um erro interno no servidor.';

    if (error && error.error && error.error.Message && error.error.Message === 'An error has occurred.') {
      return msgErro;
    }

    if (!error || !error.error) {
      return msgErro;
    }

    if (error && error.error.type === 'error') {
      return msgErro;
    }

    if (error && error.error && error.error.ModelState && error.error.ModelState.Erros && error.error.ModelState.Erros.length > 0) {
      return error.error.ModelState.Erros[0];
    }
    
    const vals = Object.values(error.error);
    this.erro = vals && vals.length? vals[0] : msgErro;
    return this.erro;
  }
}
