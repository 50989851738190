import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EventEmitterService } from './../../../shared/services/event-emitter.service';
import { SoundsService } from './../../../shared/services/sounds.service';
import { StorageServiceTimers } from './../../../shared/services/storage-timers.service';
import { UtilsService } from './../../../shared/services/utils.service';
import { Timer } from 'easytimer.js';

@Component({
  selector: 'app-cronometro',
  templateUrl: './cronometro.component.html',
  styleUrls: ['./cronometro.component.scss']
})
export class CronometroComponent implements OnInit, OnDestroy {
  @ViewChild('alert') alert;

  cronometro = new Timer();
  cronometroValues: any = {};

  form: UntypedFormGroup;
  openSidebar: boolean;
  finished: boolean;
  started: boolean;
  isPlaying: boolean;
  addClassbyService: boolean;

  configDefault = {
    Tempo: '0100',
    AlarmeAoTerminar: false,
  };

  constructor(
    private fb: UntypedFormBuilder,
    private storage: StorageServiceTimers,
    private utilsService: UtilsService,
    private soundsService: SoundsService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      Tempo: [null],
      AlarmeAoTerminar: [null],
      TelaCheia: [false],
    });

    this.initConfig();

    // dispara a cada milisegundo
    this.cronometro.addEventListener('secondTenthsUpdated', this.updateCronometro.bind(this));

    // dispara a cada segundo
    this.cronometro.addEventListener('secondsUpdated', this.checkCronometro.bind(this));

    // quando muda algum dado de config, resetar o timer
    this.form.controls.Tempo.valueChanges.subscribe(value => this.reset());

    // tela cheia
    this.form.controls.TelaCheia.valueChanges.subscribe((value) => this.utilsService.toggleTelaCheia(value));
  }

  ngOnDestroy() {
    EventEmitterService.get('background-end').emit();
    this.soundsService.stopAllSounds();
    this.reset();
  }

  initConfig() {
    const config = this.storage.get('config') || this.configDefault;

    Object.keys(config).forEach(key => {
      this.form.controls[key].setValue(config[key]);
    });
  }

  start() {
    const AlarmeAoTerminar = this.form.controls.AlarmeAoTerminar.value;
    const Tempo = this.form.controls.Tempo.value;

    if (AlarmeAoTerminar && !Tempo) {
      this.alert.open('Insira um tempo máximo para finalizar o cronômetro ou desative a opção "Alertar ao terminar"');

    } else {
      if (this.finished) {
        // se a pessoa clicar em iniciar após ter rodado uma vez
        // disparar evento de zerar para começar de novo
        this.reset();
      }

      this.isPlaying = true;
      this.cronometro.start({precision: 'secondTenths'});

      if (!this.started) {
        this.soundsService.playStart();
        this.started = true;
      }

      const totalSeconds = this.utilsService.tempoToSeconds(Tempo);
      if (totalSeconds) {
        EventEmitterService.get('background-start').emit(totalSeconds);
      } else {
        EventEmitterService.get('background-show').emit();
      }
    }
  }

  pause() {
    this.cronometro.pause();

    this.isPlaying = false;
    this.soundsService.stopAllSounds();

    const Tempo = this.form.controls.Tempo.value;
    const totalSeconds = this.utilsService.tempoToSeconds(Tempo);
    if (totalSeconds) {
      EventEmitterService.get('background-pause').emit();
    } else {
      EventEmitterService.get('background-end').emit();
    }
  }

  reset() {
    this.cronometro.reset();
    this.cronometro.pause();
    this.cronometro.stop();
    this.isPlaying = false;
    this.finished = false;
    this.started = false;

    this.cronometroValues.miliseconds = 0;
    this.cronometroValues.seconds = 0;
    this.cronometroValues.minutes = 0;

    this.soundsService.stopAllSounds();

    EventEmitterService.get('background-end').emit();
  }

  // atualizar os valores do cronometro na tela
  updateCronometro(e) {
    this.cronometroValues = this.cronometro.getTimeValues();
  }

  // verifica se deve parar e alertar
  checkCronometro(e) {
    const val = this.cronometro.getTimeValues();
    const Tempo = this.form.controls.Tempo.value;
    const AlarmeAoTerminar = this.form.controls.AlarmeAoTerminar.value;

    // verificar se já acabou o tempo
    if (Tempo) {
      const maxSeconds = this.utilsService.tempoToSeconds(Tempo);
      const totalSeconds = val.seconds + (60 * val.minutes);

      if (totalSeconds === maxSeconds) {
        this.cronometro.pause();
        this.isPlaying = false;
        this.finished = true;
        this.started = false;

        EventEmitterService.get('background-end').emit();
        if (AlarmeAoTerminar) {
          this.soundsService.playDone();
        }
      }
    }
  }

  salvarConfig({value, valid}) {
    this.addClassbyService = !this.addClassbyService;
  }

  abrirSideBar() {
    this.addClassbyService = !this.addClassbyService;
  }

  isMobileDevice() {
    const isMobile = window.orientation > -1;
    return isMobile;
  }
}
