<section>
  <form (ngSubmit)="submit(form)" [formGroup]="form">
    <div class="title">
      Redefina sua senha:
    </div>

    <mat-form-field>
      <input matInput formControlName="NovaSenha"  placeholder="Senha" type="password">
      <mat-error *ngIf="form.get('NovaSenha').invalid">Campo Obrigatório</mat-error>
    </mat-form-field>

    <mat-form-field>
      <input matInput formControlName="NovaSenhaConfirmacao"  placeholder="Confirme sua senha" type="password">
      <mat-error *ngIf="form.get('NovaSenhaConfirmacao').getError('required')">Campo Obrigatório</mat-error>
      <mat-error *ngIf="form.get('NovaSenhaConfirmacao').getError('MatchPassword')">Senhas não são iguais</mat-error>
    </mat-form-field>

    <ng-container *ngIf="erro">
      <p class="erro m-t-20">{{ erro }}</p>
    </ng-container>

    <div class="botao-entrar">
      <button type="submit" [ladda]="carregando" data-spinner-color="black" class="btn" mat-button mat-ripple>Confirmar</button>
    </div>

    <div>
      <a [routerLink]="['/login']" class="green">Voltar para o início</a>
    </div>
  </form>

  <app-footer></app-footer>
</section>
