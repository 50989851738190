<section>
  <p class="branco" *ngIf="loading && !error && !emailConfirmado">Carregando ...</p>

  <p class="erro" *ngIf="!loading && error">Ocorreu um erro.</p>

  <form *ngIf="!loading && !error && emailConfirmado" (ngSubmit)="submit(form)" [formGroup]="form">
    <p class="title">
      Crie sua senha:
    </p>

    <mat-form-field>
      <input matInput formControlName="NovaSenha" type="password" placeholder="Senha">
      <mat-error *ngIf="form.get('NovaSenha').getError('required')">Campo Obrigatório</mat-error>
      <mat-error *ngIf="form.get('NovaSenha').getError('minlength')">A senha deve ter pelo menos 6 caracteres</mat-error>
    </mat-form-field>

    <mat-form-field>
      <input matInput formControlName="NovaSenhaConfirmacao" type="password" placeholder="Confirme sua senha">
      <mat-error *ngIf="form.get('NovaSenhaConfirmacao').getError('required')">Campo Obrigatório</mat-error>
      <mat-error *ngIf="form.get('NovaSenhaConfirmacao').getError('MatchPassword')">Senhas não são iguais</mat-error>
    </mat-form-field>

    <div class="container-checkbox text-white m-t-10" style="position: relative; text-align: left;">
      <mat-checkbox [ngClass]="submitted && form.get('PrivacyAccepted').getError('required') ? 'checkbox-error' : ''" formControlName="PrivacyAccepted">Concordo com a <a [routerLink]="['/politica-privacidade']">política de privacidade</a></mat-checkbox>
      <span style="position: relative; left: 0; bottom: 12px; font-size: 12px; width: 100%; display: inline-block; color: #f44336;" class="text-error" class="text-error" *ngIf="submitted && form.get('PrivacyAccepted').getError('required')">Obrigatório aceitar as políticas de privacidade</span>
    </div>

    <div class="container-checkbox text-white m-t-10" style="position: relative; text-align: left;">
      <mat-checkbox [ngClass]="submitted && form.get('TermsAccepted').getError('required') ? 'checkbox-error' : ''" formControlName="TermsAccepted">Concordo com os termos do Trei.no e da Actuar</mat-checkbox>
      <span style="position: relative; left: 0; bottom: 12px; font-size: 12px; width: 100%; display: inline-block; color: #f44336;" class="text-error" *ngIf="submitted && form.get('TermsAccepted').getError('required')">Obrigatório aceitar os termos</span>
    </div>

    <p *ngIf="erro" class="erro m-t-20">{{erro}}</p>

    <div class="botao-entrar">
      <button [ladda]="carregando" type="submit" class="btn">Confirmar</button>
    </div>
  </form>

  <div class="link">
    <a [routerLink]="['/login']" class="green">Voltar para o início</a>
  </div>

  <app-footer></app-footer>
</section>
