<div class="dark-bg">
  <div class="academia-messages" *ngIf="loading || error">
    <mat-progress-spinner mode="indeterminate" diameter="50" *ngIf="loading && !error"></mat-progress-spinner>
    <p class="erro" *ngIf="!loading && error">Erro ao carregar informações sobre a academia</p>
  </div>

  <ng-container *ngIf="!loading && !error && !!licencaTreino">
    <header class="capa academia-capa">
      <div class="overlay-capa">
        <img [src]="licencaTreino.UrlLogo || 'assets/imagens/academia.jpg'" alt="capa-background">
      </div>
      <img class="avatar" [src]="licencaTreino.UrlLogo || 'assets/imagens/academia.jpg'" alt="avatar-capa">
    </header>

    <section class="academia-content">
      <h4 *ngIf="licencaTreino.NomeEmpresaExibicao" class="uppercase">{{licencaTreino.NomeEmpresaExibicao}}</h4>

      <div *ngIf="licencaTreino.WebSiteExibicao || licencaTreino.EmailExibicao || licencaTreino.TelefoneExibicao || licencaTreino.UrlFacebook"
        class="separator">
      </div>

      <a *ngIf="licencaTreino.WebSiteExibicao" class="link-academia" (click)="goTo('http://' + licencaTreino.WebSiteExibicao)">
        <img class="icon" src="assets/imagens/web.svg"> {{licencaTreino.WebSiteExibicao}}
      </a>

      <a *ngIf="licencaTreino.EmailExibicao" class="link-academia" href="mailto:{{licencaTreino.EmailExibicao}}">
        <img class="icon" src="assets/imagens/mail.svg"> {{licencaTreino.EmailExibicao}}
      </a>

      <a *ngIf="licencaTreino.TelefoneExibicao" class="link-academia" href="tel:{{licencaTreino.TelefoneExibicao | apenasNumeros}}">
        <img class="icon" src="assets/imagens/phone.svg"> {{licencaTreino.TelefoneExibicao}}
      </a>

      <!--Social Networks area -->
      <div class="social-networks">
        <a *ngIf="licencaTreino.UrlFacebook" (click)="goTo('http://facebook.com/' + licencaTreino.UrlFacebook)">
          <img src="assets/imagens/facebook.svg" class="facebook">
        </a>
        <a *ngIf="licencaTreino.UrlInstragram" (click)="goTo('https://www.instagram.com/' + licencaTreino.UrlInstragram)">
          <img src="assets/imagens/instagram.svg" class="instagram">
        </a>
        <a *ngIf="licencaTreino.UrlTwitter" (click)="goTo('https://twitter.com/' + licencaTreino.UrlTwitter)">
          <img src="assets/imagens/twitter.svg" class="twitter">
        </a>
        <a *ngIf="licencaTreino.UrlYouTube" (click)="goTo('https://www.youtube.com/channel/' + licencaTreino.UrlYouTube)">
          <img src="assets/imagens/youtube.svg" class="youtube">
        </a>
        <a *ngIf="licencaTreino.TelefoneExibicao" (click)="openWhatsapp(licencaTreino.TelefoneExibicao)">
          <img src="assets/imagens/whatsapp.svg" class="whatsapp">
        </a>
      </div>

      <div *ngIf="licencaTreino.FuncionamentoLinha1 || licencaTreino.FuncionamentoLinha2
        || licencaTreino.FuncionamentoLinha3 || licencaTreino.FuncionamentoLinha4">

        <div class="separator"></div>
        <h5>Horários de funcionamento</h5>

        <h5 *ngIf="licencaTreino.FuncionamentoLinha1">{{ licencaTreino.FuncionamentoLinha1 }}</h5>
        <h4 *ngIf="licencaTreino.FuncionamentoLinha2">{{ licencaTreino.FuncionamentoLinha2 }}</h4>
        <h5 *ngIf="licencaTreino.FuncionamentoLinha3">{{ licencaTreino.FuncionamentoLinha3 }}</h5>
        <h4 *ngIf="licencaTreino.FuncionamentoLinha4">{{ licencaTreino.FuncionamentoLinha4 }}</h4>
      </div>

      <div class="separator"></div>

      <img src="assets/imagens/logo-actuar-invertida@2x.png" class="footer-logo-invertida">
    </section>
  </ng-container>
</div>
