<div class="row">
  <div class="col-12 form-group">
    <div class="selecao-avatar d-flex justify-items-center d-sm-block">
    <div class="container" [style.width]="width" [style.height]="heigth" >
        <div [style.display]="loading ? 'block': 'none'"  [style.width]="width"  [style.height]="heigth">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" [style.width]="width"  [style.height]="heigth" style="margin: auto; background: rgba(255, 255, 255, 0.0); display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <path d="M9 50A41 41 0 0 0 91 50A41 43.8 0 0 1 9 50" fill="#55ec23" stroke="none">
              <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51.4;360 50 51.4"></animateTransform>
            </path>
          </svg>
        </div>
      <div class="avatar-badge" *ngIf="isEditable" [style.display]="avatarUrl && !loading? 'block' : 'none'">
        <svg width="26px" height="26px" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 122.88" style="enable-background:new 0 0 122.88 122.88" xml:space="preserve"><style type="text/css">.st0{fill-rule:evenodd;clip-rule:evenodd;}</style><g><path class="st0" d="M61.44,0c33.93,0,61.44,27.51,61.44,61.44c0,33.93-27.51,61.44-61.44,61.44S0,95.37,0,61.44 C0,27.51,27.51,0,61.44,0L61.44,0z M52.56,84c-1.82,0.61-3.68,1.17-5.5,1.77c-1.82,0.61-3.64,1.21-5.5,1.82 c-4.34,1.4-6.71,2.19-7.23,2.33c-0.51,0.14-0.19-1.86,0.89-6.06l3.45-13.19l26.01-27.04l13.85,13.33L52.56,84L52.56,84L52.56,84z M78.48,33.84c-0.65-0.61-1.4-0.93-2.24-0.89c-0.84,0-1.59,0.33-2.19,0.98l-4.94,5.13l13.85,13.38l4.99-5.22 c0.61-0.61,0.84-1.4,0.84-2.24c0-0.84-0.33-1.63-0.93-2.19L78.48,33.84L78.48,33.84L78.48,33.84z"/></g></svg>
      </div>
        <div class="avatar-img" [style.display]="avatarUrl && !loading? 'block' : 'none'" [style.width]="width" [style.height]="heigth">
        <img class='img-fade' [src]="avatarUrl" [width]="width" [height]="heigth" (load)="loadedImage()"/>
        </div>
      <div *ngIf="isEditable" class="overlay" (click)="upload()" [style.width]="width" [style.height]="heigth">
        <svg xmlns="http://www.w3.org/2000/svg" [style.width]="width" [style.height]="heigth" viewBox="0 0 80 84"><defs><style>.a{fill:rgba(255, 255, 255, 0);}.b{fill:rgb(255, 255, 255);}.c,.d{fill:none;stroke:rgb(255, 255, 255);stroke-linejoin:round;}.c{stroke-linecap:round;}</style></defs><g transform="translate(-30 -29.297)"><rect class="a" width="80" height="84" rx="2" transform="translate(30 29.297)"/><path class="b" d="M49.8,15.82a7.76,7.76,0,0,0,7.76,7.76,3.88,3.88,0,0,1,3.88,3.88V54.619a3.88,3.88,0,0,1-3.88,3.88H18.76a3.88,3.88,0,0,1-3.88-3.88V27.46a3.906,3.906,0,0,1,3.88-3.88,7.76,7.76,0,0,0,7.76-7.76H49.8m0-3.88H26.52a3.88,3.88,0,0,0-3.88,3.88,3.88,3.88,0,0,1-3.88,3.88A7.76,7.76,0,0,0,11,27.46V54.619a7.76,7.76,0,0,0,7.76,7.76h38.8a7.76,7.76,0,0,0,7.76-7.76V27.46a7.76,7.76,0,0,0-7.76-7.76,3.88,3.88,0,0,1-3.88-3.88A3.88,3.88,0,0,0,49.8,11.94Z" transform="translate(31.679 34.006)"/><path class="b" d="M26.64,19.82a7.76,7.76,0,1,1-7.76,7.76,7.76,7.76,0,0,1,7.76-7.76m0-3.88a11.64,11.64,0,1,0,11.64,11.64A11.64,11.64,0,0,0,26.64,15.94Z" transform="translate(43.199 45.526)"/><path class="c" d="M39.355,15.789A7.786,7.786,0,1,1,27.017,8.65" transform="translate(68.623 24.532)"/><path class="d" d="M31.36,15.67V7.91H23.6" transform="translate(67.966 22.4)"/></g></svg>
      </div>

      <button *ngIf="buttons && fileId" mat-button type="button" (click)="removeImagem()" class="btn btn-default">
        Remover imagem
      </button>
    </div>
  </div>
</div>
<input id="img-upload" style="display: none;" type="file" accept="image/*" (change)="fileChangeAvatar($event)">
