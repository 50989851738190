import { AgendamentoAulasComponent } from './agendamento-aulas/agendamento-aulas.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from './../shared/shared.module';
import { AcademiaComponent } from './academia/academia.component';
import { AulaComponent } from './aulas/aula.component';
import { CardAulaComponent } from './aulas/card-aula/card-aula.component';
import { ConfigComponent } from './config/config.component';
import { CoreComponent } from './core.component';
import { CoreRouting } from './core.routing';
import { EscolhaComponent } from './escolha/escolha.component';
import { CardPostagemComponent } from './feed/card-postagem/card-postagem.component';
import { FeedComponent } from './feed/feed.component';
import { SobreComponent } from './sobre/sobre.component';
import { CardExercicioComponent } from './treino/card-exercicio/card-exercicio.component';
import { MenuTreinoComponent } from './treino/menu-treino/menu-treino.component';
import { TreinoComponent } from './treino/treino.component';
import { ShareTreinoComponent } from './treino/share-treino/share-treino.component';
import { PerfilComponent } from './perfil/perfil.component';
import { AvatarCropComponent } from './perfil/avatar/avatar-crop/avatar-crop.component';
import { AvatarComponent } from './perfil/avatar/avatar.component';
import { AlterarSenhaComponent } from './perfil/alterar-senha/alterar-senha.component';
import { CardFichaInfoComponent } from './treino/card-ficha-info/card-ficha-info.component';
import { ExercicioInstrucaoComponent } from './treino/card-exercicio/exercicio-instrucao/exercicio-instrucao.component';
import { ChatComponent } from './chat/chat.component';
import { EditarCargaComponent } from './treino/card-exercicio/editar-carga/editar-carga.component';
import { MinhasReservasComponent } from "./minhas-reservas/minhas-reservas.component";
import { TimersEscolhaComponent } from './timers/timers-escolha/timers.component';
import { TabataComponent } from './timers/tabata/tabata.component';
import { AlertComponent } from './../shared/components/alert/alert.component';
import { TimersCoreComponent } from './timers/core.component';
import { CronometroComponent } from './timers/cronometro/cronometro.component';
import { TimerComponent } from './timers/timer/timer.component';
import { ButtonSidebarComponent } from '../shared/components/button-sidebar/button-sidebar/buttonsidebar.component';
import { EmomComponent } from './timers/emom/emom.component';
import { ParticipantesTurmaComponent } from './agendamento-aulas/participantes-turma/participantes-turma.component';
import { OrderModule } from 'ngx-order-pipe';
import { AlertVersionComponent } from '../shared/components/alert-version/alert-version.component';
import { AvaliacoesFisicasComponent } from './avaliacoes-fisicas/avaliacoes-fisicas.component';
import { VisualizarAvaliacaoComponent } from './avaliacoes-fisicas/visualizar-avaliacao/visualizar-avaliacao.component';
import { MinhasFrequenciasComponent } from './minhas-frequencias/minhas-frequencias.component';
import { DadosPagamentoComponent } from './dados-pagamento/dados-pagamento.component';
import { CardPagamentoComponent } from './dados-pagamento/card-pagamento/card-pagamento.component';
import { CardAssinaturaComponent } from './dados-pagamento/card-assinatura/card-assinatura.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';


@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CoreRouting,
        SharedModule,
        CommonModule,
        OrderModule,
        NgxMaskModule.forRoot(),
        MatTabsModule,
        MatSelectModule
    ],
    declarations: [
        CoreComponent,
        CardPostagemComponent,
        FeedComponent,
        CardAulaComponent,
        AulaComponent,
        CardExercicioComponent,
        MenuTreinoComponent,
        TreinoComponent,
        SobreComponent,
        ConfigComponent,
        EscolhaComponent,
        AcademiaComponent,
        ShareTreinoComponent,
        PerfilComponent,
        AvatarCropComponent,
        AvatarComponent,
        AlterarSenhaComponent,
        CardFichaInfoComponent,
        ExercicioInstrucaoComponent,
        ChatComponent,
        AgendamentoAulasComponent,
        EditarCargaComponent,
        MinhasReservasComponent,
        TimersEscolhaComponent,
        TabataComponent,
        AlertComponent,
        TimersCoreComponent,
        CronometroComponent,
        TimerComponent,
        ButtonSidebarComponent,
        EmomComponent,
        ParticipantesTurmaComponent,
        AlertVersionComponent,
        AvaliacoesFisicasComponent,
        VisualizarAvaliacaoComponent,
        MinhasFrequenciasComponent,
        DadosPagamentoComponent,
        CardPagamentoComponent,
        CardAssinaturaComponent
    ]
})

export class CoreModule { }
