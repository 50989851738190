import { SortByDatePipe } from './pipes/order-by-date.pipe';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { LaddaModule } from 'angular2-ladda';
import { TextMaskModule } from 'angular2-text-mask';
import { ImageCropperModule } from '../../../ngx-image-cropper/src';
import { NgxMaskModule } from 'ngx-mask';
import { BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';
import { DialDirective } from './directives/dial.directive';
import { MasonryDirective } from './directives/masonry.directive';
import { MdlAccordionDirective } from './directives/mdl-accordion.directive';
import { CalendarioMobileDirective } from './directives/calendario-mobile.directive';
import { FilterPipe } from './pipes/filter.pipe';
import { LimitToPipe } from './pipes/limit-to.pipe';
import { CustomDate } from './pipes/custom-date.pipe';
import { TipoExercicioPipe } from './pipes/tipo-exercicio.pipe';
import { ApenasNumerosPipe } from './pipes/apenas-numeros.pipe';
import { ChatService } from './services/chat.service';
import { ErrorService } from './services/error.service';
import { AulasService } from './services/aulas.service';
import { ConfigService } from './services/config.service';
import { SqliteService } from './services/sqlite.service';
import { TreinoService } from './services/treino.service';
import { PerfilService } from './services/perfil.service';
import { AcessoService } from './services/acesso.service';
import { DevicesService } from './services/devices.service';
import { NetworkService } from './services/network.service';
import { StorageService } from './services/storage.service';
import { ReservasService } from './services/reservas.service';
import { VibracaoService } from './services/vibracao.service';
import { PostagensService } from './services/postagens.service';
import { SweetalertService } from './services/sweetalert.service';
import { LocalStorageService } from './services/localstorage.service';
import { NotificacoesService } from './services/notificacoes.service';
import { TransferidorDeDadosService } from './services/transferidor-de-dados-service';
import { DiaMesPipe } from './pipes/dia-mes.pipe';
import { HoraMinutoPipe } from './pipes/hora-minuto.pipe';
import { StorageServiceTimers } from './services/storage-timers.service';
import { UtilsService } from './services/utils.service';
import { SoundsService } from './services/sounds.service';
import { FCM } from '@ionic-native/fcm/ngx';
import { MoedaPipe } from './pipes/moeda.pipe';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    ImageCropperModule,
    SwiperModule,
    NgxMaskModule.forRoot(),
    LaddaModule.forRoot({
      style: 'expand-right',
    }),
    TextMaskModule,
  ],
  declarations: [
    TipoExercicioPipe,
    LimitToPipe,
    FilterPipe,
    CustomDate,
    SortByDatePipe,
    MasonryDirective,
    CalendarioMobileDirective,
    MdlAccordionDirective,
    DialDirective,
    ApenasNumerosPipe,
    DiaMesPipe,
    HoraMinutoPipe,
    MoedaPipe,
  ],
  providers: [
    PerfilService,
    NotificacoesService,
    VibracaoService,
    SweetalertService,
    LocalStorageService,
    ConfigService,
    PostagensService,
    AulasService,
    TreinoService,
    NetworkService,
    DevicesService,
    StorageService,
    SqliteService,
    ChatService,
    AcessoService,
    ReservasService,
    ErrorService,
    TransferidorDeDadosService,
    StorageServiceTimers,
    UtilsService,
    SoundsService,
    FCM,
    BarcodeScanner,
  ],
  exports: [
    LaddaModule,
    MatDialogModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    ImageCropperModule,
    TipoExercicioPipe,
    CustomDate,
    LimitToPipe,
    FilterPipe,
    SortByDatePipe,
    MasonryDirective,
    CalendarioMobileDirective,
    MdlAccordionDirective,
    DialDirective,
    ApenasNumerosPipe,
    DiaMesPipe,
    HoraMinutoPipe,
    MoedaPipe,
    TextMaskModule,
    SwiperModule
  ]
})

export class SharedModule { }
