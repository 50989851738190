import { Component, NgZone, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AulasService } from '../../shared/services/aulas.service';
import { LoginService } from '../../shared/services/login.service';
import { NetworkService } from '../../shared/services/network.service';
import { PostagensService } from '../../shared/services/postagens.service';
import { TreinoService } from '../../shared/services/treino.service';

declare var navigator: any, $: any, mRefresh: any;

@Component({
  selector: 'app-aula',
  templateUrl: './aula.component.html',
  styleUrls: ['./aula.component.scss']
})
export class AulaComponent implements OnInit {
  userData: any;
  postagens: any;
  aulas: any = [];
  conexaoInternet = true;
  possuiTreino = false;
  dialogRef: any;

  constructor(
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private aulasService: AulasService,
    private treinoService: TreinoService,
    private networkService: NetworkService,
    public viewContainerRef: ViewContainerRef,
    private postagensService: PostagensService,
  ) {
    this.loginService.getSessionData()
      .subscribe(response => {
        this.userData = response;
      });
  }

  // Retorna a url do avatar
  getAvatarUrl(): string {
    if (this.userData && this.userData.AvatarUrl) {
      return this.userData.AvatarUrl;
    } else {
      return (this.userData && this.userData.Sex === 'F')
        ? 'assets/imagens/avatar-f.jpg'
        : 'assets/imagens/avatar-m.jpg';
    }
  }

  // Retorna booleano se há conexão ativa
  checkConnection(): void {
    this.networkService.checkConnection()
      .subscribe((response: any) => {
        this.zone.run(() => {
          this.conexaoInternet = response;
        });
      });
  }

  // Desativa a splashscreen do carregamento
  desativaSplash(): void {
    document.addEventListener('deviceready', () => {
      setTimeout(() => {
        if (navigator && navigator.splashscreen) {
          navigator.splashscreen.hide();
        }
      }, 0);
    }, false);
  }

  // Lifecycle hook
  ngOnInit() {
    const opts = {
      onBegin: () => {
        this.atualizarAulas();
      },
      maxTime: 6000,
      nav: '.refresher-bg',
      scrollEl: 'main',
      theme: 'mui-green-theme'
    }
    this.checkConnection();

    if (this.route.snapshot.params['aulaId']) {

      // Caso vá abrir uma aula em especifico
      this.aulasService.obterPorId(this.route.snapshot.params['aulaId'])
        .subscribe(response => {
          this.aulas = [response];
        });
    } else {
      this.atualizarAulas();
    }
  }

  atualizarAulas() {
    this.aulasService.getAulas(999, 0)
      .subscribe((response: any) => {
        this.desativaSplash();

        mRefresh.resolve();

        if (response) {
          this.aulas = response;
        }
      }, error => {
        this.desativaSplash();
      });
  }

  openCard(aula) {
    $('#aula-' + aula.AulaId).slideToggle();
  }
}
