import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../shared/services/login.service';
import { RouterService } from '../../shared/services/router.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-politica-privacidade',
  templateUrl: 'politica-privacidade.component.html',
  styleUrls: [
    './politica-privacidade.component.scss'
  ]
})
export class PoliticaPrivacidadeComponent implements OnInit {
  isAuth = false;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private routerService: RouterService,
  ) { }

  ngOnInit(): void {
    this.loginService.checkLogin().subscribe(activate => {
      this.isAuth = activate;
    });
  }

  voltar(): void {
    this.router.navigate([ this.routerService.getPreviousUrl() || this.routerService.getCurrentUrl() ]).then();
  }
}
