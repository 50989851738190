import { EventEmitterService } from '../../shared/services/event-emitter.service';
import { SweetalertService } from './../../shared/services/sweetalert.service';
import { SituacaoReservaAulaEnum } from './../../shared/enums/SituacaoReservaAula.enum';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../shared/services/login.service';
import { ErrorHandlerService } from '../../shared/services/error-handler.service';
import { ReservasService } from '../../shared/services/reservas.service';

import * as moment from 'moment';
import {Capacitor} from "@capacitor/core";
import {Browser} from "@capacitor/browser";
import { TreinoService } from 'src/app/shared/services/treino.service';


@Component({
  selector: 'app-minhas-reservas',
  templateUrl: './minhas-reservas.component.html',
  styleUrls: ['./minhas-reservas.component.scss'],
})
export class MinhasReservasComponent implements OnInit, OnDestroy {
  situacaoReservaAulaEnum = SituacaoReservaAulaEnum;

  isAlive = true;
  carregando = true;

  pessoaId = null;
  ajustes = null;
  cards = [];

  constructor(
    private loginService: LoginService,
    private reservasService: ReservasService,
    private errorHandlerService: ErrorHandlerService,
    private sweetAlert: SweetalertService,
    private router: Router,
    private treinoService: TreinoService
  ) { }

  ngOnInit(): void {
    EventEmitterService.get('refresh').takeWhile(() => this.isAlive).subscribe(() => {
      this.obterReservasPorPessoaId(this.pessoaId);
    });

    this.obterUsuarioLogado();
    this.obterAjustes();
  }

  ngOnDestroy(): void {
    this.isAlive = true;
  }

  obterAjustes(): void {
    this.treinoService.obterAjustes().subscribe((data) => {
      this.ajustes = data;
    }, (err) => {
      this.errorHandlerService.error(err);
    });
  }

  obterUsuarioLogado(): void {
    this.carregando = true;
    this.loginService.getSessionData().subscribe(response => {
      this.pessoaId = response.UserTenantId;
      this.obterReservasPorPessoaId(this.pessoaId);
    }, (err) => {
      console.error(err);

      this.carregando = false;
      this.errorHandlerService.error(err);
    });
  }

  obterReservasPorPessoaId(pessoaId: string): void {
    this.carregando = true;
    this.reservasService.obterReservasPorPessoaId(pessoaId).subscribe((data) => {
      this.cards = [];

      if (!data || !data.length) {
        this.carregando = false; 
        return;
      }

      data.forEach(item => {
        if (item && item.Reserva && item.Reserva.Situacao === this.situacaoReservaAulaEnum.Cancelado) { return; }
        const reserva = item.Reserva;
        const turma = item.Turma;
        const hoje = moment().utc(true).toISOString();
        const dataRealizacao = turma ? moment(turma.DataRealizacao).toDate().toUTCString() : null;
        const finalizada = moment(hoje).utc(false).isAfter(moment(dataRealizacao).utc(false));
        if (!finalizada) {
          this.cards.push({
            TurmaId: turma ? turma.TurmaId : null,
            Reserva: reserva || null,
            Local: turma ? turma.Local : null,
            Titulo: turma ? turma.Titulo : null,
            Descricao: turma ? turma.Descricao : null,
            DuracaoMinutos: turma ? turma.DuracaoAula : null,
            DataRealizacao: dataRealizacao,
            Situacao: reserva ? reserva.Situacao : null,
            Finalizada: finalizada,
            Vagas: turma.VagasDisponiveis >= 0 ? turma.VagasDisponiveis : turma.Vagas >= 0 ? turma.Vagas : 0,
            DataRealizacaoFinal: moment(dataRealizacao).utc(false).add(turma.DuracaoMinutos, 'minutes').toDate().toUTCString(),
            UrlAulaVirtual: turma?.UrlAulaVirtual ?? null,
          });
        }
      });

      this.cards.sort((a, b) => {
        if (!moment(a.DataRealizacao).isBefore(b.DataRealizacao)) { return 1; }
        if (moment(a.DataRealizacao).isBefore(b.DataRealizacao)) { return -1; }
        return 0;
      });

      this.carregando = false;
    }, (err) => {
      console.error(err);

      this.carregando = false;
      this.errorHandlerService.error(err);
    });
  }

  abrirCard(card: any): void {
    card.cardOpened = !card.cardOpened;

    if (!card.cardOpened) { return; }
    if (!card || !card.TurmaId) { return; }
    if (card.Local && card.Professores) { return; }

    card.isLoading = true;

    this.reservasService.obterTurmasDetalhadasPorId(card.TurmaId).subscribe((data) => {
      const professores = data.Professores.map(professor => professor.NomeCompleto);

      card.Local = data.Local;
      card.Professores = professores.join(', ');

      card.isLoading = false;
    }, (err) => {
      card.isLoading = false;
      card.cardOpened = false;

      this.errorHandlerService.error(err);
    });
  }

  fazerCheckin(card): void {
    if (card.Reserva && card.Reserva.Situacao === SituacaoReservaAulaEnum.Reservado) {
      card.confirmando = true;
      this.sweetAlert.notificacao('Aguarde', 'Em alguns instantes seu check-in será realizado', true).subscribe();
      this.reservasService.fazerCheckin(card.Reserva.ReservaId).subscribe(() => {
        card.Situacao = SituacaoReservaAulaEnum.Confirmado;
        card.confirmando = false;
        this.sweetAlert.success('Check-in realizado com sucesso.').subscribe();
      }, (err) => {
        console.error(err);
        card.confirmando = false;
        this.errorHandlerService.error(err);
      });
    }
  }

  cancelarReserva(card): void {
    if (!card || !card.Reserva || !card.Reserva.ReservaId) { return; }

    this.sweetAlert.confirm('', 'Deseja cancelar o agendamento desta aula?').subscribe(() => {
      this.sweetAlert.notificacao('Aguarde', 'Em alguns instantes seu cancelamento será realizado', true).subscribe();
      this.reservasService.cancelar(card.Reserva.ReservaId).subscribe(() => {
        this.sweetAlert.success('Agendamento cancelado com sucesso!').subscribe();
        this.cards = this.cards.filter(c => c.TurmaId !== card.TurmaId);
      }, (err) => {
        this.errorHandlerService.error(err);
      });
    });
  }

  verParticipantes(aula) {
    this.router.navigate(['/painel/participantes/', aula.TurmaId]).then();
  }

  async abrirAulaVirtual(url: string) {
    if (Capacitor.getPlatform() === 'android' || 'ios') {
      await Browser.open({url});
    } else {
      window.open(url, '_blank');
    }
  }
}
