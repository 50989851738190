import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class UtilsService {

  constructor() { }

  tempoToSeconds(tempo) {
    if (!tempo) {
      return 0;
    }

    // dividir a string de tempo e verificar o que foi digitado
    // ex: 5seg , 05:34 (5 min e 34 seg) ...

    tempo = {
      minutes: parseInt(tempo[0] + tempo[1], 10),
      seconds: parseInt(tempo[2] + tempo[3], 10),
    };

    return tempo.seconds  + (60 * tempo.minutes);
  }

  toggleTelaCheia(e) {
    if (e) {
      this.enterFullScreen();
    } else {
      this.exitFullScreen();
    }
  }

  enterFullScreen() {
    const page: any = document.documentElement;

    if (page.requestFullscreen) {
      page.requestFullscreen();
    } else if (page.mozRequestFullScreen) {
      page.mozRequestFullScreen();
    } else if (page.msRequestFullscreen) {
      page.msRequestFullscreen();
    } else if (page.webkitRequestFullScreen) {
      page.webkitRequestFullScreen();
    }
  }

  exitFullScreen() {
    const _document: any = document;

    if (_document.exitFullscreen) {
      return _document.exitFullscreen();
    } else if (_document.webkitExitFullscreen) {
      return _document.webkitExitFullscreen();
    } else if (_document.msExitFullscreen) {
      return _document.msExitFullscreen();
    } else if (_document.mozCancelFullScreen) {
      return _document.mozCancelFullScreen();
    }
  }
}
