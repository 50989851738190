import {ErrorHandler, Injectable} from '@angular/core';
import { LoginService } from './login.service';
import {SweetalertService} from './sweetalert.service';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  constructor(
    private sweetAlertService: SweetalertService,
    private loginService: LoginService,
  ) { }

  handleError(error: HttpErrorResponse): void {
    this.error(error);
  }

  error(error: HttpErrorResponse) {
    console.error(error);

    if (error && error.status === 500) {
      this.sweetAlertService.error('Ocorreu um erro interno.').subscribe();
    }

    const errors = this.getAllValidationMessages(error);

    console.log(error);

    if (errors && errors.length) {
      const errorMessage: string = errors.map(message => `\n* ${message}`).join('');
      if (errorMessage.includes('Token' || 'token')) {
        this.loginService.logOut();
      }
      this.sweetAlertService.error(errorMessage).subscribe();

      return;
    }

  }

  getAllValidationMessages(error: HttpErrorResponse): string[] {
    if (error.error && error.error.errors && error.error.errors['Password'][0]) {
      return [error.error.errors['Password'][0]];
    }

    if (error.error && error.error.Validacao && error.error.Validacao.Erros && error.error.Validacao.Erros.length) {
      return error.error.Validacao.Erros;
    }

    if (error.error && error.error.ModelState && error.error.ModelState.Erros && error.error.ModelState.Erros.length) {
      return error.error.ModelState.Erros;
    }

    if (error.error && error.error.errors && error.error.errors && error.error.errors.DomainValidations && error.error.errors.DomainValidations.length) {
      return error.error.errors.DomainValidations;
    }

    if (error && error.error && error.error.Erros) {
      return error.error.Erros;
    }

    return null;
  }

  getFirstValidationMessage(error: HttpErrorResponse): string {
    const errors = this.getAllValidationMessages(error);

    if (errors && errors.length) {
      return errors[0];
    }

    return error?.error?.ValidationResult?.Errors[0]?.ErrorMessage ?? null;
  }
}
