import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { API } from './../../../environments/environment';
import { StorageService } from './storage.service';
export interface IFileUserPhotos {
  PersonId: string;
  File: {
    FileName?: string;
    Data?: string;
    Url?: string;
  };
}
@Injectable()
export class PerfilService {
  perfilStream: ReplaySubject < any > = new ReplaySubject(1);

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) { }

  getPerfilCliente(id) {
    this.perfilStream.next({ carregando: true });

    this.http
      .get(`${API.base}/Pessoas/ObterPorId?id=${id}`, {
        withCredentials: true
      })
      .subscribe((response: any) => {
        this.storageService.set('perfil', response);
        this.perfilStream.next({ perfil: response });
      }, error => {
        console.log(error);
        this.perfilStream.next({ perfil: null });
      });
  }

  getPerfil(id: string) {
    this.perfilStream.next({ carregando: true });
        this.perfilStream.next({ perfil: this.storageService.get('perfil') });
        if (navigator.onLine) {
          this.getPerfilCliente(id);
        }
    return this.perfilStream;
  }

  obterDataVencimentoAssinaturaPorPessoaId(pessoaId: string) {
    return this.http
      .get(`${API.base}/Pessoas/ObterDataVencimentoAssinaturaPorPessoaId?id=${pessoaId}`, {
        withCredentials: true
      });
  }
  
  obterAvatarTreinoPorPessoaId(pessoaId: string): Observable<any> {
    return this.http.get(`${API.files_api}/UserPhotos?groupId=${pessoaId}&flag=2`, {
      withCredentials: true,
    });
  }

  setUserPhotoFlag(fileId): Observable<any> {
    return this.http.put(`${API.files_api}/UserPhotos/SetFlagAsync?fileId=${fileId}&flag=2`, { },
    {
      withCredentials: true,
    });
  }

  
  adicionarAvatar(file): Observable<any> {
    return this.http.post(`${API.files_api}/UserPhotos`, {
        ...file
      },
    {
      withCredentials: true,
    });
  }

  
  removerImagem(fileId: string): Observable<any> {
    return this.http.delete(`${API.files_api}/UserPhotos?id=${fileId}`, {
      withCredentials: true,
    });
  }

  atualizarImagem(file): Observable<any> {
    return this.http.put(`${API.files_api}/UserPhotos`, {
        ...file
      },
    {
      withCredentials: true,
    });
  }

}
