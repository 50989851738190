import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EventEmitterService } from './../../../shared/services/event-emitter.service';
import { SoundsService } from './../../../shared/services/sounds.service';
import { StorageServiceTimers } from './../../../shared/services/storage-timers.service';
import { UtilsService } from './../../../shared/services/utils.service';
import { Timer } from 'easytimer.js';


@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy {
  @ViewChild('alert') alert;

  timer = new Timer();
  timerValues: any = {};
  form: UntypedFormGroup;
  tempo: string;
  openSidebar = false;
  hasChanges = false;
  isRunning: boolean;
  addClassbyService: boolean;
  tocarAlarme = false;
  isFirstStart = true;
  configDefault = {
    tempo: '0100',
    alarmeAoTerminar: false,
  };

  constructor(
    private fb: UntypedFormBuilder,
    private storage: StorageServiceTimers,
    private utilsService: UtilsService,
    private soundsService: SoundsService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      tempo: ['0100'],
      alarmeAoTerminar: [false],
      telaCheia: [false],
    });

    this.tempo = this.form.controls.tempo.value;
    this.form.controls.tempo.valueChanges.subscribe((tempo) => {
      this.tempo = tempo;
      this.hasChanges = true;
    });

    this.form.controls.alarmeAoTerminar.valueChanges.subscribe((alarm) => {
      this.tocarAlarme = alarm;
    });

    this.timer.addEventListener('secondTenthsUpdated', () => {
      this.timerValues.secondTenths = this.timer.getTimeValues().secondTenths;
      this.timerValues.seconds = this.timer.getTimeValues().seconds;
      this.timerValues.minutes = this.timer.getTimeValues().minutes;
    });

    this.timer.addEventListener('targetAchieved', () => {
      EventEmitterService.get('background-end').emit();
      this.isRunning = false;
      this.tocarAlarme ? this.soundsService.playDone():this.soundsService.stopAllSounds();
    });
    // tela cheia
    this.form.controls.telaCheia.valueChanges.subscribe((value) => this.utilsService.toggleTelaCheia(value));
  }

  ngOnDestroy() {
    EventEmitterService.get('background-end').emit();
    this.soundsService.stopAllSounds();
    this.reset();
  }

  start() {
    const tempoSeconds = this.utilsService.tempoToSeconds(this.tempo);
    this.timer.start({
      countdown: true,
      precision: 'secondTenths',
      target: {
        minutes: 0,
        seconds: 0,
        secondTenths: 0,
        hours: 0
      },
      startValues: {
        seconds: tempoSeconds
      }
    });
    this.isRunning = true;
    EventEmitterService.get('background-start').emit(tempoSeconds);
    if(this.isFirstStart){
      this.soundsService.playStart();
    };
  }

  pause() {
    this.timer.pause();
    EventEmitterService.get('background-pause').emit();
    this.soundsService.stopAllSounds();
    this.isFirstStart = false;
    this.isRunning = false;
  }

  reset() {
    this.timer.reset();
    this.timer.pause();
    this.timer.stop();
    this.timerValues.secondTenths = 0;
    this.timerValues.seconds = 0;
    this.timerValues.minutes = 0;
    EventEmitterService.get('background-end').emit();
    this.soundsService.stopAllSounds();
    this.isRunning = false;
    this.isFirstStart = true;
  }

  salvarConfig(form) {
    this.addClassbyService = !this.addClassbyService;
    if(this.hasChanges) {
      this.reset();
      this.hasChanges = false;
    }
  }

  abrirSideBar() {
    this.addClassbyService = !this.addClassbyService;
  }

  isMobileDevice() {
    const isMobile = window.orientation > -1;
    return isMobile;
  }
}
