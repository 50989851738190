import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { API } from './../../../environments/environment';

@Injectable()
export class AcessoService {
  constructor(
    private http: HttpClient,
  ) { }
  
  solicitacaoAcessoNewApi(hardwareId: string, monitorId: string) {
    return this.http.get(API.acesso + '/Manager/SolicitacaoAcesso?hardwareId=' + hardwareId + '&monitorId=' + monitorId, {
      withCredentials: true
    });
  }
}
