import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Ficha, Treino } from './../../../core/core.interfaces';

declare var $: any;

@Component({
  selector: 'app-menu-treino',
  templateUrl: './menu-treino.component.html',
  styleUrls: ['./menu-treino.component.scss']
})
export class MenuTreinoComponent {
  @Input() ficha: Ficha;
  @Input() treinoAtivo: Treino;
  @Output() mudaTreinoAtivo: EventEmitter<Treino> = new EventEmitter();

  show = false;

  constructor() { }

  open(): void {
    if (!this.ficha || !this.ficha.hasOwnProperty('FichaId')) {
      return;
    }

    this.show = true;
  }

  close() {
    this.show = false;
  }

  mudaTreino(treino: Treino): void {
    this.show = false;
    treino.FichaTreinoItens.forEach((val, i) => {
      val.SeriesConcluidas = 0;
    });

    this.mudaTreinoAtivo.emit(treino);
  }
}
