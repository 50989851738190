<div class="menu-home">
<h2></h2>
<h2></h2>
<h2></h2>
    <a [ngClass]="{'a-click': cronometro}" (click)="click('cronometro')">Timer (For Time)</a>
    <a [ngClass]="{'a-click': timer}" (click)="click('timer')">Timer (AMRAP)</a>
    <a [ngClass]="{'a-click': tabata}" (click)="click('tabata')" >Tabata (HIIT)</a>
    <a [ngClass]="{'a-click': emom}" (click)="click('emom')">EMOM</a>
<h2></h2>
<h2></h2>
<h2></h2>
</div>

