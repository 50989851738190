<app-alert-version class="alert-version"></app-alert-version>
<div id="notificacao-chat" class="notificacao-chat animated" (click)="irParaChat()">
  <div class="avatar">
    <app-avatar *ngIf="chatInfo" [pessoaId]="chatInfo && chatInfo.SenderId ? chatInfo.SenderId : '-'" [width]="60" [heigth]="60" [buttons]="false"></app-avatar>
  </div>
  <div class="info">
    <span class="nome">{{ chatInfo?.Sender ?? '' }}</span>
    <span class="mensagem">{{ chatInfo?.Text ?? '' }}</span>
  </div>
</div>

<header class="header">
  <div class="header__botao">
    <div (click)="abrirLeitorQR()" *ngIf="url === '/painel/feed'" class="botao-qr-code">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs><style>.a-white{fill:#fff;}.b{fill:#00c853;}</style></defs><g transform="translate(-534 -1574)"><g transform="translate(534 1574)"><path class="a-white" d="M535.714,1574a1.719,1.719,0,0,0-1.714,1.714V1581h1.714v-4.431a.86.86,0,0,1,.857-.857H541V1574Zm20.571,17v4.431a.86.86,0,0,1-.857.857H551V1598h5.289a1.719,1.719,0,0,0,1.714-1.714V1591Zm-20.571,4.431V1591H534v5.289a1.719,1.719,0,0,0,1.714,1.714H541v-1.714h-4.431A.86.86,0,0,1,535.714,1595.429ZM556.286,1574H551v1.714h4.431a.86.86,0,0,1,.857.857V1581H558v-5.289A1.719,1.719,0,0,0,556.286,1574Z" transform="translate(-534 -1574)"/></g><path class="a-white" d="M540,1580v13.714h8.571v-5.143h5.143V1580Zm1.714,1.714h2.571v2.571h-2.571Zm2.571,4.286v.857h-.857V1586Zm2.571,1.714H546V1592h-4.286v-4.286H546v-4.286h.857Zm5.143-.857h-3.429V1586H552Zm0-1.714h-3.429v-3.429H552Z" transform="translate(-0.857 -0.857)"/><rect class="a-white" width="1" height="1" transform="translate(549 1582)"/><rect class="a" width="2" height="2" transform="translate(542 1588)"/><rect class="b" width="4" height="4" transform="translate(549 1589)"/></g></svg>
    </div>

    <div class="botao-voltar">
      <button mat-icon-button id='backButton' (click)="goback()" *ngIf="url !== '/painel/feed'">
        <mat-icon>
          <!--
            browsers antigos ( <= android 4.4 ) não entendem pelo nome do icone e precisam do código
            este icone é o keyboard_arrow_left
            -->
            &#xE314;
        </mat-icon>
      </button>

    </div>

    <div (click)="abrirLeitorQR()" *ngIf="url === '/painel/aulas'" class="botao-qr-code">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs><style>.a-white{fill:#fff;}.b{fill:#00c853;}</style></defs><g transform="translate(-534 -1574)"><g transform="translate(534 1574)"><path class="a-white" d="M535.714,1574a1.719,1.719,0,0,0-1.714,1.714V1581h1.714v-4.431a.86.86,0,0,1,.857-.857H541V1574Zm20.571,17v4.431a.86.86,0,0,1-.857.857H551V1598h5.289a1.719,1.719,0,0,0,1.714-1.714V1591Zm-20.571,4.431V1591H534v5.289a1.719,1.719,0,0,0,1.714,1.714H541v-1.714h-4.431A.86.86,0,0,1,535.714,1595.429ZM556.286,1574H551v1.714h4.431a.86.86,0,0,1,.857.857V1581H558v-5.289A1.719,1.719,0,0,0,556.286,1574Z" transform="translate(-534 -1574)"/></g><path class="a-white" d="M540,1580v13.714h8.571v-5.143h5.143V1580Zm1.714,1.714h2.571v2.571h-2.571Zm2.571,4.286v.857h-.857V1586Zm2.571,1.714H546V1592h-4.286v-4.286H546v-4.286h.857Zm5.143-.857h-3.429V1586H552Zm0-1.714h-3.429v-3.429H552Z" transform="translate(-0.857 -0.857)"/><rect class="a-white" width="1" height="1" transform="translate(549 1582)"/><rect class="a" width="2" height="2" transform="translate(542 1588)"/><rect class="b" width="4" height="4" transform="translate(549 1589)"/></g></svg>
    </div>

    <!-- USADO APENAS PARA CENTRALIZAR LOGO QUANDO TIVER BOTÃO DE ÝREA DO PROFESSOR -->
    <div *ngIf="userData.Master || userData.TipoCadastro === 'U'" class="btn-area-professor" style="width: 172px"></div>

    <!-- USADO APENAS PARA CENTRALIZAR LOGO QUANDO TIVER BOTÃO DE REFRESH -->
    <!-- <div *ngIf="url === '/painel/feed' || url === '/painel/treino'" style="width: 52px"></div> -->
  </div>

  <div class="header__chat" *ngIf="headerChat" >
    <app-avatar [pessoaId]="headerChat?.pessoaId" [buttons]="false" [heigth]="36" [width]="36"></app-avatar>
    <span>{{ headerChat?.nome }}</span>
  </div>

  <div class="header__logo" *ngIf="!headerChat">
    <img src="assets/imagens/logo-treino-vert-invertida.svg" alt="logo-header" width="83" height="20">
  </div>

  <div class="header__botao">
    <a mat-button mat-ripple *ngIf="userData.Master || userData.TipoCadastro === 'U'" class="btn btn-area-professor" (click)="redirecionaActuar()"
      target="_blank">
      ACESSO PARA O PROFESSOR
    </a>


    <div class="botao-menu" id="refresh-btn" *ngIf="url === '/painel/feed' || url === '/painel/treino' || url === '/painel/aulas' || url === '/painel/minhas-reservas'">
      <button mat-icon-button (click)="refresh()" [disabled]="refreshing">
        <mat-icon>
          <!--
            browsers antigos ( <= android 4.4 ) não entendem pelo nome do icone e precisam do código
            este icone é o refresh
            -->
            &#xE5D5;
        </mat-icon>
      </button>
    </div>

    <div class="botao-menu">
      <button mat-icon-button (click)="abreMenu()">
        <mat-icon>
          <!--
            browsers antigos ( <= android 4.4 ) não entendem pelo nome do icone e precisam do código
            este icone é o more_vert
            -->
            &#xE5D4;
        </mat-icon>
      </button>
    </div>
  </div>
</header>

<div class="menu">
  <div class="wrapper">
    <div class="close-menu">
      <i class="material-icons">
        <!-- este icone é o clear -->
        &#xE14C;
      </i>
    </div>
    <ul>
      <li *ngIf="userData.Master || userData.TipoCadastro === 'U'" class="link-area-professor">
        <a id="link-area-professor btn-area-professor" target="_blank" (click)="redirecionaActuar()">
          <i class="material-icons feed m-r-15">
            <!--
              browsers antigos ( <= android 4.4 ) não entendem pelo nome do icone e precisam do código
              este icone é o perm_identity
             -->
             &#xE8A6;
          </i>
          Acesso para o professor
        </a>
      </li>
      <!-- <li><a [routerLink]="['aula']"><i class="material-icons feed m-r-15">class</i>Aulas</a>
        </li> -->
      <li *ngIf="url !== '/painel/treino'">
        <a [routerLink]="['/painel/treino']">
          <i class="material-icons feed m-r-15">
            <!--
              browsers antigos ( <= android 4.4 ) não entendem pelo nome do icone e precisam do código
              este icone é o rss_feed
             -->
            &#xEB43;
          </i>
          Treino
        </a>
      </li>
      <li *ngIf="url !== '/painel/feed'">
        <a [routerLink]="['/painel/feed']">
          <i class="material-icons feed m-r-15">
            <!--
              browsers antigos ( <= android 4.4 ) não entendem pelo nome do icone e precisam do código
              este icone é o rss_feed
             -->
             &#xE0E5;
          </i>
            Postagens
          </a>
      </li>
      <li *ngIf="url !== '/painel/timers-escolha'">
        <a [routerLink]="['timers-escolha']">
          <i class="material-icons feed m-r-15">
            <!--
              browsers antigos ( <= android 4.4 ) não entendem pelo nome do icone e precisam do código
              este icone é o rss_feed
             -->
             <!-- &#xE0E5; -->
             &#xE425;
            </i>
            Cronômetros
          </a>
      </li>
      <li *ngIf="url !== '/painel/perfil'">
        <a [routerLink]="['perfil']">
          <i class="material-icons perfil m-r-15">
            <!-- settigs -->
            &#xE7FD;
          </i>
          Meu Perfil
        </a>
      </li>
      <li *ngIf="url !== '/painel/avaliacoes-fisicas'">
        <a [routerLink]="['avaliacoes-fisicas']">
          <div class="icon-afig">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.36 45.52">
              <defs>
                <style>
                  .afig-1 {fill: url(#afig-1);}
                  .afig-2 {fill: url(#afig-2);}
                  .afig-3 {fill: url(#afig-3);}
                </style>
                <linearGradient id="afig-1" x1="32.94" y1="23.72" x2="51.9" y2="23.72" gradientUnits="userSpaceOnUse">
                  <stop offset=".02" stop-color="#e53935" />
                  <stop offset=".47" stop-color="#ff5959" />
                  <stop offset=".54" stop-color="#f55662" />
                  <stop offset=".66" stop-color="#dc4d7a" />
                  <stop offset=".81" stop-color="#b23fa1" />
                  <stop offset=".99" stop-color="#792bd7" />
                  <stop offset="1" stop-color="#762ad9" />
                </linearGradient>
                <linearGradient id="afig-2" x1="15.76" y1="23.24" x2="36.87" y2="23.24" gradientUnits="userSpaceOnUse">
                  <stop offset="0" stop-color="#29d6ff" />
                  <stop offset=".63" stop-color="#1683f8" />
                  <stop offset=".98" stop-color="#762ad9" />
                </linearGradient>
                <linearGradient id="afig-3" x1="-.63" y1="22.76" x2="24.1" y2="22.76" gradientUnits="userSpaceOnUse">
                  <stop offset="0" stop-color="#f3f300" />
                  <stop offset=".28" stop-color="#c9f200" />
                  <stop offset=".92" stop-color="#5ef000" />
                  <stop offset="1" stop-color="#51f000" />
                </linearGradient>
              </defs>
              <title>Afig</title>
              <path class="afig-1" d="M48.17 21.17c0 .25-.09.51-.14.76v.18c-.05.24-.11.49-.17.72l-.06.22c-.06.23-.13.47-.2.7l-.1.25c-.08.24-.16.47-.25.71l-.06.17c-.08.2-.16.41-.25.62l-.08.18c-.1.23-.21.45-.32.67l-.11.22-.35.64-.13.22c-.12.21-.25.42-.39.63l-.14.21-.38.54-.09.12-10.35 14a17.26 17.26 0 0 1-5.15-6.13l5.68-7.67.1-.14.42-.6.16-.24.44-.71.15-.25c.14-.24.27-.48.4-.72l.12-.25c.13-.25.25-.5.36-.76l.09-.21c.1-.23.19-.46.28-.69l.08-.19c.1-.26.19-.53.28-.8l.08-.24c.08-.26.16-.53.23-.79l.06-.24c.07-.27.13-.54.19-.81v-.2c.05-.29.11-.57.15-.86a19.67 19.67 0 0 0-.24-7.17c0-.09 0-.18-.06-.26a19.31 19.31 0 0 0-.76-2.5l-.24-.6 3.92-5.29a17.31 17.31 0 0 1 5.9 7.79 17.51 17.51 0 0 1 .68 2.22c0 .08 0 .16.05.24a17.52 17.52 0 0 1 .2 6.31z"/>
              <path class="afig-2" d="M36.41 13.2c0-.09 0-.18-.06-.26a19.32 19.32 0 0 0-.76-2.5l-.24-.6A19.5 19.5 0 0 0 29 1.68l-4.3 5.8a19 19 0 0 1 .78 1.78 20.9 20.9 0 0 1 .8 2.64l.06.28a20.78 20.78 0 0 1 .25 7.57c0 .3-.1.61-.16.91v.22c-.06.29-.13.57-.2.86l-.06.26c-.08.28-.16.56-.24.83l-.08.25c-.09.28-.19.56-.29.84l-.08.2c-.1.25-.19.49-.3.74L25 25c-.12.27-.25.53-.38.8l-.13.26c-.13.26-.27.51-.42.76l-.16.27c-.15.25-.3.5-.46.75l-.17.25-.44.64-.11.15-7 9.4a19.47 19.47 0 0 0 5.58 6.47l6-8L33 29.07l.1-.14.42-.6.16-.24.44-.71.15-.25c.14-.24.27-.48.4-.72l.12-.25c.13-.25.25-.5.36-.76l.09-.21c.1-.23.19-.46.28-.69l.08-.19c.1-.26.19-.53.28-.8l.08-.24c.08-.26.16-.53.23-.79l.06-.24c.07-.27.13-.54.19-.81v-.21c.05-.29.11-.57.15-.86a19.67 19.67 0 0 0-.18-7.16z"/>
              <path class="afig-3" d="M24.25 12.17l-.06-.28a20.9 20.9 0 0 0-.8-2.64 19 19 0 0 0-.78-1.78A20.52 20.52 0 0 0 16.37 0L4.07 16.61A20.67 20.67 0 0 0 8.33 45.5l5.32-7.18 7-9.4.11-.15.44-.64.17-.25c.16-.25.31-.5.46-.75l.16-.27c.15-.25.28-.51.42-.76l.13-.26c.13-.26.26-.53.38-.8l.09-.22c.11-.24.21-.49.3-.74l.08-.2c.11-.28.2-.56.29-.84l.08-.25c.09-.28.17-.56.24-.83l.06-.26c.07-.28.14-.57.2-.86v-.22c.06-.3.12-.61.16-.91a20.78 20.78 0 0 0-.17-7.54z"/>
            </svg>
            <div class="icon-afig-title">
              Avaliações Físicas
            </div>
            <span class="new">
              NOVO
            </span>
          </div>
<!--          <i class="material-icons academia m-r-15">-->

<!--          </i>-->

        </a>
      </li>
      <li *ngIf="url !== '/painel/academia'">
        <a [routerLink]="['academia']">
          <i class="material-icons academia m-r-15">
            <!-- favorite_border -->
            &#xE87E;
          </i>
          Minha academia
        </a>
      </li>
      <li>
        <a [routerLink]="['aulas']">
          <i class="material-icons insert_invitation m-r-15">
            <!-- date_range -->
            &#xe24f;
          </i>
          Aulas
        </a>
      </li>
      <li *ngIf="url !== '/painel/minhas-reservas'">
        <a [routerLink]="['minhas-reservas']">
          <i class="material-icons perfil m-r-15">
            <!-- settigs -->
            &#xe192;
          </i>
          Minhas Reservas
        </a>
      </li>

      <li *ngIf="url !== '/painel/minhas-frequencias'">
        <a [routerLink]="['minhas-frequencias']">
          <i class="material-icons perfil m-r-15 ">
            <!-- settigs -->
            &#xe916;
          </i>
          Minha Frequência
          <span class="new">
              NOVO
          </span>
        </a>
      </li>
      <li *ngIf="url !== '/painel/dados-pagamento' && userData.TipoCadastroAbstraido !== 'U'">
        <a [routerLink]="['dados-pagamento']">
          <i class="material-icons perfil m-r-15 ">
            <!-- settigs -->
            &#xe850;
          </i>
          Dados de pagamento
          <span class="new">
              NOVO
          </span>
        </a>
      </li>
      <li *ngIf="url !== '/painel/config'">
        <a [routerLink]="['config']">
          <i class="material-icons config m-r-15">
            <!-- settigs -->
            &#xE8B8;
          </i>
          Configurações
        </a>
      </li>
      <li *ngIf="url !== '/painel/sobre'">
        <a [routerLink]="['sobre']">
          <i class="material-icons sobre m-r-15">
            <!-- info_outline -->
            &#xE88F;
          </i>
          Sobre
        </a>
      </li>

      <li>
        <a (click)="logOut()">
          <i class="material-icons sair m-r-15">
            <!-- highlight_off -->
            &#xE888;
          </i>
          Sair do Trei.no
        </a>
      </li>
    </ul>
  </div>
</div>

<main [style.background-color]="backgroundColor" >
  <router-outlet></router-outlet>
</main>
