import {Injectable} from '@angular/core';
import * as signalR from '@microsoft/signalr';
import {HttpTransportType} from '@microsoft/signalr';
import {LocalStorageService} from './localstorage.service';
import {API} from '../../../environments/environment';
import {LoginService} from './login.service';
import {HttpClient} from '@angular/common/http';
import {EventEmitterService} from './event-emitter.service';
import * as moment from 'moment';
import {SweetalertService} from './sweetalert.service';
import {GetMessages, IUploadMidia, MyChatInfo} from "../interfaces/chat.interface";
import {Observable} from "rxjs";

@Injectable()
export class ChatService {
  connection = null;
  chatId: MyChatInfo['ChatId'] = null;
  retryCount = 0;
  messagesQueue = [];

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private localStorage: LocalStorageService,
    private sweetAlertService: SweetalertService,
  ) { }

  abrirConexao() {
    if (this.connection) {
      return new Promise<void>((resolve) => {
        resolve();
      });
    }

    EventEmitterService.get('fechar-conexao-chat').subscribe(() => {
      this.fecharConexao();
    });

    if (this.retryCount > 3) {
      this.sweetAlertService.error('Conexão com o chat falhou.').subscribe();
      this.retryCount = 0;
      return;
    }

    return new Promise((resolve, reject) => {
      const authorizationData = this.loginService.getAuthorizationData();
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(API.chat + '/chat?userId=' + authorizationData.PersonId + '&userTenantId=' + authorizationData.TenantId,{
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          withCredentials: false
        })
        .withAutomaticReconnect([5000, 5000, 5000, 10000])
        .build();

      this.connection.onreconnected( () => {
        this.retryCount = 0;
        EventEmitterService.get('chat-conectado').emit();

        if (this.chatId) {
          this.entrarNoChat(this.chatId);
        }
      }, (err) => {
        if (err && err.statusCode === 401) {
          this.loginService.refresh_token().subscribe(() => {
            this.abrirConexao();
          });
        }
      });

      this.connection.start({withCredentials: true}).then(() => {
        EventEmitterService.get('chat-conectado').emit();
        resolve(this);
      }).catch((e) => {
        if (e && e.statusCode === 401) {
          this.loginService.refresh_token().subscribe(() => {
            this.abrirConexao().then();
          });
        }

        reject(e);
      });
    });
  }

  fecharConexao() {
    if (!this.connection) { return; }
    if (this.connection && this.connection.connectionState !== 'Connected') { return; }

    this.connection.stop();
    this.connection = null;
  }

  reconectar() {
    if (!this.connection) { return; }
    if (this.connection && this.connection.connectionState === 'Connected') { return; }

    this.connection.start().then(() => {
      this.entrarNoChat(this.chatId);
    });
  }

  enviarFila() {
    setTimeout(() => {
      if (this.messagesQueue && this.messagesQueue.length) {
        this.messagesQueue.forEach((queue) => {
          if (queue.chatId && queue.mensagem) {
            this.enviarMensagem(queue.chatId, queue.mensagem, true);
          }
        });
      }
    }, 500);
  }

  entrarNoChat(chatId: string): void {
    if (!this.connection) { return; }
    if (this.connection && this.connection.connectionState !== 'Connected') {
      EventEmitterService.get('chat-conectado').subscribe(() => {
        if (!this.connection || this.connection.connectionState !== 'Connected') { return; }
        if (!chatId) { return; }
        this.chatId = chatId;
        this.connection.invoke('Subscribe', chatId);
        this.connection.on('ReciveMessage', (data: GetMessages) => { EventEmitterService.get('nova-mensagem-chat').emit(data); });

        this.enviarFila();
      });

      return;
    }
    this.chatId = chatId;
    this.connection.invoke('Subscribe', chatId);
    this.connection.on('ReciveMessage', (data) => {
      EventEmitterService.get('nova-mensagem-chat').emit(data);
    });

    this.enviarFila();
  }

  sairDoChat(): void {
    if (this.connection && this.connection.connectionState !== 'Connected') { return; }
    if (this.chatId) {
      this.connection.invoke('Unsubscribe', this.chatId);
    }
  }

  enviarMensagem(chatId, mensagem: string, queued?: boolean): void {
    if (!this.connection || (this.connection && this.connection.connectionState !== 'Connected')) {
      if (chatId && mensagem && !queued) {
        this.messagesQueue.push({
          chatId: chatId,
          mensagem: mensagem
        });
      }

      return;
    }
    this.connection.invoke('SendMessage', chatId, mensagem);
    this.messagesQueue = this.messagesQueue.filter(m => m.mensagem !== mensagem);
  }

  getChatInfo(): Observable<MyChatInfo>{
    return this.http.get<MyChatInfo>(API.chat + '/Chat/GetMyChatInfo', {
      withCredentials: true
    });
  }

  getMessages(chatId: string): Observable<[GetMessages]> {
    const skip = 0;
    const take = 10000;
    const lessThanDate = moment().add(1, 'days').format('YYYY-MM-DD');

    return this.http.get<[GetMessages]>(API.chat + '/Message/GetMessages', {
      params: {
        chatId: chatId,
        skip: skip.toString(),
        take: take.toString(),
        lessThanDate: lessThanDate
      },
      withCredentials: true
    });
  }

  salvarMensagensLocal(mensagens) {
    this.localStorage.set('mensagens-chat', mensagens);
  }

  obterMensagensLocal() {
    return localStorage.getItem('treino/mensagens-chat');
  }

  obterMensagensNaoLidas() {
    return this.http.get(API.chat + '/Message/HasUnreadMessage', {
      withCredentials: true
    });
  }

  marcarMensagensComoLidas(mensagemIds: GetMessages['MessageId'][]): void {
    if (!this.connection) { return; }
    if (this.connection && this.connection.connectionState !== 'Connected') { return; }
    this.connection.invoke('MessageRead', mensagemIds);
  }

  enviarArquivo(uploadMidia: IUploadMidia): Observable<Object> {
    return this.http.post(API.chat + '/Message/Upload', uploadMidia, {
      withCredentials: true,
    });
  }
}
