import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-cadastrar-aluno',
  templateUrl: './cadastrar-aluno.component.html',
  styleUrls: ['./cadastrar-aluno.component.scss']
})
export class CadastrarAlunoComponent {
  @Output() voltar = new EventEmitter();

  voltarParaCadastro(): void {
    this.voltar.emit();
  }
}
