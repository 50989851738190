import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';

import { API } from './../../../environments/environment';
import { StorageService } from './storage.service';
import {map, switchMap, take, tap} from 'rxjs/operators';

import { EventEmitterService } from './../services/event-emitter.service';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';

declare var jQuery: any;

// Interface de Login
export interface Login {
  Email: string;
  Password: string;
  Origin: string;
}

// Interface de dados de autorização
export interface AuthorizationData {
  PersonId: string;
  TenantId: string;
  TenantSupportId: string;
  Token: string;
  TokenId: string;
  Type: string;
}

// Interface das informações do dispositivo
export interface DeviceInfo {
  DeviceId?: string;
  PersonId?: string;
  TenantId?: string;
  DataCadastro?: string;
  FcmToken?: string;
  Manufacturer?: string;
  Model?: string;
  Platform?: string;
  Serial?: string;
  UuId?: string;
  Version?: string;
}

@Injectable()
export class LoginService {
  tokenPushNotification: ReplaySubject<Token> = new ReplaySubject<Token>(1);
  isLoginEmail = false;

  // Dados de autorização em localstorage
  authorizationData: AuthorizationData = {
    Token: '',
    TokenId: '',
    PersonId: '',
    TenantId: '',
    TenantSupportId: '',
    Type: '',
  };

  // Dados de usuário em sessão
  userData = null;
  dvcInfo: any;

  redirectUrl: string;
  authorizationDataStream: ReplaySubject<any> = new ReplaySubject(1);
  userDataStream: ReplaySubject<any> = new ReplaySubject(1);
  gettingUserData = false;
  userDataPublic: any;

  deviceReady = false;

  constructor(
    private zone: NgZone,
    private router: Router,
    private http: HttpClient,
    private storageService: StorageService,
  ) {
      const data = this.storageService.get('authorizationData');
        this.authorizationData = data || this.authorizationData;
        this.authorizationDataStream.next(this.authorizationData);

    EventEmitterService.get('refresh').subscribe(() => {
      this.recarregarDadosUsuario();
    });

    document.addEventListener('deviceready', () => {
      this.deviceReady = true;
    });
  }

  isAppUrl() {
    return Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android';
  }

  setTokenPushNotification(token: Token) {
    this.tokenPushNotification.next(token);
  }

  // Faz o login e recebe o token de autorização
  login(login: Login): Observable<any> {

    this.clearData();
    return this.auth(login);
  }

  refresh_token(): Observable<any> {
    const data = {
      TokenId: this.authorizationData.TokenId,
      AppSource: 'treino,actuar,facilfit',
    };

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    return this.http.post(API.user_service + '/Auth/refresh', data, {
      headers: headers,
      withCredentials: false
    }).pipe(map((res) => {
      this.fillAuthData(res);
      return res;
    }));
  }

  auth(data: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');
    return this.http.post(API.user_service + '/Auth/login', data, {
      headers: headers,
      withCredentials: false
    }).pipe(
      tap((res: any) => this.fillAuthData(res)),
      switchMap((res: any) => this.getAllPersonsByUser()
      .pipe(map((persons: any) => {
        if (persons && Array.isArray(persons)) { return persons; }
        return res;
      }))
    ));
  }

  changePerson(personId: string): Observable<any> {
    return this.http.post(API.user_service + '/Auth/ChangePerson?personId=' + personId, {}, {
      withCredentials: true,
    }).pipe(map((res) => {
      this.fillAuthData(res);
      return res;
    }));
  }

  getAllPersonsByUser(): Observable<any> {
    return this.http.get(API.user_service + '/UserProfile/GetAllPersonsByUser', {
      withCredentials: true
    });
  }

  code(data) {
    this.clearData();

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    return this.http.post(API.user_service + '/OAuth/code', data, {
      headers: headers,
      withCredentials: false
    }).pipe(map((res: any) => {
      if (res && Array.isArray(res)) { return res; }
      if (res && (res.emailConfirmed === false || res.phoneConfirmed === false)) { return res; }
      this.fillAuthData(res);
      return res;
    }));
  }

  // Chama a limpeza de dados de autenticação e redireciona ao login
  logOut(): void {
    if (this.deviceReady && this.dvcInfo && this.dvcInfo.uuid) {
      this.removerDevice(this.dvcInfo.uuid).subscribe(() => {
        this.limparDadosERedirecionar();
      }, () => {
        this.limparDadosERedirecionar();
      });
    } else {
      this.limparDadosERedirecionar();
    }
  }

  limparDadosERedirecionar() {
    this.clearData();
    EventEmitterService.get('fechar-conexao-chat').emit();
    this.zone.run(() => this.router.navigate(['login']));
  }

  // Preenche os dados de autenticação
  fillAuthData(data): void {
    this.authorizationData = data;
    this.storageService.set('authorizationData', this.authorizationData);
    this.authorizationDataStream.next(this.authorizationData);
    this.registraFCM();
  }

  // Destrói a sessão e limpa os dados
  clearData(): boolean {
    this.authorizationData = {
      PersonId: '',
      TenantId: '',
      TenantSupportId: '',
      Token: '',
      TokenId: '',
      Type: '',
    };

    this.userData = null;
    this.storageService.clear();
    this.storageService.set('authorizationData', this.authorizationData);
    this.authorizationDataStream.next(this.authorizationData);

    return true;
  }

  recarregarDadosUsuario() {
    this.userDataStream = new ReplaySubject(1);
    this.getUserData().subscribe();
  }

  // Obtem os dados de usuário e armazena em sessão, emitindo via stream
  getUserData(): any {
    return this.http
      .get(API.base + '/Contas/Usuario', {
        withCredentials: true
      }).pipe(map(response => {
        this.setUserData(response);
        this.userDataPublic = response;
        this.userDataStream.next(this.userData);
        this.gettingUserData = false;
        return response;
      }));
  }

  setUserData(userData) {
    this.userData = userData;
    this.storageService.set('userData', userData);
  }

  getUserDataLocal() {
    return this.storageService.get('userData');
  }

  // Retorna os dados de sessão e emite via stream
  getSessionData() {
    return this.userDataStream;
  }

  getAuthorizationData() {
    return this.authorizationData;
  }

  // Retorna um booleano para verificar se usuário está logado
  getAuthStatus(): boolean {
    if (this.authorizationData && (this.authorizationData.Token)) {
      return true;
    }

    return false;
  }

  getToken(): any {
    return this.authorizationData.Token;
  }

  getUserInfo(id: string): Observable<any> {
    return this.http
      .get(API.user_service + '/User/GetInfo/' + id, {
        withCredentials: false
      });
  }

  // Faz a verificação de autenticação via AuthRouter
  checkLogin(): Observable< any > {
    return this.authorizationDataStream.pipe(
      take(1)).pipe(
      map((data: any) => {
        if (!data || !data.PersonId) {
          this.clearData();
          return false;
        } else if (!this.userData && !this.gettingUserData) {
          this.gettingUserData = true;
          this.getUserData().subscribe();
          return true;
        } else {
          return true;
        }
      }));
  }

  // Obtem as informações de usuário através do ID
  obterInfoPorId(id: string): Observable< any > {
    return this.http
      .get(API.base + '/Pessoas/ObterInfoPorId?id=' + id, {
        withCredentials: false
      });
  }

  // Cria a senha do cliente através do ID
  criarSenha(dados): Observable< any > {
    console.log(dados)
    return this.http
      .post(API.user_service + '/Auth/CreatePassword', dados, {
        withCredentials: false
      });
  }

  confirmarEmailNovoUsuario(dados) {
    return this.http
      .post(API.user_service + '/Auth/ConfirmEmail', dados, {
        withCredentials: false
      });
  }

  checarStatusEmail(email: string) {
    return this.http
      .get(API.user_service + `/Auth/CheckStatus?email=${email}`, {
        withCredentials: false
      });
  }

  // Envia um e-mail para recuperação de senha
  enviarEmailParaRedefinicaoDeSenha(dados: any): Observable< any > {
    return this.http
      .post(API.user_service + '/Auth/SendEmailResetPassword', dados, {
        withCredentials: false
      });
  }

  checarCodigoRedefinicaoSenha(dados) {
    return this.http
      .post(API.user_service + '/Auth/CheckResetPasswordCode', dados, {
        withCredentials: false
      });
  }

  modificarSenha(dados: any): Observable< any > {
    return this.http
      .post(API.user_service + '/Auth/ChangePassword', dados, {
        withCredentials: true
      });
  }

  // Faz a redefinição da senha a partir do token recebido
  redefinirSenha(dados: any): Observable< any > {
    return this.http
      .post(API.user_service + '/Auth/ResetPassword', dados, {
        withCredentials: false
      });
  }

  // Cria uma conta no ActuarWeb através do Trei.no
  criarConta(data: any): Observable< any > {
    const dados = {
      Segmento: data.Segment,
      Email: data.Email,
      PrimeiroNome: data.FirstName,
      Sobrenome: data.LastName,
      TermosAceito: data.TermsAccepted,
      Senha: data.Password,
      Origem: 'treino'
    };

    return this.http
      .post(API.base + '/Contas/Criar', dados, {
        withCredentials: false
      });
  }

  emailJaConfirmado(contaId: any): Observable< any > {
    return this.http
      .get(API.base + '/Contas/Confirmacoes?contaId=' + contaId, {
        withCredentials: false
      });
  }

  reenviarEmailConfirmacao(data: any): Observable< any > {
    return this.http
      .put(API.base + '/Contas/ReenviarEmailConfirmacao', data, {
        withCredentials: false
      });
  }

  enviarEmailConfirmacao(data: any): Observable< any > {
    return this.http
      .put(API.base + '/Contas/EnviarEmailConfirmacao', data, {
        withCredentials: false
      });
  }

  adicionarNumero(data: any): Observable< any > {
    return this.http
      .put(API.base + '/Contas/AdicionarTelefone', data, {
        withCredentials: false
      });
  }

  verificarNumero(data: any): Observable< any > {
    return this.http
      .post(API.base + '/Contas/VerificarTelefone', data, {
        withCredentials: false
      });
  }

  reenviarCodigoCelular(data: any) {
    return this.http
      .put(API.base + '/Contas/ReenviarCodigoTelefoneConfirmacao', data, {
        withCredentials: false
      });
  }

  adicionarRepositorioTransiente(data): Observable< any > {
    return this.http
      .post(API.base + '/Admin/AdicionarRepositorioTransiente', JSON.stringify(data), {
        withCredentials: true
      });
  }

  obterRepositorioTransiente(data: any): Observable< any > {
    return this.http
      .get(API.base + '/Admin/ObterRepositorioTransiente?sid=' + data, {
        withCredentials: false
      });
  }

  loginPorToken(token, refreshToken) {
    this.authorizationData.Token = token;
    this.authorizationData.TokenId = refreshToken;
    this.storageService.clear();
    this.storageService.set('authorizationData', this.authorizationData);
  }

  // Faz o registro do dispositivo no FCM, pega o TOKEN e atualiza o dispositivo
  registraFCM(): void {
    document.addEventListener('deviceready', () => {
      Device.getInfo().then((data) => {
        this.dvcInfo = data;
        console.log(this.dvcInfo, 'DVCINFO 1');
      }).then(() => {
        Device.getId().then((data) => {
          this.dvcInfo = {
            ...this.dvcInfo,
            ...data
          };
          console.log(this.dvcInfo, 'DVCINFO 2');
        }).then(() => {
          this.tokenPushNotification.subscribe((token: Token) => {
            console.log(token, 'TOKEN');
            console.log(this.dvcInfo, 'DVCINFO 3');
            if (token && token.value) {
              const data: DeviceInfo = {
                FcmToken: token.value,
                PersonId: this.authorizationData.PersonId,
                TenantId: this.authorizationData.TenantId,
                Manufacturer: this.dvcInfo.manufacturer,
                Model: this.dvcInfo.model,
                Platform: this.dvcInfo.platform,
                Serial: null,
                UuId: this.dvcInfo.uuid,
                Version: this.dvcInfo.webViewVersion
              };
              this.atualizaInfoDispositivo(data)
                .subscribe(response => {
                  if (response) {
                    console.log('Dispositivo atualizado');
                  }
                }, error => {
                  console.error('Não foi possível atualizar o dispositivo');
                });
            }
          });
        });
      });
    });
  };

  // Atualiza as informações do dispositivo
  atualizaInfoDispositivo(info: DeviceInfo): Observable< any > {
    return this.http
      .post(API.client_device_service + '/ClientDevice', {...info}, {
        withCredentials: true
      });
  }

  unauthorized(error?) {
    this.redirectUrl = this.router.url;
    this.logOut();
    return Observable.throwError(error || '');
  }

  private objToURL(obj) {
    let str = '';
    const keys = Object.keys(obj);

    keys.forEach(key => {
      str += `&${key}=${obj[key]}`;
    });

    // remove primeiro &
    return keys.length ? str.substr(1) : str;
  }

  confirmarEmail(data): Observable< any > {
    return this.http
      .put(API.user_service + '/User/ConfirmEmail', data, {
        withCredentials: false
      });
  }

  removerDevice(deviceId: string): Observable< any > {
    return this.http
      .delete(API.base + '/Devices/Remover?id=' + deviceId, {
        withCredentials: true
      });
  }

  verificarEmail(email: string): Observable<any> {
    return this.http
      .get(API.user_service + '/Auth/CheckStatus?email=' + email, {
        withCredentials: false
      });
  }

  enviarEmailCriacaoSenha(email: string): Observable<any> {
    const data = {
        Email: email,
        Origin: 'treino'
    };

    return this.http
      .put(API.base + '/Contas/EnviarEmailCriacaoSenha', data, {
        withCredentials: false
      });
  }

  loginEmail(login: Login): Observable<any> {
    this.clearData();
    return this.auth(login);
  }


}
